@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

:focus {
  outline: 0;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

input, textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

label, button, input[type=submit], input[type=button] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button, input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

* {
  box-sizing: border-box;
}

:root {
  --cl-black: #0a0a0a;
  --cl-black-rgb: 0 0 0;
  --cl-primary: #010f93;
  --cl-white: #ffffff;
  --cl-grey: #e0e4e1;
  --cl-navy: #010f93;
  --cl-royal-blue: #0035ec;
  --cl-navy-80: rgba(1, 15, 147, 0.8);
  --cl-pink: #e6007e;
  --cl-aqua: #68e6fc;
  --cl-charcoal: #35353f;
  --cl-dark-grey: #656565;
  --cl-light-bg: #f2f1f1;
  --cl-red: #ff2727;
  --gradient: linear-gradient(90deg, #000000 0%, #2800d7 100%);
  --font-main: "effra", sans-serif;
  --font-second: "Gellix", sans-serif;
  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
  --vh: calc(100vh / 100);
  --arw: 1;
  --arh: 1;
  --max-line-num: 3;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */
/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
/* ../../assets/ */
@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?k41qwn");
  src: url("../../assets/fonts/icomoon.eot?k41qwn#iefix") format("embedded-opentype"), url("../../assets/fonts/icomoon.ttf?k41qwn") format("truetype"), url("../../assets/fonts/icomoon.woff?k41qwn") format("woff"), url("../../assets/fonts/icomoon.svg?k41qwn#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: "\e912";
}

.icon-log-out:before {
  content: "\e926";
}

.icon-cancel:before {
  content: "\e927";
}

.icon-save-changes:before {
  content: "\e928";
}

.icon-mail-second:before {
  content: "\e90a";
}

.icon-show:before {
  content: "\e900";
}

.icon-download:before {
  content: "\e901";
}

.icon-profile:before {
  content: "\e902";
}

.icon-manage:before {
  content: "\e903";
}

.icon-saved:before {
  content: "\e904";
}

.icon-current:before {
  content: "\e905";
}

.icon-upload:before {
  content: "\e906";
}

.icon-upload-2:before {
  content: "\e929";
}

.icon-search:before {
  content: "\e907";
}

.icon-not-show:before {
  content: "\e908";
}

.icon-location:before {
  content: "\e909";
}

.icon-phone-second:before {
  content: "\e90b";
}

.icon-arrow-chevron-second:before {
  content: "\e90c";
}

.icon-arrow-chevron:before {
  content: "\e90d";
}

.icon-check:before {
  content: "\e90e";
}

.icon-plus-second:before {
  content: "\e90f";
}

.icon-star:before {
  content: "\e910";
}

.icon-save:before {
  content: "\e911";
}

.icon-phone:before {
  content: "\e913";
}

.icon-plus:before {
  content: "\e914";
}

.icon-minus:before {
  content: "\e915";
}

.icon-cv:before {
  content: "\e916";
}

.icon-submit:before {
  content: "\e917";
}

.icon-close:before {
  content: "\e918";
}

.icon-instagram:before {
  content: "\e919";
}

.icon-twitter:before {
  content: "\e91a";
}

.icon-facebook:before {
  content: "\e91b";
}

.icon-linkedin:before {
  content: "\e91c";
}

.icon-arrow-prev:before {
  content: "\e91d";
}

.icon-arrow-next:before {
  content: "\e91e";
}

.icon-login:before {
  content: "\e91f";
}

.icon-arrow-left:before {
  content: "\e920";
}

.icon-arrow-right:before {
  content: "\e921";
}

.icon-whatsapp:before {
  content: "\e922";
}

.icon-testimonials:before {
  content: "\e923";
}

.icon-tiktok:before {
  content: "\e924";
}

.icon-filters:before {
  content: "\e925";
}

/*h1 {
  font-size: ac(140px, 54px);
  font-weight: 300;
  line-height: normal;
  color: var(--cl-primary);

  @mixin media 374 {
    font-size: 40px;
  }

  @mixin media 330 {
    font-size: 38px;
  }
}

h2 {
  font-size: ac(90px, 44px);
  font-weight: 300;
  line-height: 1.15;
  color: var(--cl-primary);

  @mixin max-xs {
    font-size: 25px;
  }
}

h3 {
  font-size: ac(35px, 26px);
  font-weight: 300;
  line-height: normal;
  color: var(--cl-primary);

  &.big-size {
    font-size: ac(90px, 44px);
    font-weight: 300;
    line-height: 1.1;
    color: var(--cl-primary);

    @mixin max-xs {
      font-size: 25px;
    }
  }
}

h4 {
  font-size: ac(30px, 20px);
  font-weight: 300;
  line-height: normal;
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}*/
/*p {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

li {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

a {
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
}*/
.heading-1 {
  font-size: clamp(44px, 44px + 86 * (100vw - 768px) / 672, 130px);
  line-height: 1.2;
  font-weight: 700;
}

@media (max-width: 640px) {
  .heading-1 {
    line-height: 1.08;
  }
}
.heading-2 {
  font-size: clamp(36px, 36px + 64 * (100vw - 768px) / 672, 100px);
  font-weight: 700;
  line-height: 1.04;
}

.heading-3 {
  font-size: clamp(30px, 30px + 40 * (100vw - 768px) / 672, 70px);
  font-weight: 700;
  letter-spacing: clamp(0.5px, 0.5px + 0.7 * (100vw - 768px) / 672, 1.2px);
  line-height: 1.08;
}

.heading-4 {
  font-size: clamp(26px, 26px + 26 * (100vw - 768px) / 672, 52px);
  font-weight: 700;
  line-height: 1.25;
}

.heading-4-second {
  font-size: clamp(24px, 24px + 10 * (100vw - 768px) / 672, 34px);
  font-weight: 700;
  line-height: 129.41%;
  letter-spacing: 0;
}

.heading-5 {
  font-size: clamp(22px, 22px + 18 * (100vw - 768px) / 672, 40px);
  font-weight: 500;
  line-height: 113.33%;
  letter-spacing: 0;
}

@media (max-width: 640px) {
  .heading-5 {
    line-height: 1.2;
  }
}
.heading-6 {
  font-size: clamp(22px, 22px + 8 * (100vw - 768px) / 672, 30px);
  font-weight: 500;
  line-height: 1.28;
  letter-spacing: 0;
}

@media (max-width: 640px) {
  .heading-6 {
    line-height: 1.2;
  }
}
.heading-7 {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
}

@media (max-width: 640px) {
  .heading-7 {
    line-height: 1.36;
  }
}
.heading-1 span[data-word="."], .heading-2 span[data-word="."], .heading-3 span[data-word="."] {
  color: var(--cl-pink);
}

@media (max-width: 451px) {
  .heading-1 span, .heading-2 span, .heading-3 span {
    padding-bottom: 2px;
  }
}
.subtitle-1 {
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 500;
  line-height: clamp(28px, 28px + 3 * (100vw - 768px) / 672, 31px);
}

.subtitle-2 {
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 400;
  line-height: 1.32;
}

.body-text-1 {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.4;
}

.body-text-2 {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.4;
}

.body-text-3 {
  font-size: 16px;
  line-height: 1.4;
}

.content-element {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 400;
  line-height: 1.44;
}

.content-element[data-scroll] {
  --line-count: 6;
  max-height: calc(clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px) * 1.44 * var(--line-count));
}

.content-element h1 a, .content-element h2 a, .content-element h3 a, .content-element h4 a, .content-element h5 a, .content-element h6 a, .content-element p a, .content-element li a {
  font: inherit;
  color: var(--cl-royal-blue);
  text-decoration: underline;
  transition: color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline-block;
}

.content-element h1 a:hover, .content-element h2 a:hover, .content-element h3 a:hover, .content-element h4 a:hover, .content-element h5 a:hover, .content-element h6 a:hover, .content-element p a:hover, .content-element li a:hover {
  color: var(--cl-navy-80);
}

.content-element h3:not(:last-child), .content-element h4:not(:last-child) {
  margin-bottom: 13px;
}

/*Not Design*/
.content-element li:not(:last-child), .content-element p:not(:last-child) {
  margin-bottom: clamp(5px, 8px + -3 * (100vw - 768px) / 672, 8px);
}

.content-element ol {
  list-style-type: none;
  counter-reset: num;
  display: grid;
}

.content-element ol li {
  position: relative;
  display: inline-block;
  padding-left: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.content-element ol li:before {
  position: absolute;
  color: var(--cl-black);
  top: 0;
  left: 0;
  font: inherit;
  content: counter(num) ".";
  counter-increment: num;
}

.content-element ol li:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 4 * (100vw - 768px) / 672, 12px);
}

.content-element ol:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

.content-element ul {
  display: grid;
}

.content-element ul li {
  position: relative;
  display: inline-block;
  padding-left: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.content-element ul li:before {
  position: absolute;
  background: var(--cl-primary);
  border-radius: 50%;
  content: "";
  width: 8px;
  height: 8px;
  top: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
  left: 0;
}

.content-element ul li:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 4 * (100vw - 768px) / 672, 12px);
}

.content-element ul:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

.content-element.medium-size {
  font-size: clamp(18px, 18px + 4 * (100vw - 768px) / 672, 22px);
  font-weight: 400;
  line-height: 1.36;
  letter-spacing: 0;
}

.content-element.big-size {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  font-weight: 400;
  line-height: 1.48;
  letter-spacing: 0;
}

.content-element .simplebar-track.simplebar-vertical {
  width: clamp(2px, 2px + 1 * (100vw - 768px) / 672, 3px);
  background: var(--cl-grey);
  border-radius: 0;
}

.content-element .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--cl-sun);
  border-radius: 0;
}

.content-element .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.content-element.article-type h1 {
  font-size: clamp(30px, 30px + 40 * (100vw - 768px) / 672, 70px);
  font-weight: 700;
  line-height: normal;
  color: var(--cl-navy);
}

.content-element.article-type h1:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.content-element.article-type h2 {
  font-size: clamp(26px, 26px + 26 * (100vw - 768px) / 672, 52px);
  font-weight: 700;
  line-height: 1.2;
  color: var(--cl-navy);
}

.content-element.article-type h2:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.content-element.article-type h3 {
  font-size: clamp(22px, 22px + 8 * (100vw - 768px) / 672, 30px);
  font-weight: 500;
  line-height: 1.28;
  letter-spacing: 0;
  color: var(--cl-navy);
}

.content-element.article-type h3:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.content-element.article-type h4 {
  color: var(--cl-navy);
}

.content-element.article-type p, .content-element.article-type li {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 1.4;
}

.content-element.article-type p:not(:last-child), .content-element.article-type ul:not(:last-child), .content-element.article-type ol:not(:last-child) {
  margin-bottom: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.content-element.article-type p + h2, .content-element.article-type ol + h2, .content-element.article-type ul + h2 {
  padding-top: 23px;
}

.content-element.article-type p + h2, .content-element.article-type ol + h2, .content-element.article-type ul + h2 {
  padding-top: 23px;
}

.content-element.article-type p + h3, .content-element.article-type ol + h3, .content-element.article-type ul + h3 {
  padding-top: 23px;
}

.content-element.article-type p + h4, .content-element.article-type ol + h4, .content-element.article-type ul + h4 {
  padding-top: 23px;
}

.dark-bg {
  color: var(--cl-white);
}

.dark-bg h1, .dark-bg h2, .dark-bg h3, .dark-bg h4, .dark-bg h5, .dark-bg h6, .dark-bg p, .dark-bg li {
  color: var(--cl-white);
}

.dark-bg .content-element h1 a, .dark-bg .content-element h2 a, .dark-bg .content-element h3 a, .dark-bg .content-element h4 a, .dark-bg .content-element h5 a, .dark-bg .content-element h6 a, .dark-bg .content-element p a, .dark-bg .content-element li a {
  color: var(--cl-white);
}

.dark-bg .content-element h1 a:hover, .dark-bg .content-element h2 a:hover, .dark-bg .content-element h3 a:hover, .dark-bg .content-element h4 a:hover, .dark-bg .content-element h5 a:hover, .dark-bg .content-element h6 a:hover, .dark-bg .content-element p a:hover, .dark-bg .content-element li a:hover {
  color: var(--cl-primary);
}

.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);
  color: var(--cl-charcoal);
  font-family: var(--font-main);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 400;
  line-height: 1.44;
  overflow-x: hidden;
  min-height: calc(100 * var(--vh));
  display: flex;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: var(--cl-grey);
  border-radius: 0;
}

body::-webkit-scrollbar-thumb {
  background: var(--cl-primary);
  border-radius: 0;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#site:not(.is-visible) {
  overflow: hidden;
}

@media (max-width: 640px) {
  #site.is-visible {
    overflow: hidden;
  }
}
.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1280px;
  width: 88.8888888889%;
}

@media (max-width: 1024px) {
  .cont {
    width: 89.33%;
  }
}
@media (max-width: 640px) {
  .cont {
    width: 87.2%;
  }
}
@media (max-width: 360px) {
  .cont {
    width: 90%;
  }
}
section, footer, header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.swiper {
  width: 100%;
  max-width: 100%;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.sections-container.home-container {
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
}

.sections-container.home-container .home-container__bg {
  --arw: 1440;
  --arh: 2552;
  position: absolute;
  width: 100%;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 640px) {
  .sections-container.home-container .home-container__bg {
    display: none;
  }
}
.sections-container.home-container .home-container__image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: clamp(310px, 310px + 24 * (100vw - 768px) / 672, 334px);
  clip-path: polygon(0 clamp(550px, 550px + 394 * (100vw - 768px) / 672, 944px), 100% 0, 100% 100%, 0 100%);
  z-index: -1;
}

.sections-container.home-container .home-container__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 640px) {
  .sections-container.home-container .home-container__image {
    top: 568px;
    clip-path: polygon(0 240px, 100% 0, 100% 100%, 0 100%);
  }
}
.sections-container.services-container {
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
}

.sections-container.services-container .services-container__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  height: 100%;
}

.sections-container.services-container .services-container__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 640px) {
  .sections-container.services-container .services-container__image {
    /* top: 508px; */
    /* clip-path: polygon(0 240px, 100% 0, 100% 100%, 0 100%); */
  }
}
.sections-container.jobs-search-container {
  overflow: visible;
}

.title-animation {
  opacity: 0;
}

.title-animation.animated {
  opacity: 1;
}

.title-animation.animated .splitting .word {
  animation: slide-in 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
  animation-delay: calc(100ms * var(--word-index));
  /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
}

.js-marquee-wrapper {
  will-change: transform;
}

.second-title-animation {
  transition: all 0.3s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;
}

.second-title-animation.animated {
  opacity: 1;
  transform: scaleX(1) translateX(0);
}

@keyframes slide-in {
  from {
    transform: translateX(0.5em) scaleY(1.2) rotate(0);
    opacity: 0;
    /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
  }
}
.logo {
  transition: 0.3s ease;
}

.logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;
}

#bold-credits::before, #bold-credits::after {
  display: none;
}

[data-scroll] {
  width: calc(100% + 10px);
  padding-right: 10px;
}

[data-scroll] .os-theme-dark, [data-scroll] .os-theme-light {
  --os-size: clamp(6px, calc(6px + 2.00 * ((100vw - 768px) / 672)), 8px);
  --os-track-border-radius: 6px;
}

[data-scroll] .os-theme-dark {
  --os-handle-bg: var(--cl-aqua);
  --os-handle-bg-hover: var(--cl-navy);
}

.image-aspect-ratio {
  position: relative;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.image-aspect-ratio::before {
  content: "";
  display: block;
  padding-bottom: min(100% * var(--arh) / var(--arw));
}

.image-aspect-ratio img, .image-aspect-ratio video {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.image-aspect-ratio[data-parallax-el] {
  overflow: visible;
  -webkit-mask-image: none;
  mask-image: none;
}

.image-aspect-ratio[data-parallax-el] img, .image-aspect-ratio[data-parallax-el] svg {
  position: absolute !important;
}

.max-line-length-number {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-line-num);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.about-section-container {
  position: relative;
}

.about-section-container__image {
  pointer-events: none;
  position: absolute;
  inset: 0;
  top: clamp(-300px, 0px + -300 * (100vw - 1440px) / 480, 0px);
  overflow: hidden;
}

.about-section-container__image img {
  width: 100%;
}

.about-section-container__bg {
  background-color: rgba(3, 3, 15, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
}

/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: clamp(16px, 16px + 24 * (100vw - 768px) / 672, 40px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.header.absolute {
  position: absolute;
}

.header.fixed {
  position: fixed;
}

@media (max-width: 640px) {
  .header.open-menu {
    border-bottom: 1px solid var(--cl-aqua);
  }
}
.header:before {
  position: absolute;
  inset: 0;
  content: "";
  z-index: -1;
  background-color: transparent;
  opacity: 0;
  transition: all 0.25s ease;
}

.header.scrolled {
  /* &:not(.open-menu) { */
  background: rgba(var(--cl-charcoal), 0.5);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  padding: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px) 0;
  /* } */
}

.header.scrolled:before {
  opacity: 0.5;
  background-color: var(--cl-charcoal);
}

.header.open-menu .logo svg {
  transition: 0.6s all;
}

@media (max-width: 1200px) {
  .header.open-menu .logo svg {
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
  }
}
@media (max-width: 640px) {
  .header.open-menu .logo svg {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }
}
.header.open-menu .logo svg path {
  fill: var(--cl-aqua);
}

.header.open-menu .logo:hover svg path {
  fill: rgba(255, 255, 255, 0.9);
}

.header.open-menu .btn-profile-header {
  background-color: var(--cl-aqua) !important;
}

.header.open-menu .btn-profile-header .btn__icon i {
  color: var(--cl-charcoal);
}

@media (max-width: 640px) {
  .header.open-menu::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--cl-aqua);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.header.header-second:not(.scrolled) .logo svg path {
  fill: var(--cl-navy);
}

.header.header-second:not(.scrolled) .logo:hover svg path {
  fill: var(--cl-charcoal);
}

@media (min-width: 1201px) {
  .header.header-second:not(.scrolled):not(.open-menu) .menu .simplebar-content .menu-list {
    border-color: var(--cl-charcoal);
  }
}
.header.header-second:not(.scrolled):not(.open-menu) .btn {
  border-color: var(--cl-navy);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn.btn-transparent .btn__text {
  color: var(--cl-navy);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn:not(.btn-transparent) {
  background-color: var(--cl-navy);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn:not(.btn-transparent) .btn__text {
  color: var(--cl-white);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn:not(.btn-transparent) .btn__icon {
  color: var(--cl-white);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn:not(.btn-transparent):hover {
  background-color: var(--cl-aqua);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn:not(.btn-transparent):hover .btn__text {
  color: var(--cl-navy);
}

.header.header-second:not(.scrolled):not(.open-menu) .btn:not(.btn-transparent):hover .btn__icon {
  color: var(--cl-navy);
}

@media (min-width: 1200px) {
  .header.header-second:not(.scrolled) .menu-link {
    color: var(--cl-charcoal);
  }
  .header.header-second:not(.scrolled) .menu-link:before {
    background-color: var(--cl-aqua);
  }
  .header.header-second:not(.scrolled) .menu-link:hover {
    color: var(--cl-navy);
  }
  .header.header-second:not(.scrolled) .menu-link:hover:before {
    background-color: var(--cl-navy);
  }
}
.header.header-second:not(.scrolled) .burger:not(.active) {
  background-color: var(--cl-white);
}

.header.header-second:not(.scrolled) .burger:not(.active) .line {
  stroke: var(--cl-navy);
}

.header.header-second:not(.scrolled) .burger:not(.active):hover .line {
  stroke: var(--cl-charcoal);
}

.header.header-second.open-menu .logo svg {
  transition: 0.6s all;
}

@media (max-width: 1200px) {
  .header.header-second.open-menu .logo svg {
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
  }
}
@media (max-width: 640px) {
  .header.header-second.open-menu .logo svg {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }
}
.header.header-second.open-menu .logo svg path {
  fill: var(--cl-white);
}

.header.header-second.open-menu .logo:hover svg path {
  fill: rgba(255, 255, 255, 0.9);
}

.header .logo {
  --arw: 162;
  --arh: 65;
  height: 100%;
  width: 104px;
  flex-shrink: 0;
  transition: transform 0.25s ease, width 0.25s ease;
  z-index: 100;
  margin-right: auto;
}

.header .logo img, .header .logo svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
}

.header .logo svg path {
  fill: var(--cl-white);
  transition: fill 0.3s ease;
}

.header .logo:hover {
  transform: scale(0.96);
}

.header .logo:hover svg path {
  fill: var(--cl-aqua);
}

.header .navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .navbar__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  margin-left: clamp(36px, 36px + 14 * (100vw - 768px) / 672, 50px);
  position: relative;
  z-index: 10;
}

@media (min-width: 1200px) and (max-width: 1260px) {
  .header .navbar__buttons {
    margin-left: 36px;
  }
  .header .navbar__buttons .btn {
    padding-left: 14px;
    padding-right: 14px;
  }
}
/*.social-link {
        color: var(--cl-light-grey);
        font-size: ac(22px, 24px);

        &:hover {
          color: var(--cl-maron);
        }
      }*/
@media not all and (min-width: 1200px) {
  .header .navbar__buttons {
    margin-right: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  }
  @media (max-width: 851px) {
    .header .navbar__buttons .btn {
      display: none !important;
    }
    .header .navbar__buttons .btn.btn-profile-header {
      display: flex;
      background-color: transparent;
      padding: 12px !important;
      width: 48px;
      height: 48px;
      border-radius: 12px;
    }
    .header .navbar__buttons .btn.btn-profile-header .btn__text {
      display: none;
    }
    .header .navbar__buttons .btn.btn-profile-header .btn__icon {
      color: var(--cl-white);
    }
    .header .navbar__buttons .btn.btn-profile-header:hover {
      background-color: var(--cl-aqua);
    }
    .header .navbar__buttons .btn.btn-profile-header:hover .btn__icon {
      color: var(--cl-charcoal);
    }
  }
}
.header .burger {
  display: none;
}

.header .navbar-nav {
  display: flex;
  flex-grow: 1;
}

.header .navbar-nav .menu-list {
  display: flex;
  flex-grow: 1;
}

.header .navbar-nav__decor {
  display: none;
}

@media (min-width: 1200px) {
  .header .navbar-nav {
    max-width: clamp(590px, 590px + 120 * (100vw - 768px) / 672, 710px);
  }
  .header .navbar-nav__image {
    display: none;
  }
}
.header .menu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

/*transition: opacity 0.2s ease;
    opacity: 0;*/
@media (min-width: 1200px) {
  .header .menu {
    width: auto;
    flex: 1;
    align-items: center;
  }
  .header .menu .simplebar-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header .menu .simplebar-content .menu-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: clamp(16px, 16px + 10 * (100vw - 768px) / 672, 26px);
    padding-left: 10px;
    border-left: 1px solid var(--cl-white);
  }
}
@media (min-width: 1200px) and (min-width: 1200px) and (max-width: 1400px) {
  .header .menu .simplebar-content .menu-list {
    padding-left: 32px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .header .menu .simplebar-content .menu-list {
    min-height: 37px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .header .menu .simplebar-content .menu-list {
    gap: 16px;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) and (max-width: 1400px) {
  .header .menu .simplebar-content-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .header .menu .simplebar-content:after {
    display: none;
  }
}
.header .menu:not([data-simplebar=init]) {
  display: none;
}

.header .menu-item {
  position: relative;
}

.header .menu-item.dropdown > .menu-link {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.header .menu-item.dropdown > .menu-link::after {
  content: "\e999";
  font-family: "icomoon";
  font-size: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
  padding-left: 10px;
}

.header .menu-additional-content {
  display: none;
}

@media not all and (min-width: 1200px) {
  .header .menu-additional-content {
    /*transform: translateY(-8px);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: auto;
  }
  .header .menu-additional-content .btn {
    width: 100%;
  }
}
@media not all and (min-width: 640px) {
  .header .menu-additional-content .menu-additional-content__buttons {
    width: 100%;
    grid-row-gap: 16px;
    margin-bottom: 8px;
  }
  .header .menu-additional-content .menu-additional-content__buttons .btn-link:first-child {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .header:not(.header-mobile) .menu-item {
    padding: 5px 0;
  }
}
@media (min-width: 1200px) {
  .header:not(.header-mobile) .menu-item:hover .dropdown-menu {
    display: block;
  }
}
.header .menu-link {
  position: relative;
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  color: var(--cl-white);
  transition: all 0.3s ease;
  cursor: pointer;
}

.header .menu-link::before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 0;
  height: 2px;
  background: var(--cl-white);
  transition: all 0.3s ease;
}

.header .menu-link:hover {
  color: var(--cl-aqua);
}

.header .menu-link:hover::before {
  width: 100%;
  left: 0;
  background: var(--cl-white);
}

.header .menu-link.active::before {
  width: 100%;
  left: 0;
  background: var(--cl-aqua);
}

@media (max-width: 1320px) {
  .header .menu-link {
    font-size: 12px;
  }
}
.header .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  padding: 10px 10px 10px 15px;
  border-radius: 10px;
  background: var(--cl-grey);
  box-sizing: content-box;
  display: none;
}

.header .dropdown-menu li .menu-link {
  padding: 5px 0;
  text-transform: capitalize;
}

.header .btn.clicked {
  opacity: 0.85;
}

/* end of Common desktop header */
/* ----------------------- Common desktop header Mobile HEADER ----------------------- */
@media not all and (min-width: 1200px) {
  .header:not(.header-mobile) .burger, .header:not(.header-mobile) .menu-toggle {
    display: flex;
    margin-right: -2px;
  }
  .header:not(.header-mobile) .navbar-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 46%;
    height: auto;
    max-height: calc(var(--vh) * 100);
    min-width: 320px;
    padding-top: 152px;
    background: var(--cl-charcoal);
    padding-right: 5px;
    padding-bottom: 20px;
    border-bottom-left-radius: 16px;
    transition: opacity 0.25s ease-out, clip-path 0.5s ease, background-color 0.4s ease 0.1s, border-radius 0.3s ease;
    clip-path: circle(25px at calc(100% - 45px) 45px);
    opacity: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    pointer-events: none;
  }
  @media (max-width: 992px) {
    .header:not(.header-mobile) .navbar-nav {
      padding-top: 130px;
      width: 48%;
    }
  }
  @media (max-width: 780px) {
    .header:not(.header-mobile) .navbar-nav {
      width: 58%;
    }
  }
  /* @media screen and (max-height: 730px) {
    padding-top: 144px;
  } */
  @media (max-width: 851px) {
    .header:not(.header-mobile) .navbar-nav {
      padding-top: 110px;
    }
  }
  .header:not(.header-mobile) .navbar-nav__image {
    position: absolute;
    inset: 0;
  }
  .header:not(.header-mobile) .navbar-nav__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .header:not(.header-mobile) .navbar-nav.active {
    pointer-events: auto;
    clip-path: circle(75%);
    opacity: 1;
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-left {
    right: auto;
    left: 0;
    transform: translateX(-100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-left.active {
    transform: translateX(0);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-right {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-right.active {
    transform: translateX(0);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-down {
    right: auto;
    left: 0;
    transform: translateY(-100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-down.active {
    transform: translateY(0);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-up {
    right: auto;
    left: 0;
    transform: translateY(100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-up.active {
    transform: translateY(0);
  }
  .header:not(.header-mobile) .navbar-nav .menu {
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 15px;
    align-items: flex-start;
    align-self: center;
    position: relative;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-wrapper {
    flex: 1;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-content .menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 48px;
    position: relative;
  }
  @media screen and (max-height: 730px) {
    .header:not(.header-mobile) .navbar-nav .menu .simplebar-content .menu-list {
      padding-top: 32px;
    }
  }
  @media (max-width: 851px) {
    .header:not(.header-mobile) .navbar-nav .menu .simplebar-content .menu-list {
      margin-bottom: 24px;
    }
  }
  @media (min-width: 640px) {
    .header:not(.header-mobile) .navbar-nav .menu .simplebar-content .menu-list:after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: 173px;
      height: 1px;
      background-color: var(--cl-white);
      content: "";
    }
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-track.simplebar-vertical {
    width: clamp(4px, 4px + 2 * (100vw - 768px) / 672, 6px);
    background: var(--cl-grey);
    border-radius: 4px;
  }
  /*transform: translateX(ac(6px, 4px));*/
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
    background: var(--cl-navy);
    border-radius: 4px;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    content: none;
  }
  .header:not(.header-mobile) .navbar-nav .menu-link {
    padding: 0;
    font-size: 24px;
    font-weight: 400;
    margin-left: 0;
    width: auto;
  }
  .header:not(.header-mobile) .navbar-nav .menu-link:hover {
    color: var(--cl-aqua) !important;
  }
  .header:not(.header-mobile) .navbar-nav .menu-link:hover:before {
    background-color: var(--cl-aqua) !important;
  }
  .header:not(.header-mobile) .navbar-nav .menu-item {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    /*@mixin media-height 600 {
      padding-bottom: 8px;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }*/
  }
  .header:not(.header-mobile) .navbar-nav .menu-item:not(:last-child):last-of-type {
    margin-bottom: 63px;
  }
  @media screen and (max-height: 700px) {
    .header:not(.header-mobile) .navbar-nav .menu-item:not(:last-child):last-of-type {
      margin-bottom: 32px;
    }
  }
  @media (min-width: 851px) {
    .header:not(.header-mobile) .navbar-nav .menu-additional-content {
      display: none;
    }
  }
  @media not all and (min-width: 640px) {
    .header:not(.header-mobile) .navbar-nav {
      width: 100%;
      height: calc(var(--vh) * 100);
      padding-bottom: 40px;
      border-bottom-left-radius: 0;
    }
    .header:not(.header-mobile) .navbar-nav .menu {
      padding-left: 20px;
    }
    .header:not(.header-mobile) .navbar-nav .menu-item {
      justify-content: center;
    }
    .header:not(.header-mobile) .navbar-nav .menu-additional-content {
      margin-top: auto;
      justify-content: flex-end;
    }
  }
  .header:not(.header-mobile) .dropdown-menu {
    position: relative;
    top: 0;
  }
}
/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger, .menu-toggle {
  background: var(--cl-white);
  position: relative;
  z-index: 100;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.3s;
  border: 1px solid var(--cl-white);
}

/*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/
.burger .burger-icon, .menu-toggle .burger-icon {
  cursor: pointer;
  transition: transform 400ms, background-color 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 56px !important;
  height: 56px !important;
  position: relative;
  z-index: 2;
}

.burger.active, .menu-toggle.active {
  background-color: transparent;
}

.burger.active:before, .menu-toggle.active:before {
  opacity: 1;
}

.burger.active .burger-icon, .menu-toggle.active .burger-icon {
  transform: rotate(45deg);
}

.burger.active .top, .menu-toggle.active .top {
  stroke-dashoffset: -68px;
}

.burger.active .bottom, .menu-toggle.active .bottom {
  stroke-dashoffset: -68px;
}

.burger.active .line, .menu-toggle.active .line {
  stroke: var(--cl-white);
}

.burger .line, .menu-toggle .line {
  fill: none;
  transition: stroke 0.3s ease, stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--cl-navy);
  stroke-width: 3.5;
  stroke-linecap: round;
}

.burger .top, .menu-toggle .top {
  stroke-dasharray: 40 121;
}

.burger .bottom, .menu-toggle .bottom {
  stroke-dasharray: 40 121;
}

.burger:hover .line, .menu-toggle:hover .line {
  stroke: var(--cl-charcoal);
}

.burger.active:hover .line, .menu-toggle.active:hover .line {
  stroke: var(--cl-aqua);
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.header-close-wrapper.active {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  cursor: pointer;
}

/* end of HEADER COMPONENTS */
.footer {
  padding-top: clamp(40px, 40px + 20 * (100vw - 768px) / 672, 60px);
  padding-bottom: clamp(48px, 48px + 32 * (100vw - 768px) / 672, 80px);
  background: var(--cl-charcoal);
  color: var(--cl-white);
  overflow: hidden;
  /* &-base {
    .footer__logo {
      --arw: 161;
      --arh: 62;
      width: ac(161px, 117px);
    }
  } */
}

.footer__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer__wrap:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 26 * (100vw - 768px) / 672, 50px);
}

@media (max-width: 1023px) {
  .footer__wrap {
    flex-direction: column;
    justify-content: flex-start;
    grid-row-gap: 32px;
  }
}
.footer__content {
  width: 48.05%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1023px) {
  .footer__content {
    width: 100%;
    max-width: 615px;
  }
}
.footer__logo {
  --arw: 224;
  --arh: 88;
  width: clamp(117px, 117px + 107 * (100vw - 768px) / 672, 224px);
  transition: 0.3s;
}

.footer__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
}

.footer__logo svg {
  width: 100%;
  transition: 0.4s all ease;
}

@media (min-width: 576px) {
  .footer__logo:hover svg {
    transform: scale(1.05);
  }
  .footer__logo:hover svg path {
    fill: white;
  }
}
.footer__logo:not(:last-child) {
  margin-bottom: clamp(30px, 32px + -2 * (100vw - 768px) / 672, 32px);
}

.footer__title.heading-4 {
  line-height: normal;
}

.footer__title:not(:last-child) {
  margin-bottom: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.footer__text-buttons-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.footer__text {
  width: 100%;
  max-width: clamp(328px, 328px + 42 * (100vw - 768px) / 672, 370px);
}

.footer__nav-form {
  width: 37.42%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .footer__nav-form {
    width: 45%;
  }
}
@media (max-width: 1023px) {
  .footer__nav-form {
    width: 100%;
    max-width: 690px;
  }
}
.footer__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.footer__nav:not(:last-child) {
  margin-bottom: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
}

.footer__nav-title {
  color: var(--cl-aqua);
}

.footer__nav-title:not(:last-child) {
  margin-bottom: clamp(18px, 20px + -2 * (100vw - 768px) / 672, 20px);
}

.footer__nav-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: clamp(29px, 29px + 4 * (100vw - 768px) / 672, 33px);
  grid-row-gap: 16px;
}

.footer__nav-list li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer__nav-list a {
  font-size: 18px;
  line-height: 22px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s ease;
}

.footer__nav-list a:before {
  bottom: 0;
  right: auto;
  height: 1px;
  width: 0;
  background: var(--cl-white);
  transition: width 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  content: "";
  position: absolute;
  z-index: -1;
}

.footer__nav-list a:hover {
  color: var(--cl-aqua);
}

.footer__nav-list a:hover:before {
  left: 0;
  right: auto;
  width: 100%;
  background-color: var(--cl-aqua);
}

@media (max-width: 551px) {
  .footer__nav-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 28px;
    width: 100%;
    max-width: 460px;
  }
}
@media (max-width: 374px) {
  .footer__nav-list {
    grid-column-gap: 16px;
  }
}
.footer__nav-list:not(:last-child) {
  margin-bottom: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
}

.footer__form-title {
  width: 100%;
}

/* &.heading-5 {
      @media (max-width: 551px) {
        font-size: 20px;
      }
    } */
.footer__form-title:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.footer__form {
  width: 100%;
  gap: clamp(10px, 12px + -2 * (100vw - 768px) / 672, 12px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer__form-input {
  flex: 1;
}

.footer__form-input .meta-input input {
  background-color: transparent;
  color: #FFF;
}

.footer__form-input .meta-input input::-moz-placeholder {
  color: #FFF;
}

.footer__form-input .meta-input input::placeholder {
  color: #FFF;
}

.footer__form-input .meta-input #footer-email:focus {
  border-color: var(--cl-aqua);
  caret-color: var(--cl-aqua);
}

.footer__form-btn i {
  margin-top: 2px;
  margin-right: 2px;
  transform: rotate(0);
}

.footer__form-btn:hover i {
  transform: rotate(45deg) !important;
}

.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--cl-aqua);
  padding-top: clamp(19px, 23px + -4 * (100vw - 768px) / 672, 23px);
  max-width: 1270px;
  width: 100%;
  position: relative;
  grid-row-gap: 20px;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .footer__bottom {
    border-color: #537770;
    grid-row-gap: 27px;
  }
}
.footer__info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-row-gap: 17px;
  grid-column-gap: 20px;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: clamp(0px, 0px + 8 * (100vw - 768px) / 672, 8px);
}

@media (max-width: 551px) {
  .footer__info {
    grid-column-gap: 27px;
  }
}
.footer__info a {
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.footer__info a:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--cl-white);
  transition: 0.3s;
  content: "";
  position: absolute;
  z-index: -1;
}

.footer__info a:hover {
  color: var(--cl-aqua);
}

.footer__info a:hover:before {
  height: 50%;
  background-color: var(--cl-navy);
}

@media (max-width: 640px) {
  .footer__info {
    order: 2;
  }
}
@media (max-width: 451px) {
  .footer__info p {
    order: 2;
    width: 100%;
  }
  .footer__info a {
    order: 1;
  }
}
.footer__socials {
  gap: 8px !important;
}

.footer__socials .social-link {
  font-size: 32px;
}

@media (max-width: 640px) {
  .footer__socials {
    order: 1;
  }
}
.footer__developer {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.footer__developer img {
  -o-object-fit: contain;
  object-fit: contain;
  transition: 0.3s;
}

.footer__developer:hover img {
  transform: scale(1.03);
}

@media (min-width: 641px) {
  .footer__developer {
    position: absolute;
    bottom: -15px;
    transform: translateY(100%) translateX(-50%);
    left: 50%;
  }
}
@media (max-width: 640px) {
  .footer__developer {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: -20px;
    order: 3;
  }
}
:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;
  --pp-black: #000;
  --pp-black-rgb: 0 0 0;
  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;
  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;
  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;
  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;
  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;
  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;
}

.popup.active {
  display: block;
}

.popup__close-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
  transition: all 0.5s ease;
  animation-duration: 0.3s;
  animation-name: opacityPopupBg;
}

.popup__block {
  --pp-default-translate-x-from: -50%;
  --pp-default-translate-x-to: -50%;
  --pp-default-translate-y-from: -50%;
  --pp-default-translate-y-to: -50%;
  --pp-opacity-from: 0;
  --pp-opacity-to: 1;
  background: var(--pp-white);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: clamp(600px, 600px + 250 * (100vw - 768px) / 672, 850px);
  padding: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
  transition: all 300ms ease-out;
  animation-duration: 0.6s;
}

.popup__block.without-opacity {
  --pp-opacity-from: 1;
}

.popup__block.pos-l, .popup__block.pos-r {
  --pp-default-translate-x-from: 0;
  --pp-default-translate-x-to: 0;
}

.popup__block.pos-t, .popup__block.pos-b {
  --pp-default-translate-y-from: 0;
  --pp-default-translate-y-to: 0;
}

.popup__block.pos-t {
  top: 0;
  bottom: auto;
  bottom: initial;
}

.popup__block.pos-r {
  right: 0;
  left: auto;
  left: initial;
}

.popup__block.pos-b {
  top: auto;
  top: initial;
  bottom: 0;
}

.popup__block.pos-l {
  right: auto;
  right: initial;
  left: 0;
}

.popup__block.full-height {
  max-height: 100vh;
  height: 100%;
  right: 0;
  left: auto;
  left: initial;
  --pp-default-translate-x-from: 0;
  --pp-default-translate-x-to: 0;
}

.popup__block.full-height .popup__content {
  max-height: calc(var(--vh, 1vh) * 72);
}

.popup__block.slide-l {
  --pp-default-translate-x-from: 100%;
}

.popup__block.slide-r {
  --pp-default-translate-x-from: -100%;
}

.popup__block.slide-t {
  --pp-default-translate-y-from: -100%;
}

.popup__block.slide-b {
  --pp-default-translate-y-from: 100%;
}

.popup__block.slide-t, .popup__block.slide-r, .popup__block.slide-b, .popup__block.slide-l {
  animation-name: popupSlide;
}

.popup__title {
  color: var(--pp-dark-blue);
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  line-height: 1.2;
  font-family: var(--pp-font-main);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.popup__btn-close {
  position: absolute;
  right: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  top: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  transition: all 300ms ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup__btn-close .close-clip {
  transition: inherit;
  width: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  height: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  background: var(--pp-dark-blue);
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.popup__btn-close i {
  transition: inherit;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: var(--pp-dark-blue);
}

.popup__btn-close:hover .close-clip {
  background: var(--pp-blue);
  transform: scale(1.05);
}

.popup__btn-close:hover i {
  color: var(--pp-blue);
  transform: scale(1.05);
}

.popup__content {
  max-height: calc(var(--vh, 1vh) * 66);
}

.popup__content .simplebar-track.simplebar-vertical {
  width: 4px;
  background: var(--pp-light-gray);
  border-radius: 4px;
  transform: translateX(10px);
}

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--pp-dark-blue);
  border-radius: 4px;
}

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.popup__content .popup-form__container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

@media not all and (min-width: 640px) {
  .popup__content .popup-form__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.popup__content .popup-form__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  flex-wrap: wrap;
}

.popup__content .popup-form__bottom .popup-btn {
  margin-left: auto;
}

.popup__content .popup-btn {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  font-weight: 700;
  font-family: var(--pp-font-main);
  line-height: 1.2;
  text-transform: uppercase;
  background-color: var(--pp-dark-blue);
  color: var(--pp-white);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 12px 18px;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  position: relative;
  overflow: hidden;
}

.popup__content .popup-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: skew(45deg);
  pointer-events: none;
}

.popup__content .popup-btn:hover::after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.7) 100%);
  animation: gradientPopupBtn 0.3s linear forwards;
}

.popup__content .popup-btn:active {
  transform: scale(0.9);
  opacity: 0.9;
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from)) translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }
  to {
    transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}
@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }
  100% {
    transform: translateX(100%) skew(45deg);
  }
}
.meta-input label, .meta-input .label, .meta-select label, .meta-select .label, .meta-upload-file label, .meta-upload-file .label {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.meta-input label span, .meta-input .label span, .meta-select label span, .meta-select .label span, .meta-upload-file label span, .meta-upload-file .label span {
  display: inline-block;
  color: var(--cl-primary);
}

.dark-bg .meta-input label, .dark-bg .meta-input .label, .dark-bg .meta-select label, .dark-bg .meta-select .label, .dark-bg .meta-upload-file label, .dark-bg .meta-upload-file .label {
  color: var(--cl-white);
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.meta-input input, .meta-input textarea {
  width: 100%;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-charcoal);
  border: 2px solid var(--cl-white);
  border-radius: 38px;
  outline: none;
  transition: box-shadow 0.25s ease, border-color 0.3s ease, caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  padding: 10px clamp(25px, 25px + 4 * (100vw - 768px) / 672, 29px);
  height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
  background: var(--cl-white);
  /*&.error {
    border-color: var(--cl-red);
    box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
  }*/
}

.meta-input input:-webkit-autofill, .meta-input input:-webkit-autofill:hover, .meta-input input:-webkit-autofill:focus, .meta-input input:-webkit-autofill:active, .meta-input textarea:-webkit-autofill, .meta-input textarea:-webkit-autofill:hover, .meta-input textarea:-webkit-autofill:focus, .meta-input textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--cl-charcoal) !important;
}

.meta-input input::-moz-placeholder, .meta-input textarea::-moz-placeholder {
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: var(--cl-charcoal);
  opacity: 0.7;
}

.meta-input input::placeholder, .meta-input textarea::placeholder {
  transition: all 0.3s ease;
  color: var(--cl-charcoal);
  opacity: 0.7;
}

.meta-input input:focus, .meta-input textarea:focus {
  border-color: var(--cl-navy);
  caret-color: var(--cl-navy);
  box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
}

.meta-input textarea {
  padding-top: 16px;
  min-height: clamp(78px, 90px + -12 * (100vw - 768px) / 672, 90px);
  max-height: clamp(78px, 90px + -12 * (100vw - 768px) / 672, 90px);
  overflow-y: auto;
  resize: none;
  clip-path: inset(-1px -1px -1px -1px round clamp(28px, 28px + 11 * (100vw - 768px) / 672, 39px));
  border-radius: clamp(28px, 28px + 11 * (100vw - 768px) / 672, 39px);
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

.meta-input textarea::-webkit-scrollbar {
  width: 3px;
}

.meta-input textarea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 2px;
}

.meta-input textarea::-webkit-scrollbar-thumb {
  background: var(--cl-primary);
  border-radius: 2px;
}

.meta-input__help, .meta-input__error {
  margin-top: 5px;
  font-family: var(--font-main);
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
  line-height: 1.2;
  font-weight: 700;
}

.meta-input__help {
  color: var(--cl-white);
}

/*&__error {
    color: var(--cl-red);
  }*/
.meta-input.form-type input, .meta-input.form-type textarea {
  border-radius: 8px;
  background-color: var(--cl-light-bg);
  border: 1px solid var(--cl-aqua);
  padding-left: 19px;
  padding-right: 19px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-navy-80);
}

.meta-input.form-type input:-webkit-autofill, .meta-input.form-type input:-webkit-autofill:hover, .meta-input.form-type input:-webkit-autofill:focus, .meta-input.form-type input:-webkit-autofill:active, .meta-input.form-type textarea:-webkit-autofill, .meta-input.form-type textarea:-webkit-autofill:hover, .meta-input.form-type textarea:-webkit-autofill:focus, .meta-input.form-type textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--cl-navy-80) !important;
}

.meta-input.form-type input::-moz-placeholder, .meta-input.form-type textarea::-moz-placeholder {
  color: var(--cl-navy-80);
}

.meta-input.form-type input::placeholder, .meta-input.form-type textarea::placeholder {
  color: var(--cl-navy-80);
}

.meta-input.profile-type input, .meta-input.profile-type textarea {
  border-radius: 8px;
  background-color: var(--cl-white);
  border: 1px solid rgba(53, 53, 63, 0.3);
  padding-left: 19px;
  padding-right: 19px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-charcoal);
}

.meta-input.profile-type input:-webkit-autofill, .meta-input.profile-type input:-webkit-autofill:hover, .meta-input.profile-type input:-webkit-autofill:focus, .meta-input.profile-type input:-webkit-autofill:active, .meta-input.profile-type textarea:-webkit-autofill, .meta-input.profile-type textarea:-webkit-autofill:hover, .meta-input.profile-type textarea:-webkit-autofill:focus, .meta-input.profile-type textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--cl-charcoal) !important;
}

.meta-input.profile-type input::-moz-placeholder, .meta-input.profile-type textarea::-moz-placeholder {
  color: var(--cl-charcoal);
  opacity: 0.8;
}

.meta-input.profile-type input::placeholder, .meta-input.profile-type textarea::placeholder {
  color: var(--cl-charcoal);
  opacity: 0.8;
}

.meta-input.profile-type input:focus, .meta-input.profile-type textarea:focus {
  border-color: var(--cl-navy-80);
}

.meta-input.profile-type textarea {
  clip-path: inset(-1px -1px -1px -1px round 8px);
  border-radius: 8px;
  min-height: clamp(90px, 90px + 94 * (100vw - 768px) / 672, 184px);
  max-height: clamp(90px, 90px + 94 * (100vw - 768px) / 672, 184px);
}

.dark-bg .meta-input input:focus, .dark-bg .meta-input textarea:focus {
  border-color: var(--cl-aqua);
}

.is-safari .meta-input textarea::-webkit-scrollbar {
  width: auto;
  width: initial;
}

.is-safari .meta-input textarea::-webkit-scrollbar-track {
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  border-radius: 0;
  border-radius: initial;
}

.is-safari .meta-input textarea::-webkit-scrollbar-thumb {
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  border-radius: 0;
  border-radius: initial;
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.meta-select select {
  opacity: 0;
  height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
}

.meta-select .choices {
  width: 100%;
  margin-bottom: 0;
  overflow: visible;
}

.meta-select .choices__inner {
  border: 1px solid var(--cl-aqua);
  border-radius: 8px;
  outline: none;
  transition: box-shadow 0.3s ease, border-color 0.3s ease, border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
  padding: 5px 19px !important;
  background: var(--cl-light-bg);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-navy-80);
  height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
  min-height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
}

.meta-select .choices__inner:hover {
  box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
}

.meta-select .choices.is-open:not(.is-flipped) .choices__inner {
  border-radius: 6px 6px 0 0;
}

.meta-select .choices.is-open:not(.is-flipped) .choices__list--dropdown, .meta-select .choices.is-open:not(.is-flipped) .choices__list[aria-expanded] {
  border-top: none;
  margin-top: 0;
  border-radius: 0 0 6px 6px;
}

.meta-select .choices.is-open.is-flipped .choices__inner {
  border-radius: 0 0 6px 6px;
}

.meta-select .choices.is-open.is-flipped .choices__list--dropdown, .meta-select .choices.is-open.is-flipped .choices__list[aria-expanded] {
  margin-bottom: 0;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
}

.meta-select .choices__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meta-select .choices__placeholder {
  color: var(--cl-navy-80);
  opacity: 1;
}

.meta-select .choices__list {
  padding: 0;
  background-color: var(--cl-light-bg);
}

.meta-select .choices__list .choices__item {
  padding-right: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-navy-80);
}

.meta-select .choices__list .choices__item.is-selected {
  color: var(--cl-navy);
}

/*&--multiple {
        color: var(--cl-dark-blue);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }*/
.meta-select .choices__list--dropdown .choices__item {
  color: var(--cl-navy-80);
}

.meta-select .choices[data-type*=select-one], .meta-select .choices[data-type*=select-multiple] {
  cursor: pointer;
}

.meta-select .choices[data-type*=select-one]:after, .meta-select .choices[data-type*=select-multiple]:after {
  border: none;
  border-bottom: 1px solid var(--cl-navy-80);
  border-right: 1px solid var(--cl-navy-80);
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: clamp(18px, 18px + 7 * (100vw - 768px) / 672, 25px);
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg) scale(1.5);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.meta-select .choices.is-open:after {
  transform: rotate(-135deg) scale(1.5);
}

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted, .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: transparent;
}

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted:not(.is-selected), .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:not(.is-selected) {
  color: var(--cl-primary);
}

.meta-select .choices__list--dropdown, .meta-select .choices .choices__list[aria-expanded] {
  border: 1px solid var(--cl-aqua);
  z-index: 20;
  border-radius: 6px;
}

.meta-select .choices[data-type*=select-one] .choices__input {
  margin: 2px;
  max-width: calc(100% - 4px);
  border: 2px solid var(--cl-lilac-500);
  border-radius: 8px;
  background: transparent;
  color: var(--cl-primary);
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  line-height: 1.2;
  font-weight: 500;
  font-family: var(--font-main);
}

.meta-select .choices[data-type*=select-multiple] .choices__inner {
  cursor: pointer;
}

.meta-select.sort-type .choices .choices__inner {
  border-color: rgba(53, 53, 63, 0.3);
  background-color: var(--cl-white);
}

.meta-select.sort-type .choices .choices__list {
  background-color: var(--cl-white);
}

/*padding-left: 120px !important;
          position: relative;*/
.meta-select.sort-type .choices .choices__list.choices__list--single .choices__item.choices__item--selectable:before {
  display: inline-block;
  content: "Sort by ‒";
  margin-right: 4px;
}

.meta-select.sort-type .choices[data-type*=select-one]:after, .meta-select.sort-type .choices[data-type*=select-multiple]:after {
  border-color: var(--cl-charcoal);
}

.meta-select.profile-type .choices .choices__inner {
  border-color: rgba(53, 53, 63, 0.3);
  background-color: var(--cl-white);
}

.meta-select.profile-type .choices .choices__list {
  background-color: var(--cl-white);
}

.meta-select.profile-type .choices .choices__list.choices__list--single .choices__item.choices__item--selectable {
  /*padding-left: 120px !important;
  position: relative;*/
  color: var(--cl-charcoal);
  opacity: 0.8;
}

.password-input {
  position: relative;
}

.password-input__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  right: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  bottom: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  position: absolute;
  z-index: 10;
  --size: clamp(22px, calc(22px + 2.00 * ((100vw - 768px) / 672)), 24px);
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  color: var(--cl-charcoal);
  transition: 0.3s;
}

.password-input__btn i {
  font-size: 16px;
}

.password-input__btn i.icon-not-show {
  font-size: 18px;
}

.password-input__btn:hover {
  color: var(--cl-navy);
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.meta-upload-file label, .meta-upload-file .label {
  color: #757575;
  padding-bottom: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  cursor: pointer;
}

.meta-upload-file__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: clamp(10px, 10px + 7 * (100vw - 768px) / 672, 17px);
  width: 100%;
}

.meta-upload-file__container:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 3 * (100vw - 768px) / 672, 13px);
}

.meta-upload-file__input {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  border: 1px solid var(--cl-navy);
  padding: clamp(10px, 10px + 4 * (100vw - 768px) / 672, 14px) clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  border-radius: 12px;
  transition: all 0.3s ease;
  background: var(--cl-white);
  cursor: pointer;
  position: relative;
  height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
}

.meta-upload-file__input input {
  position: absolute;
  inset: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.meta-upload-file__input input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

.meta-upload-file__input span {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--cl-navy);
  transition: 0.3s;
}

.meta-upload-file__input:hover {
  background-color: var(--cl-aqua);
  border-color: var(--cl-charcoal);
  color: var(--cl-charcoal);
}

.meta-upload-file__input:hover span {
  color: var(--cl-charcoal);
}

.meta-upload-file__info {
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
  line-height: normal;
  font-style: italic;
}

.meta-upload-file.drag .meta-upload-file__input {
  background-color: var(--cl-primary);
  box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-primary)/0.3);
}

.meta-upload-file.drop .meta-upload-file__input {
  border-style: solid;
}

.meta-upload-file.drop:not(.done) .meta-upload-file__input {
  transform: scale(0.9);
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.meta-checkbox-list__item:not(:last-child) {
  margin-bottom: 8px;
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.meta-checkbox__box {
  --size: clamp(20px, calc(20px + 5.00 * ((100vw - 768px) / 672)), 25px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  border: 1px solid var(--cl-black);
  margin-right: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background: transparent;
}

.meta-checkbox__box:hover {
  border-color: var(--cl-primary);
}

.meta-checkbox__box .check-clip, .meta-checkbox__box .circle-clip {
  width: 85%;
  height: 85%;
  background-color: var(--cl-black);
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.meta-checkbox__box .check-clip {
  clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
}

.meta-checkbox__box .circle-clip {
  clip-path: circle(50%);
}

.meta-checkbox__box i {
  opacity: 0;
  font-size: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
  color: var(--cl-charcoal);
  transition: opacity 0.3s ease, color 0.3s ease;
}

.meta-checkbox input {
  display: none;
}

.meta-checkbox input[type=checkbox] + .meta-checkbox__box {
  border-radius: 4px;
}

.meta-checkbox input[type=radio] + .meta-checkbox__box {
  border-radius: 50%;
}

.meta-checkbox__text {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 1.4;
  font-family: var(--font-main);
  color: var(--cl-charcoal);
}

.meta-checkbox__text a {
  font: inherit;
  color: var(--cl-primary);
  text-decoration: underline;
  text-decoration-color: var(--cl-primary);
  transition: all 0.25s ease;
}

.meta-checkbox__text a:hover {
  color: var(--cl-aqua);
  text-decoration-color: var(--cl-aqua);
}

.meta-checkbox input:checked ~ .meta-checkbox__box {
  border-color: var(--cl-navy);
}

.meta-checkbox input:checked ~ .meta-checkbox__box .check-clip, .meta-checkbox input:checked ~ .meta-checkbox__box .circle-clip, .meta-checkbox input:checked ~ .meta-checkbox__box i {
  opacity: 1;
  color: var(--cl-primary);
}

.meta-checkbox input:checked ~ .meta-checkbox__box:hover {
  border-color: var(--cl-charcoal);
}

input[type=password]::-webkit-contacts-auto-fill-button, input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.btn {
  cursor: pointer;
  border: 1px solid var(--cl-white);
  padding: clamp(10px, 10px + 4 * (100vw - 768px) / 672, 14px) clamp(24px, 24px + 5 * (100vw - 768px) / 672, 29px);
  border-radius: 12px;
  transition: all 0.3s ease;
  background: transparent;
  background: var(--cl-white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
  grid-column-gap: clamp(4px, 4px + 6 * (100vw - 768px) / 672, 10px);
}

.btn__text {
  font-family: var(--font-main);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-charcoal);
  transition: color 0.3s ease, transform 0.3s ease;
  transform: scale(1);
}

.btn__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.3s ease;
  color: var(--cl-charcoal);
}

.btn:hover {
  background-color: var(--cl-aqua);
}

.btn:hover .btn__icon {
  transform: rotate(-45deg);
}

.btn.btn-transparent {
  background-color: transparent;
}

.btn.btn-transparent .btn__text {
  color: var(--cl-white);
}

.btn.btn-transparent .btn__icon {
  color: var(--cl-white);
}

.btn.btn-transparent:hover {
  background-color: var(--cl-aqua);
}

.btn.btn-transparent:hover .btn__text {
  color: var(--cl-charcoal);
}

.btn.btn-transparent:hover .btn__icon {
  color: var(--cl-charcoal);
}

.btn.btn-navy {
  border-color: var(--cl-navy);
  background-color: var(--cl-navy);
}

.btn.btn-navy .btn__text {
  color: var(--cl-white);
}

.btn.btn-navy .btn__icon {
  color: var(--cl-white);
}

.btn.btn-navy:hover {
  background-color: var(--cl-white);
}

.btn.btn-navy:hover .btn__text {
  color: var(--cl-charcoal);
}

.btn.btn-navy:hover .btn__icon {
  color: var(--cl-charcoal);
}

.btn.btn-icon-not-rotate:hover .btn__icon {
  transform: scale(1.1);
}

.btn.btn-profile-header:hover .btn__icon {
  transform: scale(1.1);
}

.btn.btn-border {
  border: 1px solid var(--cl-navy);
  background-color: transparent;
}

.btn.btn-border .btn__text {
  color: var(--cl-navy);
}

.btn.btn-border .btn__icon {
  color: var(--cl-navy);
}

.btn.btn-border:hover {
  background-color: var(--cl-aqua);
}

.btn.btn-red {
  border-color: var(--cl-red);
  background-color: var(--cl-red);
}

.btn.btn-red .btn__text {
  color: var(--cl-white);
}

.btn.btn-red .btn__icon {
  color: var(--cl-white);
}

.btn.btn-red:hover {
  background-color: var(--cl-white);
}

.btn.btn-red:hover .btn__text {
  color: var(--cl-charcoal);
}

.btn.btn-red:hover .btn__icon {
  color: var(--cl-charcoal);
}

.btn.btn-border-red {
  border: 1px solid var(--cl-red);
  background-color: #f5f5f5;
}

.btn.btn-border-red .btn__text {
  color: var(--cl-red);
}

.btn.btn-border-red .btn__icon {
  color: var(--cl-red);
}

.btn.btn-border-red:hover {
  background-color: var(--cl-red);
}

.btn.btn-border-red:hover .btn__text {
  color: var(--cl-white);
}

.btn.btn-border-red:hover .btn__icon {
  color: var(--cl-white);
}

.btn.btn-border-white {
  border: 1px solid var(--cl-white);
  background-color: transparent;
}

.btn.btn-border-white .btn__text {
  color: var(--cl-white);
}

.btn.btn-border-white .btn__icon {
  color: var(--cl-white);
}

.btn.btn-border-white:hover {
  background-color: var(--cl-aqua);
}

.btn.btn-border-white:hover .btn__text {
  color: var(--cl-charcoal);
}

.btn.btn-border-white:hover .btn__icon {
  color: var(--cl-charcoal);
}

@media (max-width: 360px) {
  .btn {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  flex-wrap: wrap;
}

.btn-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 400;
  color: var(--cl-charcoal);
  cursor: pointer;
  transition: color 0.3s ease;
}

.btn-show-hide__icon {
  border: 1px solid var(--cl-charcoal);
  width: clamp(32px, 32px + 6 * (100vw - 768px) / 672, 38px);
  height: clamp(32px, 32px + 6 * (100vw - 768px) / 672, 38px);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
}

.btn-show-hide__icon i {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  color: var(--cl-charcoal);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 0.3s ease;
}

.btn-show-hide__show {
  display: flex;
}

.btn-show-hide__hide {
  display: none;
}

.btn-show-hide:not(.is-visible) {
  opacity: 0;
  display: none;
}

.btn-show-hide:not(.active) .btn-show-hide__icon i.icon-plus-second {
  opacity: 1;
}

.btn-show-hide.active .btn-show-hide__show {
  display: none;
}

.btn-show-hide.active .btn-show-hide__hide {
  display: flex;
}

.btn-show-hide.active .btn-show-hide__icon i.icon-plus-second {
  opacity: 0;
}

.btn-show-hide:hover {
  color: var(--cl-navy);
}

.text-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

.text-btn__text {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: var(--cl-charcoal);
  transition: 0.3s;
}

.text-btn__icon {
  --size: 36px;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--cl-charcoal);
  transition: 0.3s;
}

.text-btn__icon i {
  font-size: 20px;
  transition: 0.3s;
}

.text-btn.save-btn .text-btn__icon {
  border: none;
  --size: auto;
}

.text-btn.save-btn .text-btn__icon i {
  font-size: 24px;
}

.text-btn.not-circle-type .text-btn__icon {
  border: none;
  --size: auto;
}

.text-btn.not-circle-type .text-btn__icon i {
  font-size: 24px;
}

.text-btn.not-icon-rotate-type .text-btn__icon i.icon-show {
  font-size: 14px;
}

.text-btn:hover .text-btn__text {
  color: var(--cl-navy);
}

.text-btn:hover .text-btn__icon {
  color: var(--cl-navy);
}

.text-btn:hover .text-btn__icon i {
  transform: rotate(-45deg);
}

.text-btn:hover.save-btn .text-btn__icon i {
  transform: none;
}

.text-btn:hover.not-circle-type .text-btn__icon i {
  transform: none;
}

.text-btn:hover.not-icon-rotate-type .text-btn__icon i {
  transform: none;
}

.btn-circle {
  --size: clamp(48px, calc(48px + 8.00 * ((100vw - 768px) / 672)), 56px);
  width: var(--size);
  height: var(--size);
  border: 2px solid var(--cl-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background: var(--cl-white);
  transition: all 0.3s ease;
}

.btn-circle i {
  font-size: 24px;
  transition: all 0.3s ease;
  color: var(--cl-charcoal);
}

.btn-circle:hover {
  background-color: var(--cl-aqua);
}

.btn-circle:hover i {
  transform: rotate(-45deg);
}

.link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 12px;
  cursor: pointer;
}

.link-btn__text {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  font-weight: 600;
  line-height: 1.36;
  color: var(--cl-black);
}

.link-btn__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cl-pink);
  border-radius: 50%;
  --size: clamp(34px, calc(34px + 2.00 * ((100vw - 768px) / 672)), 36px);
  width: var(--size);
  height: var(--size);
  transition: background-color 0.3s ease;
}

.link-btn__icon i {
  transform: rotate(45deg);
  color: var(--cl-white);
  transition: all 0.3s ease;
  font-size: clamp(14px, 14px + 1 * (100vw - 768px) / 672, 15px);
}

.link-btn:hover .link-btn__icon {
  background-color: var(--cl-navy);
}

.link-btn:hover .link-btn__icon i {
  transform: rotate(0);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 551px) {
  .buttons {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons .btn {
    width: 100%;
  }
}
.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--cl-white);
  transition: color 0.3s;
}

.social-link:hover {
  color: var(--cl-aqua);
}

.social-link.social-link-navy {
  color: var(--cl-royal-blue);
}

.social-link.social-link-navy:hover {
  color: var(--cl-charcoal);
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.swiper-buttons {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;
}

.swiper-buttons .swiper-button {
  position: relative;
  transform: none;
  margin: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  display: flex;
  --size: clamp(48px, calc(48px + 12.00 * ((100vw - 768px) / 672)), 60px);
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  color: var(--cl-charcoal, #346c50);
  border: 1px solid rgba(53, 53, 63, 0.5);
  border-radius: 50%;
  transition: 0.3s;
  background-color: var(--cl-white);
  margin-top: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
}

.swiper-buttons .swiper-button::after {
  display: none;
}

.swiper-buttons .swiper-button::before {
  content: "\e921";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "icomoon" !important;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  margin-top: clamp(0px, 0px + 1 * (100vw - 768px) / 672, 1px);
}

.swiper-buttons .swiper-button.swiper-button-prev::before {
  content: "\e920";
}

.swiper-buttons .swiper-button:hover {
  border-color: var(--cl-navy);
  background: var(--cl-navy, #346c50);
  color: var(--cl-white, #fff);
}

.swiper-buttons .swiper-button.swiper-button-lock {
  display: none;
}

.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  min-width: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  /*&.pagination-next {
    margin-left: ac(16px, 8px);
  }

  &.pagination-prev {
    margin-right: ac(16px, 8px);
  }*/
}

.pagination-btn i {
  font-size: 10px;
  color: var(--cl-navy);
  transition: color 0.3s ease;
}

.pagination-btn.pagination-btn-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-btn:hover:not(.pagination-btn-disabled) {
  border-color: var(--cl-navy);
}

.pagination-num {
  text-align: center;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  height: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  min-width: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-num span {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  color: var(--cl-charcoal);
  transition: all 0.3s ease;
}

.pagination-num.active {
  cursor: not-allowed;
  border-color: var(--cl-navy);
}

.pagination-num.active span {
  color: var(--cl-navy);
}

.pagination-num.pointer-events-none {
  pointer-events: none;
  min-width: 28px;
  width: 28px;
}

.pagination-num:hover:not(.active) span {
  color: var(--cl-navy-80);
}

.pagination {
  margin-top: clamp(35px, 35px + 5 * (100vw - 768px) / 672, 40px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: clamp(4px, 4px + 4 * (100vw - 768px) / 672, 8px);
}

.line-decor {
  position: relative;
  overflow: hidden;
  height: clamp(6px, 6px + 3 * (100vw - 768px) / 672, 9px);
  width: clamp(80px, 80px + 62 * (100vw - 768px) / 672, 142px);
}

.line-decor:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  opacity: 0;
  transition: all 0.4s ease;
  background: var(--cl-aqua);
  content: "";
}

.line-decor[data-view].animated:before {
  width: 100%;
  opacity: 1;
}

/*@import 'team.pcss';
@import 'job.pcss';*/
.testimonial-card {
  background: var(--cl-white);
  border: 4px solid var(--cl-aqua);
  border-radius: 24px;
  padding-left: clamp(18px, 18px + 18 * (100vw - 768px) / 672, 36px);
  padding-right: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  padding-top: 12px;
  padding-bottom: clamp(20px, 20px + 16 * (100vw - 768px) / 672, 36px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.testimonial-card__logo {
  --arw: 157;
  --arh: 79;
  width: clamp(140px, 140px + 17 * (100vw - 768px) / 672, 157px);
}

.testimonial-card__logo:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 2 * (100vw - 768px) / 672, 26px);
}

.testimonial-card__text {
  line-height: 1.3;
}

.testimonial-card__text:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 3 * (100vw - 768px) / 672, 27px);
}

.testimonial-card__author {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.testimonial-card__author-image {
  width: clamp(64px, 64px + 6 * (100vw - 768px) / 672, 70px);
  border-radius: 50%;
}

.testimonial-card__author-image:after {
  position: absolute;
  inset: -1px;
  z-index: 1;
  border: 4px solid var(--cl-aqua);
  border-radius: 50%;
  content: "";
}

.testimonial-card__author-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  grid-row-gap: clamp(6px, 6px + 1 * (100vw - 768px) / 672, 7px);
}

.testimonial-card__author-title {
  font-family: var(--font-second);
  font-weight: 700;
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: normal;
}

.testimonial-card__author-subtitle {
  font-weight: 400;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: normal;
  color: var(--cl-navy);
  opacity: 0.8;
}

.job-card {
  position: relative;
  z-index: 5;
  padding: clamp(20px, 20px + 10 * (100vw - 768px) / 672, 30px) clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  background: transparent;
  border-radius: 10px;
  height: auto;
  transition: background-color 0.3s ease;
}

/* opacity: 0.96; */
.job-card:not(.job-card-second) {
  background: rgba(53, 53, 63, 0.96);
  -webkit-backdrop-filter: blur(6.95px);
  backdrop-filter: blur(6.95px);
}

.job-card:after {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  content: "";
  opacity: 0;
  transition: all 0.3s ease;
  background: var(--cl-aqua);
  z-index: -2;
  height: 100%;
}

.job-card:before {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  content: "";
  opacity: 0;
  transition: all 0.3s ease;
  background: var(--cl-navy);
  z-index: -1;
}

.job-card__bg {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0.96;
  z-index: -3;
}

.job-card__bg:before {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  content: "";
  /* background: var(--cl-charcoal);
  backdrop-filter: blur(6.95px); */
}

.job-card__title {
  color: var(--cl-white);
  transition: color 0.3s ease;
}

.job-card__title:not(:last-child) {
  margin-bottom: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
}

@media (max-width: 551px) {
  .job-card__title {
    margin-top: -6px;
  }
}
.job-card__list {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: clamp(4px, 4px + 6 * (100vw - 768px) / 672, 10px);
}

.job-card__list li {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 130%;
  transition: color 0.3s ease;
  color: var(--cl-white);
}

@media (max-width: 640px) {
  .job-card__list li {
    line-height: 140%;
  }
}
.job-card__list:not(:last-child) {
  margin-bottom: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
}

.job-card__link {
  --size: clamp(40px, calc(40px + 16.00 * ((100vw - 768px) / 672)), 56px);
  width: var(--size);
  height: var(--size);
}

.job-card__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  grid-row-gap: 14px;
}

.job-card:hover:after {
  height: calc(100% + 6px);
  opacity: 1;
}

.job-card:hover:before {
  opacity: 1;
}

.job-card.job-card-second {
  padding: clamp(20px, 20px + 6 * (100vw - 768px) / 672, 26px) clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px) clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  min-height: clamp(280px, 280px + 50 * (100vw - 768px) / 672, 330px);
}

.job-card.job-card-second:after, .job-card.job-card-second:before {
  border-radius: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.job-card.job-card-second:after {
  content: none;
}

.job-card.job-card-second .job-card__bg {
  border-radius: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.job-card.job-card-second .job-card__bg:before {
  background-color: var(--cl-white);
  border: 1px solid var(--cl-aqua);
  border-radius: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.job-card.job-card-second .job-card__title {
  color: var(--cl-navy);
}

.job-card.job-card-second .job-card__title.heading-5 {
  font-size: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0;
}

.job-card.job-card-second .job-card__list li {
  color: var(--cl-charcoal);
}

.job-card.job-card-second .job-card__list:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 7 * (100vw - 768px) / 672, 31px);
}

.job-card.job-card-second:hover .job-card__title {
  color: var(--cl-white);
}

.job-card.job-card-second:hover .job-card__list li {
  color: var(--cl-white);
}

.job-card.job-card-second:hover .text-btn__text {
  color: var(--cl-white);
}

.job-card.job-card-second:hover .text-btn__icon {
  border-color: var(--cl-white);
  color: var(--cl-white);
}

.job-card.job-card-second:hover .text-btn:hover .text-btn__text {
  color: var(--cl-aqua);
}

.job-card.job-card-second:hover .text-btn:hover .text-btn__icon {
  border-color: var(--cl-aqua);
  color: var(--cl-aqua);
}

.blog-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
  height: auto;
}

.blog-card__image {
  --arw: 413;
  --arh: 322;
  width: 100%;
  -webkit-mask-image: url("../../assets/static/mask/blog-card-mask.svg");
  mask-image: url("../../assets/static/mask/blog-card-mask.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.blog-card__image img {
  transition: transform 0.3s ease;
}

.blog-card__image:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.blog-card__subtitle {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 122.22%;
  font-weight: 600;
  color: var(--cl-pink);
}

.blog-card__subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.blog-card__title {
  color: var(--cl-navy);
  transition: color 0.3s ease;
}

.blog-card__title:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 18 * (100vw - 768px) / 672, 48px);
}

.blog-card__buttons {
  margin-top: auto;
}

.blog-card:hover .blog-card__title {
  color: var(--cl-pink);
}

.blog-card:hover .blog-card__image img {
  transform: scale(1.04);
}

.looking-section {
  padding-top: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
  padding-bottom: 40px;
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.looking-section__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  grid-row-gap: 20px;
  width: 100%;
}

.looking-section__item {
  min-height: clamp(300px, 300px + 132 * (100vw - 768px) / 672, 432px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--cl-white);
}

.looking-section__item:before {
  position: absolute;
  --size: clamp(100px, calc(100px + 24.00 * ((100vw - 768px) / 672)), 124px);
  width: var(--size);
  height: var(--size);
  z-index: 1;
  -webkit-mask-image: url("../../assets/static/mask/radius-mask.svg");
  mask-image: url("../../assets/static/mask/radius-mask.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: var(--cl-white);
  top: -0.5px;
  right: -0.5px;
  content: "";
}

.looking-section__item:hover .looking-section__item-link {
  background-color: var(--cl-pink);
}

.looking-section__item:hover .looking-section__item-link i {
  transform: rotate(0);
}

.looking-section__item-image {
  position: absolute;
  inset: 0;
  border-radius: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  overflow: hidden;
}

.looking-section__item-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.looking-section__item-image:before, .looking-section__item-image:after {
  position: absolute;
  inset: 0;
  content: "";
  background: var(--cl-navy);
}

.looking-section__item-image:before {
  position: absolute;
  z-index: 1;
  mix-blend-mode: overlay;
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.looking-section__item-image:after {
  position: absolute;
  z-index: 2;
  mix-blend-mode: color;
}

.looking-section__item-content {
  margin-top: auto;
  padding: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 5;
}

.looking-section__item-title {
  --max-line-num: 4;
  letter-spacing: clamp(0.5px, 0.5px + 0.7 * (100vw - 768px) / 672, 1.2px);
}

.looking-section__item-title span {
  color: var(--cl-pink);
}

.looking-section__item-link {
  position: absolute;
  top: clamp(4px, 4px + 3 * (100vw - 768px) / 672, 7px);
  right: clamp(0px, 4px + -4 * (100vw - 768px) / 672, 4px);
  --size: clamp(64px, calc(64px + 16.00 * ((100vw - 768px) / 672)), 80px);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: var(--cl-white);
  cursor: pointer;
  background: var(--cl-navy);
  transition: background-color 0.3s ease;
  z-index: 10;
}

.looking-section__item-link i {
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
}

@media (max-width: 640px) {
  .looking-section .looking-section__list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.looking-section + .who-we-are-section {
  padding-top: clamp(0px, 0px + 40 * (100vw - 768px) / 672, 40px);
}

.who-we-are-section {
  background: var(--cl-aqua);
  z-index: 10;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 1700px) {
  .who-we-are-section {
    padding-bottom: 60px;
  }
}
.who-we-are-section .line-decor {
  bottom: clamp(20px, 30px + -10 * (100vw - 768px) / 672, 30px);
}

.who-we-are-section:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 47%;
  background: var(--cl-white);
  z-index: 1;
  content: "";
}

.who-we-are-section__wrap {
  position: relative;
  z-index: 2;
}

.who-we-are-section__container {
  position: relative;
  padding: clamp(60px, 60px + 60 * (100vw - 768px) / 672, 120px) clamp(20px, 20px + 44 * (100vw - 768px) / 672, 64px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--cl-white);
  z-index: 1;
}

.who-we-are-section__image {
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  overflow: hidden;
}

.who-we-are-section__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.who-we-are-section__title {
  width: 41.93%;
}

.who-we-are-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 55.3%;
}

.who-we-are-section__text span {
  color: var(--cl-pink);
}

@media (max-width: 768px) {
  .who-we-are-section .who-we-are-section__container {
    flex-direction: column;
    align-items: flex-start;
  }
  .who-we-are-section .who-we-are-section__title {
    width: 100%;
  }
  .who-we-are-section .who-we-are-section__title:not(:last-child) {
    margin-bottom: 30px;
  }
  .who-we-are-section .who-we-are-section__content {
    width: 100%;
  }
}
.who-we-are-section.areas-type {
  padding-top: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.who-we-are-section.areas-type .line-decor {
  bottom: clamp(8px, 30px + -22 * (100vw - 768px) / 672, 30px);
}

.who-we-are-section.areas-type:before {
  top: auto;
  bottom: 0;
  height: 120px;
  background: var(--cl-white);
}

.who-we-are-section.areas-type .who-we-are-section__image:after {
  position: absolute;
  inset: 0;
  background: var(--cl-navy);
  mix-blend-mode: color;
  content: "";
  border-radius: 24px;
  filter: blur(10px);
}

.who-we-are-section.areas-type .who-we-are-section__image img {
  filter: blur(10px);
}

@media (min-width: 769px) {
  .who-we-are-section.areas-type .who-we-are-section__title {
    width: 60%;
  }
  .who-we-are-section.areas-type .who-we-are-section__content {
    width: 36%;
  }
  .who-we-are-section.areas-type .who-we-are-section__buttons {
    width: 100%;
    justify-content: flex-end;
  }
}
@media (max-width: 551px) {
  .who-we-are-section.areas-type .who-we-are-section__title {
    width: 100%;
    text-align: center;
  }
}
.who-we-are-section + .text-image-section {
  padding-top: 24px;
}

.text-image-section {
  padding-top: clamp(30px, 30px + 50 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(30px, 30px + 50 * (100vw - 768px) / 672, 80px);
  background: var(--cl-aqua);
  z-index: 5;
}

.text-image-section__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-image-section__image {
  width: 49.22%;
  border-radius: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  --arw: 630;
  --arh: 382;
}

.text-image-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 45.78%;
}

.text-image-section__text:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

@media (max-width: 950px) {
  .text-image-section .text-image-section__wrap {
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 30px;
  }
  .text-image-section .text-image-section__image {
    width: 100%;
    max-width: 600px;
  }
  .text-image-section .text-image-section__content {
    width: 100%;
  }
}
.areas-section {
  padding-top: clamp(8px, 20px + -12 * (100vw - 768px) / 672, 20px);
  padding-bottom: clamp(40px, 40px + 30 * (100vw - 768px) / 672, 70px);
  overflow: hidden;
}

.areas-section__slider {
  width: 100%;
  padding: 0 clamp(0px, 0px + 64 * (100vw - 768px) / 672, 64px);
}

.areas-section__slider .swiper {
  overflow: visible;
  margin-left: 0;
}

@media (max-width: 640px) {
  .areas-section__slider .swiper {
    width: 100%;
    max-width: 375px;
  }
}
.areas-section__slider .swiper-button {
  margin-top: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
}

.areas-section__list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  grid-row-gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.areas-section__item {
  width: 100%;
  display: grid;
  grid-template-columns: 41px repeat(1, minmax(0, 1fr));
  grid-column-gap: 10px;
  padding-right: clamp(50px, 50px + 20 * (100vw - 768px) / 672, 70px);
  position: relative;
  padding-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  border-bottom: 1px solid var(--cl-aqua);
  cursor: pointer;
}

.areas-section__item:hover .areas-section__item-link {
  opacity: 1;
}

.areas-section__item-count {
  color: var(--cl-pink);
}

.areas-section__item-title {
  color: var(--cl-navy);
  --max-line-num: 3;
}

@media (max-width: 390px) {
  .areas-section__item-title.heading-4 {
    font-size: 22px;
    letter-spacing: 0;
  }
}
@media (max-width: 374px) {
  .areas-section__item-title.heading-4 {
    font-size: 20px;
    letter-spacing: 0;
  }
}
.areas-section__item-link {
  position: absolute;
  top: clamp(10px, 10px + 21 * (100vw - 768px) / 672, 31px);
  right: 0;
  --size: clamp(40px, calc(40px + 22.00 * ((100vw - 768px) / 672)), 62px);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  color: var(--cl-white);
  cursor: pointer;
  background: var(--cl-pink);
  transition: opacity 0.3s ease;
  z-index: 10;
  opacity: 0;
  /*&:hover {
    background-color: var(--cl-pink);
  }*/
}

.areas-section__item-link i {
  transform: rotate(-45deg);
}

.areas-section + .clients-say-section {
  margin-top: 0;
}

.clients-say-section {
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
}

.clients-say-section__nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: clamp(32px, 32px + 40 * (100vw - 768px) / 672, 72px);
}

.clients-say-section__title {
  color: #3c3c3b;
  width: 100%;
}

.clients-say-section__title span:not(.word) {
  color: var(--cl-navy);
}

@media (max-width: 768px) {
  .clients-say-section__arrows {
    display: none;
  }
}
.clients-say-section__slider {
  width: 100%;
  max-width: 740px;
  /* overflow: hidden; */
}

.clients-say-section .swiper-buttons .swiper-button:before {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.clients-say-section__card {
  padding: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  border-radius: 20px;
  background: var(--cl-navy);
  color: #fff;
  transition: 0.4s all;
}

.clients-say-section__card-body {
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 400;
  line-height: 1.32em;
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  max-height: 10.56em;
  overflow: auto;
  scrollbar-width: thin;
  padding-right: clamp(0px, 0px + 2 * (100vw - 768px) / 672, 2px);
}

@media (max-width: 992px) {
  .clients-say-section__card-body {
    max-height: 9.24em;
  }
}
@media (max-width: 576px) {
  .clients-say-section__card-body {
    max-height: 17.16em;
  }
}
/* user, logo */
.clients-say-section__card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients-say-section__card-name {
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 500;
  margin-bottom: clamp(2px, 2px + 6 * (100vw - 768px) / 672, 8px);
}

.clients-say-section__card-position {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
}

.clients-say-section__card-logo {
  padding: 9px 34px;
  background-color: #fff;
  border: 1px solid #e7e8e9;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .clients-say-section__card-logo {
    display: none;
  }
}
.clients-say-section .swiper {
  overflow: visible;
}

.clients-say-section .swiper-slide-prev {
  opacity: 0.5;
  background-color: var(--cl-aqua);
  color: #3c3c3b;
}

.clients-say-section .swiper-slide-next {
  opacity: 0.5;
  background-color: var(--cl-aqua);
  color: #3c3c3b;
}

.clients-say-section .swiper-slide-next + .swiper-slide {
  opacity: 0.5;
  background-color: var(--cl-aqua);
  color: #3c3c3b;
}

.stats-section {
  padding-top: clamp(40px, 40px + 124 * (100vw - 768px) / 672, 164px);
  padding-bottom: clamp(45px, 45px + 75 * (100vw - 768px) / 672, 120px);
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.stats-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.stats-section__title-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stats-section__title-content:not(:last-child) {
  margin-bottom: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
}

.stats-section__title {
  width: 47.66%;
  color: var(--cl-navy);
}

.stats-section__content {
  width: 40.78%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.stats-section__slider {
  width: 100%;
  max-width: 100%;
}

.stats-section__slider .swiper-button {
  margin-top: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
}

@media (max-width: 550px) {
  .stats-section__slider .swiper {
    overflow: visible;
    max-width: 280px;
  }
}
.stats-section__item {
  border-radius: 24px;
  border: 4px solid var(--cl-aqua);
  padding: clamp(24px, 24px + 14 * (100vw - 768px) / 672, 38px) 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.stats-section__item-count {
  color: var(--cl-pink);
  font-family: var(--font-second);
  font-size: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
  line-height: 1;
  font-weight: 600;
}

.stats-section__item-count .count-up {
  color: var(--cl-navy);
  font-weight: 700;
}

.stats-section__item-count:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.stats-section__item-text {
  width: 100%;
  text-align: center;
  color: var(--cl-navy);
  letter-spacing: 0;
  font-weight: 500;
}

@media (max-width: 901px) {
  .stats-section .stats-section__title-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 30px;
  }
  .stats-section .stats-section__title {
    width: 100%;
  }
  .stats-section .stats-section__content {
    width: 100%;
  }
}
.blogs-section {
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(50px, 50px + 70 * (100vw - 768px) / 672, 120px);
  position: relative;
  z-index: 10;
}

@media (min-width: 1700px) {
  .blogs-section {
    padding-top: 160px;
  }
}
.blogs-section .line-decor:nth-child(1) {
  top: clamp(-24px, -12px + -12 * (100vw - 768px) / 672, -12px);
}

.blogs-section .line-decor:nth-child(2) {
  top: clamp(-60px, -20px + -40 * (100vw - 768px) / 672, -20px);
}

.blogs-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
}

.blogs-section__title {
  color: var(--cl-navy);
}

@media (min-width: 1201px) {
  .blogs-section__title {
    max-width: calc(100% - 400px);
  }
}
.blogs-section__title:not(:last-child) {
  margin-bottom: clamp(40px, 40px + 24 * (100vw - 768px) / 672, 64px);
}

.blogs-section__slider {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.blogs-section__slider .swiper {
  overflow: visible;
}

.blogs-section__buttons {
  grid-column-gap: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

@media (min-width: 1201px) {
  .blogs-section__buttons {
    position: absolute;
    top: clamp(-64px, -40px + -24 * (100vw - 768px) / 672, -40px);
    transform: translateY(-100%);
    right: 0;
  }
  .blogs-section__buttons .swiper-buttons {
    margin-right: clamp(-24px, -20px + -4 * (100vw - 768px) / 672, -20px);
  }
}
@media (max-width: 1200px) {
  .blogs-section__buttons {
    padding-top: 40px;
    flex-direction: column-reverse;
    align-items: center;
    grid-row-gap: 30px;
  }
  .blogs-section__buttons .btn {
    min-width: 190px;
  }
}
.clients {
  padding-top: clamp(30px, 30px + 44 * (100vw - 768px) / 672, 74px);
  padding-bottom: clamp(58px, 58px + 56 * (100vw - 768px) / 672, 114px);
  overflow: hidden;
  position: relative;
  z-index: 5;
}

.clients__title {
  color: var(--cl-white);
}

.clients__title span:not(.word) {
  color: var(--cl-aqua);
}

.clients__title {
  margin-bottom: clamp(27px, 27px + 23 * (100vw - 768px) / 672, 50px);
}

.clients__marquee:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 12 * (100vw - 768px) / 672, 20px);
}

.clients .js-marquee {
  gap: clamp(8px, 8px + 12 * (100vw - 768px) / 672, 20px);
  display: flex;
}

.clients__logo {
  display: grid;
  place-items: center;
  padding: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px) clamp(16px, 16px + 10 * (100vw - 768px) / 672, 26px);
  background: transparent;
  width: 305px;
  height: 150px;
  border: 2px solid var(--cl-white);
  border-radius: clamp(6px, 6px + 4 * (100vw - 768px) / 672, 10px);
  overflow: hidden;
}

.clients__logo img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.clients-double .clients__title {
  margin-bottom: 8px;
}

.clients-double .clients__title h3 {
  font-size: clamp(42px, 42px + 28 * (100vw - 768px) / 672, 70px);
  font-weight: 700;
}

.clients-double__wrapper {
  margin-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.clients-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
}

@media (max-width: 992px) {
  .clients-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
/* left nav block (title, subtitle) */
.clients__subtitle {
  color: #FFF;
}

@media (max-width: 992px) {
  .clients__subtitle {
    margin-bottom: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  }
}
.clients__descr {
  max-width: clamp(420px, 420px + 25 * (100vw - 768px) / 672, 445px);
  color: #FFF;
}

@media (max-width: 992px) {
  .clients__descr {
    max-width: 600px;
  }
}
@media (min-width: 1536px) {
  .clients .cont:not(.cont-text) {
    max-width: 100%;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .clients .clients__logo {
    padding: 20px;
    width: 200px;
    height: 116px;
  }
}
@media (max-width: 551px) {
  .clients .clients__logo {
    padding: 6px 11px;
    width: 130px;
    height: 64px;
  }
}
.services-container .clients {
  padding-top: 0;
  padding-bottom: clamp(36px, 36px + 44 * (100vw - 768px) / 672, 80px);
}

.employers-container .clients {
  padding-top: 0;
  padding-bottom: clamp(42px, 42px + 78 * (100vw - 768px) / 672, 120px);
}

.first-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: var(--cl-black);
}

@media (max-width: 640px) {
  .first-section:after {
    position: absolute;
    z-index: 6;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background: var(--cl-white);
    content: "";
  }
}
.first-section__overlay {
  position: absolute;
  inset: 0;
  z-index: 3;
}

.first-section__overlay:before {
  position: absolute;
  inset: 0;
  z-index: 2;
  background: linear-gradient(90deg, rgba(40, 43, 77, 0.6) 0%, rgba(12, 21, 104, 0) 100%);
  mix-blend-mode: soft-light;
  content: "";
}

.first-section__overlay:after {
  position: absolute;
  inset: 0;
  z-index: 1;
  background: rgba(3, 17, 143, 0.2);
  content: "";
}

.first-section__image {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.first-section__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  filter: contrast(150%);
}

@media (max-width: 551px) {
  .first-section__image {
    bottom: auto;
    height: 100vh;
  }
  .first-section__image img {
    transform: scale(1.15);
  }
}
.first-section__bg {
  position: absolute;
  inset: 0;
  z-index: 2;
  mix-blend-mode: multiply;
}

.first-section__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.first-section__circle {
  position: absolute;
  top: -25.56%;
  left: 33.64%;
  width: 106.6%;
  z-index: 4;
  mix-blend-mode: plus-lighter;
  max-width: 1850px;
}

@media (max-width: 640px) {
  .first-section__circle {
    width: 1350px;
    top: -180px;
    left: -23px;
  }
}
.first-section__circle {
  filter: blur(2px);
  opacity: 0.95;
}

.first-section__wrap {
  min-height: 100vh;
  height: auto;
  padding-top: clamp(200px, 200px + 60 * (100vw - 1440px) / 1125, 260px);
  padding-bottom: clamp(180px, 180px + 50 * (100vw - 768px) / 672, 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 5;
  position: relative;
}

@media (max-width: 1440px) {
  .first-section__wrap {
    padding-top: clamp(150px, 150px + 50 * (100vw - 768px) / 672, 200px);
  }
}
@media (max-width: 1024px) {
  .first-section__wrap {
    min-height: 500px;
  }
}
@media (max-width: 1024px) and (min-width: 641px) {
  .first-section__wrap {
    height: auto;
  }
}
@media (max-width: 640px) {
  .first-section__wrap {
    padding-top: 143px;
  }
}
@media (max-width: 551px) {
  .first-section__wrap {
    padding-bottom: 40px;
    height: auto;
    min-height: 87.5vh;
  }
}
.first-section__title {
  width: 100%;
  text-align: left;
}

@media (min-width: 552px) {
  .first-section__title h1 br + .word {
    margin-left: clamp(20px, 20px + 191 * (100vw - 768px) / 672, 211px);
  }
}
.first-section__title:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 28 * (100vw - 768px) / 672, 58px);
}

@media (max-width: 551px) {
  .first-section__title br {
    display: none;
  }
}
.first-section__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .first-section__content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    grid-row-gap: 40px;
    flex: 1;
    padding-bottom: 120px;
  }
}
.first-section__form {
  width: 52.03%;
  gap: clamp(10px, 12px + -2 * (100vw - 768px) / 672, 12px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 640px) {
  .first-section__form {
    width: 100%;
  }
}
.first-section__form-input {
  flex: 1;
}

.first-section + .jobs-section-container {
  position: relative;
  z-index: 12;
}

.first-section + .jobs-section-container .jobs-section {
  position: absolute;
  left: 0;
  right: 0;
  --mt: clamp(127px, calc(127px + 82.00 * ((100vw - 768px) / 672)), 209px);
  padding-top: 6px;
  margin-top: calc(var(--mt) * -1);
}

.first-section + .jobs-section-container + .sections-container.home-container {
  margin-top: clamp(-112px, -80px + -32 * (100vw - 768px) / 672, -80px);
  padding-top: clamp(280px, 280px + 54 * (100vw - 768px) / 672, 334px);
  position: relative;
  z-index: 11;
}

.first-section.first-index .first-section__wrap {
  padding-top: clamp(220px, 220px + 40 * (100vw - 1440px) / 480, 260px);
  padding-bottom: clamp(180px, 180px + 50 * (100vw - 768px) / 672, 230px);
  justify-content: center;
}

@media (min-width: 1920px) {
  .first-section.first-index .first-section__wrap {
    padding-top: 80px;
  }
}
.first-section-internal .first-section__wrap {
  padding-top: clamp(140px, 140px + 116 * (100vw - 768px) / 672, 256px);
  padding-bottom: clamp(180px, 180px + 36 * (100vw - 768px) / 672, 216px);
  min-height: auto;
  align-items: flex-start;
}

@media (max-width: 551px) {
  .first-section-internal .first-section__image {
    height: 100%;
  }
  .first-section-internal .first-section__image img {
    transform: scale(1);
  }
}
.first-section-internal__title h1 {
  color: var(--cl-aqua);
}

.first-section-internal.first-section:after {
  display: none;
}

.first-section-internal-employers .first-section__wrap {
  padding-bottom: clamp(100px, 100px + 80 * (100vw - 768px) / 672, 180px);
}

.first-section-internal-employers .first-section-internal__title .heading-2 {
  font-size: clamp(44px, 44px + 86 * (100vw - 768px) / 672, 130px);
  line-height: 1.1;
  font-weight: 700;
}

@media (max-width: 640px) {
  .first-section-internal-employers .first-section-internal__title .heading-2 {
    line-height: 1.08;
  }
}
.first-section-internal-employers .first-section__circle {
  max-width: 1280px;
  left: 45.64%;
}

.first-section-internal-employers .first-section__image img {
  filter: grayscale(1);
}

.first-section-internal-employers .first-section__overlay::before {
  display: none;
}

.first-section-internal-employers .first-section__overlay::after {
  background: rgba(97, 3, 143, 0.1294117647);
}

.first-section-internal-sectors .first-section__circle {
  max-width: 1280px;
  left: 45.64%;
}

.first-section-internal-sectors .first-section__image img {
  filter: grayscale(1);
}

.first-section-internal-sectors .first-section__overlay::before {
  display: none;
}

.first-section-internal-sectors .first-section__overlay::after {
  background: rgba(97, 3, 143, 0.1294117647);
}

.first-section-internal-about .first-section__wrap {
  padding-bottom: clamp(140px, 140px + 76 * (100vw - 768px) / 672, 216px);
}

.first-section-internal-about .first-section__circle {
  max-width: 1280px;
  left: 45.64%;
}

.first-section-internal-about .first-section__overlay::before {
  background: transparent;
}

.first-section-internal-about .first-section__overlay::after {
  background: rgba(97, 3, 143, 0.1294117647);
}

.first-section-internal-bulletins .heading-3 {
  font-size: clamp(26px, 26px + 26 * (100vw - 768px) / 672, 52px);
  font-weight: 700;
  line-height: 1.25;
}

.first-section-internal-bulletins .first-section__circle {
  max-width: 1280px;
  left: 45.64%;
}

.first-section-internal-bulletins .first-section__image img {
  filter: grayscale(1);
}

.first-section-internal-bulletins .first-section__overlay::before {
  display: none;
}

.first-section-internal-bulletins .first-section__overlay::after {
  background: rgba(59, 3, 143, 0.1294117647);
}

.first-section-internal-services .first-section__wrap {
  padding-bottom: clamp(180px, 180px + 45 * (100vw - 768px) / 672, 225px);
}

.first-section-internal-services .first-section__image img {
  filter: grayscale(1);
}

@media (max-width: 551px) {
  .first-section-internal-services .first-section__image {
    height: 100%;
  }
  .first-section-internal-services .first-section__image img {
    transform: scale(1);
  }
}
.first-section-internal-services .first-section__overlay {
  opacity: 0;
}

.first-section-internal-services .first-section__circle {
  max-width: 1280px;
  left: 45.64%;
}

.first-section-individual .first-section-internal__title {
  max-width: clamp(510px, 510px + 395 * (100vw - 768px) / 672, 905px);
}

@media (max-width: 992px) {
  .first-section-individual .first-section-internal__title {
    margin-bottom: 24px;
  }
}
.first-section-individual .first-section-internal__title h1 {
  color: #fff;
}

.first-section-individual .first-section__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  padding-bottom: clamp(119px, 119px + 65 * (100vw - 768px) / 672, 184px);
}

@media (max-width: 992px) {
  .first-section-individual .first-section__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 551px) {
  .first-section-individual .first-section__image {
    height: 100%;
  }
}
.first-section-individual .first-section__image img {
  filter: grayscale(1);
}

.first-section-individual .first-section__bg {
  mix-blend-mode: hard-light;
}

.first-section-individual .first-section__overlay::before {
  background: transparent;
}

.first-section-individual .first-section__overlay::after {
  background: rgba(0, 0, 0, 0.2);
}

.first-section.jobs-search-page {
  margin-top: clamp(92px, 92px + 44 * (100vw - 768px) / 672, 136px);
  background: var(--cl-white);
}

.first-section.jobs-search-page .first-section__title .heading-3 {
  line-height: 1;
  color: var(--cl-white);
  letter-spacing: 0;
}

@media (max-width: 551px) {
  .first-section.jobs-search-page .first-section__title .heading-3 span.heading-3 {
    margin-right: 9px;
  }
}
.first-section.jobs-search-page .first-section__title .heading-2 {
  color: var(--cl-aqua);
}

@media (max-width: 640px) {
  .first-section.jobs-search-page .first-section__title .heading-2 {
    display: block;
  }
}
.first-section.jobs-search-page .first-section__wrap {
  min-height: clamp(300px, 300px + 183 * (100vw - 768px) / 672, 483px);
  height: auto;
  padding-top: 120px;
  padding-bottom: clamp(160px, 160px + 18 * (100vw - 768px) / 672, 178px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*@media (max-width: 1024px) {
    min-height: 500px;
    @media (min-width: 641px) {
      height: auto;
    }
  }

  @media (max-width: 640px) {
    padding-top: 143px;
  }

  @media (max-width: 551px) {
    padding-bottom: 40px;
    height: auto;
    min-height: 87.5vh;
  }*/
}

@media (min-width: 1200px) {
  .first-section.jobs-search-page .first-section__circle {
    top: -64.56%;
    left: 41.64%;
  }
}
.first-section.jobs-search-page .first-section__overlay:before {
  background: linear-gradient(90deg, rgba(140, 140, 147, 0.6) 0%, rgba(22, 38, 187, 0) 18%);
}

@media (max-width: 640px) {
  .first-section.jobs-search-page .first-section__overlay:before {
    background: linear-gradient(90deg, rgba(140, 140, 147, 0.6) 0%, rgba(22, 38, 187, 0) 80%);
  }
}
.first-section.jobs-search-page .first-section__overlay::after {
  background: rgba(25, 40, 170, 0.4588235294);
}

.together-section {
  padding-top: clamp(32px, 32px + 78 * (100vw - 768px) / 672, 110px);
  padding-bottom: clamp(28px, 28px + 57 * (100vw - 768px) / 672, 85px);
  overflow: hidden;
}

.together-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.together-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 49.22%;
  color: var(--cl-charcoal);
}

@media (max-width: 640px) {
  .together-section__content {
    width: 100%;
  }
}
.together-section__buttons {
  position: relative;
  z-index: 20;
}

.together-section__title span:not(.word) {
  color: var(--cl-navy);
}

.together-section__title:not(:last-child) {
  margin-bottom: clamp(22px, 22px + 36 * (100vw - 768px) / 672, 58px);
}

.together-section__line:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 30 * (100vw - 768px) / 672, 50px);
}

.together-section__text:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 39 * (100vw - 768px) / 672, 63px);
}

@media (max-width: 551px) {
  .together-section__text {
    font-size: 20px;
    line-height: 148%;
  }
}
@media (min-width: 701px) {
  .together-section__list {
    margin-top: clamp(-168px, -90px + -78 * (100vw - 768px) / 672, -90px);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1px;
    width: 72.81%;
    position: relative;
    margin-left: auto;
  }
  .together-section__list:before {
    position: relative;
    display: block;
    content: "";
  }
  .together-section__list .together-section__item:nth-child(1) {
    border-top-left-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(1):before, .together-section__list .together-section__item:nth-child(1):after {
    border-top-left-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(2) {
    border-top-right-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(2):before, .together-section__list .together-section__item:nth-child(2):after {
    border-top-right-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(3) {
    border-top-left-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(3):not(:hover) {
    border-bottom-left-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(3):before, .together-section__list .together-section__item:nth-child(3):after {
    border-top-left-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(4):last-child {
    border-bottom-right-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(4):last-child:before, .together-section__list .together-section__item:nth-child(4):last-child:after {
    border-bottom-right-radius: 10px;
  }
  .together-section__list .together-section__item:nth-child(5) {
    border-bottom-right-radius: 10px;
  }
}
@media (min-width: 701px) and (max-width: 1050px) {
  .together-section__list {
    width: 100%;
    padding-top: 40px;
  }
}
@media (max-width: 700px) {
  .together-section__list {
    margin-top: 32px;
  }
  .together-section__list .together-section__item {
    background: var(--cl-charcoal);
    min-height: auto;
  }
  .together-section__list .together-section__item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .together-section__list .together-section__item:first-child:before, .together-section__list .together-section__item:first-child:after {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .together-section__list .together-section__item:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .together-section__list .together-section__item:last-child:before, .together-section__list .together-section__item:last-child:after {
    border-bottom-right-radius: 10px;
  }
  .together-section__list .together-section__item:last-child:not(.active):before, .together-section__list .together-section__item:last-child:not(.active):after {
    border-bottom-left-radius: 10px;
  }
  .together-section__list .together-section__item-title:not(:last-child) {
    margin-bottom: 0;
  }
  .together-section__list .together-section__item-hidden {
    max-height: 0;
    opacity: 0;
    transition: 0.3s;
    margin-top: 0;
  }
  .together-section__list .together-section__item.active .together-section__item-hidden {
    max-height: 500px;
    opacity: 1;
    margin-top: 16px;
  }
}
.together-section__item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  color: var(--cl-white);
  background: rgba(53, 53, 63, 0.96);
  padding: clamp(24px, 24px + 7 * (100vw - 768px) / 672, 31px) clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px) clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  position: relative;
  z-index: 5;
  transition: background-color 0.2s ease, border-radius 0.2s ease;
  /*filter: drop-shadow(1px 1px 0px var(--cl-dark-grey))
    drop-shadow(-1px -1px 0px var(--cl-dark-grey));*/
  box-shadow: 1px 1px 0px var(--cl-dark-grey), -1px -1px 0px var(--cl-dark-grey);
  min-height: clamp(300px, 300px + 50 * (100vw - 768px) / 672, 350px);
  border-bottom-left-radius: 0;
}

.together-section__item:before {
  position: absolute;
  inset: 0;
  content: "";
  background: var(--cl-navy);
  z-index: 2;
  clip-path: polygon(100% 0, 100% 100%, 98px 100%, 0 calc(100% - 85px), 0 0);
  transition: 0.2s;
  opacity: 0;
}

@media (max-width: 551px) {
  .together-section__item:before {
    clip-path: polygon(100% 0, 100% 100%, 68px 100%, 0 calc(100% - 62px), 0 0);
  }
}
.together-section__item:after {
  position: absolute;
  inset: -1px;
  content: "";
  background: var(--cl-aqua);
  z-index: 1;
  clip-path: polygon(100% 0, 100% 100%, 98px 100%, 0 calc(100% - 85px), 0 0);
  transition: 0.2s;
  opacity: 0;
}

@media (max-width: 551px) {
  .together-section__item:after {
    clip-path: polygon(100% 0, 100% 100%, 68px 100%, 0 calc(100% - 62px), 0 0);
  }
}
@media (min-width: 701px) {
  .together-section__item:hover {
    z-index: 10;
    box-shadow: 1px 1px 0px transparent, -1px -1px 0px transparent;
    border-bottom-left-radius: 50%;
  }
  .together-section__item:hover:before, .together-section__item:hover:after {
    opacity: 1;
  }
  .together-section__item:hover .together-section__item-hidden {
    opacity: 1;
    pointer-events: auto;
  }
}
@media (max-width: 700px) {
  .together-section__item.active {
    z-index: 10;
    box-shadow: 1px 1px 0px transparent, -1px -1px 0px transparent;
  }
  .together-section__item.active:before, .together-section__item.active:after {
    opacity: 1;
  }
  .together-section__item.active .together-section__item-hidden {
    pointer-events: auto;
  }
}
.together-section__item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 5;
  flex: 1;
}

@media (max-width: 640px) {
  .together-section__item-title br {
    display: none;
  }
}
.together-section__item-title:not(:last-child) {
  margin-bottom: clamp(20px, 27px + -7 * (100vw - 768px) / 672, 27px);
}

.together-section__item-hidden {
  width: calc(100% + 10px);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  opacity: 0;
  pointer-events: none;
}

.together-section__item-text {
  width: 100%;
  --line-count: 5;
}

.together-section__item-text:not(:last-child) {
  margin-bottom: clamp(10px, 16px + -6 * (100vw - 768px) / 672, 16px);
}

@media (max-width: 551px) {
  .together-section__item-text {
    max-width: 276px;
  }
}
.together-section__item-link {
  margin-top: auto;
  margin-left: auto;
}

@media (max-width: 551px) {
  .together-section__item-link {
    --size: 40px;
    margin-right: 10px;
  }
}
.jobs-section {
  padding-top: clamp(40px, 40px + 43 * (100vw - 768px) / 672, 83px);
  padding-bottom: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  /*not design*/
  position: relative;
  z-index: 10;
}

.jobs-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
}

.jobs-section__content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-row-gap: 20px;
  grid-column-gap: clamp(20px, 20px + 10 * (100vw - 768px) / 672, 30px);
  width: 100%;
}

.jobs-section__content:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
}

@media (max-width: 768px) {
  .jobs-section__content {
    flex-direction: column;
    align-items: flex-start;
  }
}
.jobs-section__title {
  color: var(--cl-navy);
}

.jobs-section__slider {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
}

.jobs-section__slider .swiper-buttons {
  width: 100%;
  justify-content: flex-end;
}

.jobs-section__slider .swiper-buttons .swiper-button {
  margin-top: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
}

.jobs-section__slider .swiper {
  overflow: visible;
  opacity: 0;
}

.jobs-section__slider .swiper.swiper-initialized {
  opacity: 1;
}

@media (max-width: 551px) {
  .jobs-section__slider .swiper {
    max-width: 300px;
    margin-left: 0;
  }
}
.jobs-section__individual .jobs-section {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: clamp(-121px, -45px + -76 * (100vw - 768px) / 672, -45px);
}

.jobs-section.job-details-page {
  padding-bottom: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
}

.jobs-section.job-details-page .job-card__title {
  font-size: clamp(22px, 22px + 8 * (100vw - 768px) / 672, 30px);
  font-weight: 500;
  line-height: 1.28;
  letter-spacing: 0;
}

.jobs-section.job-details-page .job-card__title:not(:last-child) {
  margin-bottom: 16px;
}

.jobs-section.job-details-page .swiper-buttons .swiper-button {
  --size: clamp(48px, calc(48px + 8.00 * ((100vw - 768px) / 672)), 56px);
}

.jobs-section.job-details-page + .contact-us-section {
  padding-top: 0;
}

.trusted-section {
  padding-top: clamp(28px, 28px + 57 * (100vw - 768px) / 672, 85px);
  padding-bottom: clamp(28px, 28px + 44 * (100vw - 768px) / 672, 72px);
  overflow: hidden;
}

.trusted-section__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-row-gap: clamp(32px, 32px + 40 * (100vw - 768px) / 672, 72px);
}

.trusted-section__title {
  width: 61.72%;
  color: var(--cl-white);
}

.trusted-section__title h2.heading-2 {
  line-height: 1.08;
}

.trusted-section__title span:not(.word) {
  color: var(--cl-aqua);
}

@media (max-width: 851px) {
  .trusted-section__title {
    width: 100%;
    order: 1;
  }
}
@media (max-width: 451px) {
  .trusted-section__title {
    max-width: 328px;
  }
}
.trusted-section__content {
  width: 27.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--cl-white);
  margin-top: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

@media (max-width: 851px) {
  .trusted-section__content {
    margin-top: 0;
    width: 100%;
    order: 2;
  }
}
.trusted-section__text:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
}

@media (max-width: 851px) {
  .trusted-section__text {
    font-size: 20px;
    line-height: 148%;
  }
}
.trusted-section__slider {
  width: 100%;
}

.trusted-section__slider .swiper {
  overflow: visible;
}

.trusted-section__slider .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  grid-row-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

@media (max-width: 951px) {
  .trusted-section__slider .swiper:not(.swiper-initialized) {
    display: none;
  }
}
@media (max-width: 500px) {
  .trusted-section__slider .swiper.swiper-initialized {
    max-width: 300px;
    margin-left: 0;
    margin-top: -7px;
  }
}
.trusted-section__slider .swiper-buttons {
  display: none;
}

.trusted-section__slider .swiper-buttons div.swiper-button:hover {
  border-color: var(--cl-aqua);
}

.trusted-section__slider .swiper-buttons div.swiper-button:hover::before {
  color: var(--cl-aqua);
}

@media (max-width: 851px) {
  .trusted-section__slider {
    order: 3;
  }
}
.trusted-section__item {
  color: var(--cl-white);
  padding-top: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  padding-left: clamp(20px, 23px + -3 * (100vw - 768px) / 672, 23px);
  padding-right: clamp(23px, 23px + 40 * (100vw - 768px) / 672, 63px);
  padding-bottom: clamp(20px, 23px + -3 * (100vw - 768px) / 672, 23px);
  border-radius: clamp(10px, 12px + -2 * (100vw - 768px) / 672, 12px);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
}

@media (min-width: 952px) {
  .trusted-section__item {
    min-height: clamp(200px, 200px + 60 * (100vw - 768px) / 672, 260px);
  }
}
.trusted-section__item:before {
  position: absolute;
  inset: 0;
  z-index: -1;
  background: rgba(53, 53, 63, 0.96);
  border-radius: clamp(10px, 12px + -2 * (100vw - 768px) / 672, 12px);
  content: "";
  transition: 0.3s;
}

.trusted-section__item:after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-radius: clamp(10px, 12px + -2 * (100vw - 768px) / 672, 12px);
  content: "";
  opacity: 0;
  transition: all 0.3s ease;
  background: var(--cl-aqua);
  z-index: -2;
  height: 100%;
}

@media (min-width: 641px) {
  .trusted-section__item:hover:after {
    height: calc(100% + 6px);
    opacity: 1;
  }
  .trusted-section__item:hover:before {
    background-color: var(--cl-white);
  }
  .trusted-section__item:hover .trusted-section__item-link {
    background-color: var(--cl-aqua);
  }
  .trusted-section__item:hover .trusted-section__item-link i {
    /* transform: rotate(-45deg); */
  }
  .trusted-section__item:hover .trusted-section__item-title, .trusted-section__item:hover .trusted-section__item-text {
    color: var(--cl-charcoal);
  }
}
@media (max-width: 640px) {
  .trusted-section__item:after {
    height: calc(100% + 6px);
    opacity: 1;
  }
  .trusted-section__item:before {
    background-color: var(--cl-white);
    border: 1px solid var(--cl-white);
    transition: 0.3s;
  }
  .trusted-section__item .trusted-section__item-link {
    background-color: var(--cl-aqua);
  }
  .trusted-section__item .trusted-section__item-title, .trusted-section__item .trusted-section__item-text {
    color: var(--cl-charcoal);
  }
  .trusted-section__item.swiper-slide-active:after {
    height: 100%;
    opacity: 0;
  }
  .trusted-section__item.swiper-slide-active:before {
    background-color: var(--cl-charcoal);
    border-color: var(--cl-aqua);
  }
  .trusted-section__item.swiper-slide-active .trusted-section__item-link {
    background-color: var(--cl-white);
  }
  .trusted-section__item.swiper-slide-active .trusted-section__item-link i {
    transform: rotate(90deg);
  }
  .trusted-section__item.swiper-slide-active .trusted-section__item-title, .trusted-section__item.swiper-slide-active .trusted-section__item-text {
    color: var(--cl-white);
  }
}
.trusted-section__item-title {
  max-width: calc(100% - 50px);
  transition: 0.3s;
}

.trusted-section__item-title:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
}

.trusted-section__item-text {
  transition: 0.3s;
  color: var(--white);
}

@media (min-width: 641px) {
  .trusted-section__item-text {
    --max-line-num: 5 ;
  }
}
.trusted-section__item-link {
  --size: clamp(40px, calc(40px + 8.00 * ((100vw - 768px) / 672)), 48px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  right: 24px;
  transition: 0.3s;
}

.trusted-section__item-link i {
  transform: rotate(45deg);
}

.trusted-section__item-link:hover {
  border-color: var(--cl-navy) !important;
}

.trusted-section__item-link:hover i {
  transform: rotate(0deg) !important;
}

.trusted-section-second {
  padding-top: clamp(42px, 42px + 78 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(80px, 80px + 50 * (100vw - 768px) / 672, 130px);
}

.trusted-section-second .trusted-section__slider .swiper-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.trusted-section-second .trusted-section__slider .swiper-buttons .swiper-button {
  background-color: transparent;
  color: #fff;
  border: 1px solid #ffffff;
}

@media (max-width: 992px) {
  .trusted-section-second .trusted-section__slider .swiper-buttons {
    justify-content: center;
  }
}
.trusted-section-second .trusted-section__item {
  padding-top: clamp(26px, 26px + 16 * (100vw - 768px) / 672, 42px);
  padding-left: clamp(23px, 23px + 7 * (100vw - 768px) / 672, 30px);
  padding-right: clamp(23px, 23px + 31 * (100vw - 768px) / 672, 54px);
  padding-bottom: clamp(20px, 23px + -3 * (100vw - 768px) / 672, 23px);
  min-height: clamp(200px, 200px + 110 * (100vw - 768px) / 672, 310px);
  max-width: 413px;
  min-height: clamp(230px, 230px + 80 * (100vw - 768px) / 672, 310px);
  justify-content: flex-start;
}

.trusted-section-second .trusted-section__item-title {
  padding-bottom: 24px;
  margin-bottom: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  position: relative;
  min-height: clamp(87px, 114px + -27 * (100vw - 1200px) / 240, 114px);
}

.trusted-section-second .trusted-section__item-title::after {
  content: "";
  width: 62px;
  height: 4px;
  background-color: var(--cl-aqua);
  position: absolute;
  left: 0;
  bottom: 0;
}

@media (max-width: 992px) {
  .trusted-section-second .trusted-section__item-title {
    /* min-height: ac(85px, 108px, 768, 992); */
    min-height: 108px;
  }
}
@media (max-width: 768px) {
  .trusted-section-second .trusted-section__item-title {
    padding-bottom: 0;
    margin-bottom: clamp(27px, 27px + 13 * (100vw - 768px) / 672, 40px);
    min-height: 55px;
  }
  .trusted-section-second .trusted-section__item-title::after {
    display: none;
  }
}
@media (min-width: 641px) {
  .trusted-section-second .trusted-section__item-text {
    --max-line-num: 3 ;
  }
}
.trusted-section-second .trusted-section__item-link {
  --size: clamp(40px, calc(40px + 16.00 * ((100vw - 768px) / 672)), 56px);
  width: var(--size);
  height: var(--size);
  top: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  right: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  background-color: transparent;
}

.trusted-section-second .trusted-section__item-link i {
  color: #fff;
}

@media (max-width: 640px) {
  .trusted-section-second .trusted-section__item-link {
    background-color: var(--cl-aqua);
  }
  .trusted-section-second .trusted-section__item-link i {
    color: var(--cl-charcoal);
  }
}
.trusted-section-second .trusted-section__item:hover i {
  color: var(--cl-charcoal);
}

@media (max-width: 640px) {
  .trusted-section-second .trusted-section__item:after {
    height: 100%;
    opacity: 0;
  }
  .trusted-section-second .trusted-section__item:before {
    background-color: var(--cl-charcoal);
    border-color: var(--cl-aqua);
  }
  .trusted-section-second .trusted-section__item .trusted-section__item-link {
    background-color: var(--cl-white);
  }
  .trusted-section-second .trusted-section__item .trusted-section__item-link i {
    transform: rotate(45deg);
  }
  .trusted-section-second .trusted-section__item .trusted-section__item-title, .trusted-section-second .trusted-section__item .trusted-section__item-text {
    color: var(--cl-white);
  }
  .trusted-section-second .trusted-section__item.swiper-slide-active:after {
    height: calc(100% + 6px);
    opacity: 1;
  }
  .trusted-section-second .trusted-section__item.swiper-slide-active:before {
    background-color: var(--cl-white);
    border: 1px solid var(--cl-white);
    transition: 0.3s;
  }
  .trusted-section-second .trusted-section__item.swiper-slide-active .trusted-section__item-link {
    background-color: var(--cl-aqua);
  }
  .trusted-section-second .trusted-section__item.swiper-slide-active .trusted-section__item-title, .trusted-section-second .trusted-section__item.swiper-slide-active .trusted-section__item-text {
    color: var(--cl-charcoal);
  }
}
.trusted-second-about {
  position: relative;
}

.trusted-second-about__image {
  position: absolute;
  inset: 0;
}

.trusted-second-about__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.trusted-second-about .trusted-section__item {
  justify-content: flex-start;
}

/* font-size: ac(18px, 16px);
    font-weight: 400;
    margin-top: 13px;
    line-height: 1.5; */
@media (max-width: 900px) {
  .employers-container .trusted-section__text {
    margin-top: 0;
  }
}
.employers-container .trusted-section-second .trusted-section__slider .swiper-buttons .swiper-button {
  border-width: 2px;
}

.employers-container .trusted-section-second .trusted-section__slider .swiper-buttons .swiper-button:before {
  font-size: clamp(20px, 20px + 6 * (100vw - 768px) / 672, 26px);
}

.quote-about {
  background: #F2F1F1;
}

.quote-about__wrap {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  border-top: 8px solid #68E6FC;
  border-radius: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  padding: clamp(32px, 32px + 70 * (100vw - 768px) / 672, 102px);
  margin-top: clamp(-136px, -106px + -30 * (100vw - 768px) / 672, -106px);
  position: relative;
  z-index: 10;
}

@media (max-width: 768px) {
  .quote-about__wrap {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .quote-about__wrap {
    padding: 32px 24px;
  }
}
.quote-about__title {
  color: var(--cl-navy);
  max-width: 60%;
  margin-right: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
}

@media (max-width: 950px) {
  .quote-about__title {
    max-width: 45%;
  }
}
@media (max-width: 768px) {
  .quote-about__title {
    max-width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }
}
.quote-about__descr {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 28px;
  color: var(--cl-charcoal);
  max-width: 39.5%;
  width: 100%;
}

@media (max-width: 950px) {
  .quote-about__descr {
    max-width: 50%;
  }
}
@media (max-width: 768px) {
  .quote-about__descr {
    max-width: 100%;
  }
}
.quote-about-sectors {
  background-color: transparent;
}

.quote-about-sectors .quote-about__wrap {
  border: 1px solid var(--cl-aqua);
  border-top: 8px solid var(--cl-aqua);
}

.quote-about__circle {
  display: none;
}

.quote-about-sectors .quote-about__circle {
  display: flex;
  position: absolute;
  z-index: 0;
  /* bottom: ac(-240px, -90px, 820);
  left: ac(560px, 280px, 820);
  width: ac(1280px, 750px, 820); */
  bottom: clamp(-390px, -90px + -300 * (100vw - 820px) / 1100, -90px);
  right: clamp(-500px, -290px + -210 * (100vw - 820px) / 620, -290px);
  width: clamp(750px, 750px + 730 * (100vw - 820px) / 1100, 1480px);
}

@media (min-width: 2000) {
  .quote-about-sectors .quote-about__circle {
    display: none;
  }
}
@media (max-width: 820px) {
  .quote-about-sectors .quote-about__circle {
    display: none;
  }
}
.quote-about-sectors .quote-about__circle img {
  width: 100%;
}

.our-services-section {
  padding-top: clamp(50px, 50px + 70 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(60px, 60px + 20 * (100vw - 768px) / 672, 80px);
  background: #F2F1F1;
}

.our-services-section__title {
  padding-bottom: clamp(18px, 18px + 38 * (100vw - 768px) / 672, 56px);
}

.our-services-section__title span:not(.word) {
  color: var(--cl-navy);
}

.our-services-section__line:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 30 * (100vw - 768px) / 672, 50px);
}

/* padding-top: ac(48px, 12px); */
@media (max-width: 768px) {
  .our-services-section__wrap {
    padding-top: 0;
  }
}
.our-services-section__accordion {
  background-color: #FFF;
  border-radius: 20px;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  max-width: 1232px;
  transition: 0.3s all;
}

@media (max-width: 992px) {
  .our-services-section__accordion {
    max-width: none;
  }
}
@media (max-width: 576px) {
  .our-services-section__accordion {
    /* padding: 16px 12px; */
  }
}
.our-services-section__accordion:last-child {
  margin-bottom: 0;
}

.our-services-section__accordion-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
}

.our-services-section__accordion-toggle {
  margin-right: clamp(8px, 8px + 12 * (100vw - 768px) / 672, 20px);
  min-width: clamp(34px, 34px + 14 * (100vw - 768px) / 672, 48px);
  height: clamp(34px, 34px + 14 * (100vw - 768px) / 672, 48px);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #68E6FC;
  background-color: var(--cl-navy);
  transition: 0.3s all;
  position: relative;
}

.our-services-section__accordion-toggle i {
  color: #FFF;
  transition: 0.3s all;
}

.our-services-section__accordion-toggle i.icon-minus {
  opacity: 0;
  position: absolute;
  color: var(--cl-navy);
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.our-services-section__accordion-title {
  color: var(--cl-navy);
  transition: 0.3s all;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .our-services-section__accordion-title {
    max-width: 385px;
  }
}
@media (max-width: 992px) {
  .our-services-section__accordion-title {
    max-width: none;
  }
}
.our-services-section__accordion-body {
  max-height: 0;
  overflow: hidden;
  max-width: clamp(545px, 545px + 100 * (100vw - 1200px) / 240, 645px);
  box-sizing: content-box;
  width: 100%;
  opacity: 0;
  transition: 0.4s all;
}

@media (max-width: 1200px) {
  .our-services-section__accordion-body {
    max-width: clamp(505px, 505px + 40 * (100vw - 992px) / 208, 545px);
  }
}
@media (max-width: 992px) {
  .our-services-section__accordion-body {
    max-width: none;
    width: auto;
    box-sizing: border-box;
  }
}
.our-services-section__accordion-descr {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 20px;
  color: var(--cl-charcoal);
}

.our-services-section__accordion-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: rgba(53, 53, 63, 0.8);
  margin-bottom: 24px;
  max-height: 430px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

.our-services-section__accordion-content::-webkit-scrollbar {
  width: 3px;
}

.our-services-section__accordion-content p:not(:last-child) {
  margin-bottom: 10px;
}

.our-services-section__accordion-btn {
  max-width: 155px;
}

@media (max-width: 992px) {
  .our-services-section__accordion-btn {
    margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  }
}
.our-services-section__accordion-image {
  position: absolute;
  top: 0;
  right: clamp(-46px, -46px + 46 * (100vw - 1200px) / 240, 0px);
  opacity: 0;
  transition: 0.3s all;
  z-index: -1;
}

/* height: 100%; */
.our-services-section__accordion-image img {
  max-width: clamp(497px, 680px + -183 * (100vw - 768px) / 672, 680px);
  height: 100%;
  width: 100%;
}

@media (max-width: 1200px) {
  .our-services-section__accordion-image {
    right: clamp(-240px, -240px + 194 * (100vw - 768px) / 672, -46px);
  }
}
@media (max-width: 992px) {
  .our-services-section__accordion-image {
    position: relative;
    inset: auto;
  }
  .our-services-section__accordion-image img {
    border-radius: 18px;
    max-width: 640px;
  }
}
.our-services-section__accordion--active {
  padding-top: clamp(0px, 0px + 15 * (100vw - 768px) / 672, 15px);
  padding-left: clamp(0px, 0px + 17 * (100vw - 768px) / 672, 17px);
}

.our-services-section__accordion--active .our-services-section__accordion-title {
  font-size: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

@media (max-width: 576) {
  .our-services-section__accordion--active .our-services-section__accordion-title {
    font-size: 20px;
  }
}
.our-services-section__accordion--active .our-services-section__accordion-toggle {
  border: 2px solid rgba(1, 15, 147, 0.5019607843);
  background: #F2F1F1;
  min-width: clamp(34px, 34px + 21 * (100vw - 768px) / 672, 55px);
  height: clamp(34px, 34px + 21 * (100vw - 768px) / 672, 55px);
}

.our-services-section__accordion--active .our-services-section__accordion-toggle .icon-minus {
  position: relative;
  opacity: 1;
}

.our-services-section__accordion--active .our-services-section__accordion-toggle .icon-plus-second {
  opacity: 0;
  position: absolute;
}

.our-services-section__accordion--active .our-services-section__accordion-body {
  padding-left: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
  padding-bottom: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
  opacity: 1;
}

@media (max-width: 992px) {
  .our-services-section__accordion--active .our-services-section__accordion-body {
    padding-right: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
  }
}
.our-services-section__accordion--active .our-services-section__accordion-image {
  opacity: 1;
  z-index: 1;
}

.our-history-section {
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
  overflow: hidden;
  z-index: 30;
}

.our-history-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (min-width: 1023px) {
  .our-history-section__title {
    max-width: calc(100% - 150px);
  }
}
.our-history-section__title .heading-3 {
  letter-spacing: 0;
}

.our-history-section__title span:not(.word) {
  color: var(--cl-navy);
}

.our-history-section__title:not(:last-child) {
  margin-bottom: clamp(32px, 32px + 18 * (100vw - 768px) / 672, 50px);
}

.our-history-section__slider {
  width: 100%;
  position: relative;
  clip-path: inset(-150px -200px -1px -1px);
}

.our-history-section__slider .swiper {
  margin-left: 0;
  width: 95%;
  max-width: clamp(590px, 590px + 148 * (100vw - 768px) / 672, 738px);
  overflow: visible;
  position: relative;
  z-index: 1;
  transition: opacity 0.1s;
  transition-delay: 0.3s;
}

.our-history-section__slider .swiper.swiper-initialized {
  opacity: 1;
}

.our-history-section__slider .swiper:before {
  top: clamp(140px, 140px + 66 * (100vw - 768px) / 672, 206px);
  left: 0;
  width: 100vw;
  height: 1px;
  background: var(--cl-charcoal);
  content: "";
  z-index: -1;
  position: absolute;
}

@media (min-width: 1023px) {
  .our-history-section__slider .swiper-buttons {
    position: absolute;
    top: clamp(-56px, -32px + -24 * (100vw - 768px) / 672, -32px);
    right: 0;
    transform: translateY(-100%);
  }
  .our-history-section__slider .swiper-buttons .swiper-button {
    margin-top: 0;
    --size: clamp(48px, calc(48px + 8.00 * ((100vw - 768px) / 672)), 56px);
  }
  .our-history-section__slider .swiper-buttons .swiper-button::before {
    font-size: 24px;
  }
}
.our-history-section__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.our-history-section__item.swiper-slide-active .our-history-section__item-container, .our-history-section__item.swiper-slide-prev .our-history-section__item-container {
  opacity: 1;
}

.our-history-section__item.swiper-slide-active .our-history-section__item-text, .our-history-section__item.swiper-slide-prev .our-history-section__item-text {
  opacity: 1;
}

.our-history-section__item:not(.swiper-slide-active) .our-history-section__item-start {
  opacity: 1;
}

.our-history-section__item.swiper-slide-next .our-history-section__item-start {
  transform: translateX(15%);
}

.our-history-section__item.swiper-slide-next + .swiper-slide .our-history-section__item-start {
  transform: translateX(-72%) scale(0.9) translateY(-10%);
}

.our-history-section__item.swiper-slide-next .our-history-section__item-start-title {
  font-weight: 700;
}

.our-history-section__item-circle {
  position: absolute;
  bottom: clamp(-280px, -280px + 20 * (100vw - 768px) / 672, -260px);
  right: clamp(-290px, -190px + -100 * (100vw - 768px) / 672, -190px);
  filter: saturate(0.1);
  max-width: clamp(610px, 610px + 168 * (100vw - 768px) / 672, 778px);
}

.our-history-section__item-title {
  color: var(--cl-white);
  letter-spacing: 0;
  line-height: 1.1;
}

.our-history-section__item-title:not(:last-child) {
  margin-bottom: clamp(26px, 26px + 3 * (100vw - 768px) / 672, 29px);
}

.our-history-section__item-start {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: 0.3s;
  opacity: 0;
  width: clamp(170px, 170px + 120 * (100vw - 768px) / 672, 290px);
  height: clamp(170px, 170px + 120 * (100vw - 768px) / 672, 290px);
  top: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
}

.our-history-section__item-start-image {
  z-index: -1;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  inset: 0;
}

.our-history-section__item-start-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.our-history-section__item-start-image:after {
  z-index: 1;
  position: absolute;
  inset: 0;
  content: "";
  background: var(--cl-navy);
  opacity: 0.4;
}

.our-history-section__item-start-title {
  color: var(--cl-white);
  text-align: center;
  font-size: clamp(28px, 28px + 22 * (100vw - 768px) / 672, 50px);
  font-weight: 500;
}

.our-history-section__item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: clamp(26px, 26px + 5 * (100vw - 768px) / 672, 31px);
  padding-bottom: clamp(26px, 26px + 5 * (100vw - 768px) / 672, 31px);
  padding-left: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  padding-right: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  min-height: clamp(280px, 280px + 132 * (100vw - 768px) / 672, 412px);
  position: relative;
  border-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  border: 1px solid var(--cl-aqua);
  overflow: hidden;
  z-index: 1;
  width: 100%;
  transition: 0.3s;
  opacity: 0;
}

.our-history-section__item-container:not(:last-child) {
  margin-bottom: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
}

.our-history-section__item-image {
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.our-history-section__item-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.our-history-section__item-image:after {
  z-index: 1;
  position: absolute;
  inset: 0;
  content: "";
  background: var(--cl-navy);
  opacity: 0.4;
}

.our-history-section__item-text {
  transition: 0.3s;
  opacity: 0;
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 500;
  line-height: 1.32;
  max-height: 200px;
  color: #3C3C3B;
  overflow: auto;
  scrollbar-width: thin;
  padding-right: 2px;
}

.our-history-section__item-text::-webkit-scrollbar {
  width: 3px;
}

.contact-us-section {
  padding-top: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
}

.contact-us-section__wrap {
  display: flex;
}

@media (max-width: 840px) {
  .contact-us-section__wrap {
    flex-direction: column-reverse;
  }
}
.contact-us-section__text {
  padding: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
  border-radius: 24px;
  background-color: var(--cl-aqua);
  max-width: 716px;
}

@media (max-width: 840px) {
  .contact-us-section__text {
    max-width: none;
  }
}
@media (max-width: 360px) {
  .contact-us-section__text {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.contact-us-section__title {
  color: var(--cl-navy);
  margin-bottom: clamp(18px, 18px + 22 * (100vw - 768px) / 672, 40px);
}

.contact-us-section__title .heading-3 {
  letter-spacing: clamp(-1.4px, 0.3px + -1.7 * (100vw - 768px) / 672, 0.3px);
}

.contact-us-section__line {
  margin-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.contact-us-section__line::before {
  background: white;
}

.contact-us-section__descr {
  margin-bottom: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
  font-size: clamp(18px, 18px + 12 * (100vw - 768px) / 672, 30px);
  font-weight: 500;
  line-height: 1.28;
}

.contact-us-section__buttons {
  display: flex;
}

@media (max-width: 530px) {
  .contact-us-section__buttons {
    flex-direction: column;
  }
}
.contact-us-section__btn {
  width: -moz-fit-content;
  width: fit-content;
}

.contact-us-section__btn:first-child {
  color: #fff;
  margin-right: 16px;
}

@media (max-width: 530px) {
  .contact-us-section__btn:first-child {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
.contact-us-section__image {
  border-radius: 24px;
  overflow: hidden;
  min-width: clamp(320px, 320px + 240 * (100vw - 768px) / 672, 560px);
}

.contact-us-section__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 564px;
}

@media (max-width: 840px) {
  .contact-us-section__image img {
    max-width: none;
  }
}
@media (max-width: 840px) {
  .contact-us-section__image {
    min-width: auto;
  }
}
.contact-us-section__form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact-us-section__form {
  width: 100%;
  gap: clamp(10px, 12px + -2 * (100vw - 768px) / 672, 12px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 640px) {
  .contact-us-section__form {
    width: 100%;
  }
}
@media (max-width: 360px) {
  .contact-us-section__form {
    gap: 8px;
  }
}
.contact-us-section__form:not(:last-child) {
  margin-bottom: 16px;
}

.contact-us-section__form-input {
  flex: 1;
}

.contact-us-section__form-info {
  font-size: 14px;
  line-height: 1.4;
}

.contact-us-section__form-info a {
  display: inline-block;
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.contact-us-section__form-info a:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--cl-charcoal);
  transition: 0.3s;
  content: "";
  position: absolute;
  z-index: -1;
}

.contact-us-section__form-info a:hover {
  color: var(--cl-navy);
}

.contact-us-section__form-info a:hover:before {
  height: 50%;
  background-color: var(--cl-white);
}

.contact-us-section__form-btn {
  background-color: var(--cl-navy);
  border-color: var(--cl-navy);
}

.contact-us-section__form-btn i {
  color: var(--cl-white);
  margin-top: 2px;
  margin-right: 2px;
}

.contact-us-section__form-btn:hover {
  background-color: var(--cl-aqua);
}

.contact-us-section__form-btn:hover i {
  color: var(--cl-charcoal);
  transform: rotate(45deg);
}

@media (min-width: 1200px) {
  .contact-us-section.subscribe-type .contact-us-section__text {
    padding-top: clamp(30px, 30px + 50 * (100vw - 768px) / 672, 80px);
    padding-left: clamp(20px, 20px + 60 * (100vw - 768px) / 672, 80px);
    padding-right: clamp(20px, 20px + 60 * (100vw - 768px) / 672, 80px);
    padding-bottom: clamp(20px, 20px + 60 * (100vw - 768px) / 672, 80px);
  }
}
.contact-us-section.subscribe-type .contact-us-section__form-container input {
  border: 1px solid rgba(1, 16, 147, 0.5);
}

@media (max-width: 540px) {
  .contact-us-section.subscribe-type .contact-us-section__form-container input {
    padding: 10px 13px;
  }
}
.contact-us-section.subscribe-type .contact-us-section__title .heading-3 {
  line-height: 1.08;
  letter-spacing: 0;
}

.contact-us-section-individual .contact-us-section {
  background-color: #F2F1F1;
  padding-top: 0;
  /* margin-top: ac(80px, 42px); */
}

.contact-us-section-sectors .contact-us-section__image {
  width: 547px;
}

@media (max-width: 840px) {
  .contact-us-section-sectors .contact-us-section__image {
    /* max-height: 420px; */
    width: 100%;
  }
}
.contact-us-section-sectors .contact-us-section__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 725px;
}

@media (max-width: 840px) {
  .contact-us-section-sectors .contact-us-section__text {
    max-width: 100%;
  }
}
.type-2 .contact-us-section__title {
  margin-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.workforce-section {
  padding-top: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
}

.workforce-section__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 865px) {
  .workforce-section__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
.workforce-section__title {
  margin-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.workforce-section__title span:not(.word) {
  color: var(--cl-navy);
}

.workforce-section__image {
  margin-right: clamp(12px, 12px + 44 * (100vw - 768px) / 672, 56px);
}

@media (max-width: 865px) {
  .workforce-section__image {
    margin-right: 0;
    margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  }
}
.workforce-section__image img {
  border-radius: 24px;
  min-width: 430px;
}

@media (max-width: 992px) {
  .workforce-section__image img {
    min-width: clamp(340px, 340px + 50 * (100vw - 768px) / 672, 390px);
    max-width: clamp(510px, 580px + -70 * (100vw - 768px) / 672, 580px);
  }
}
@media (max-width: 865px) {
  .workforce-section__image img {
    min-width: auto;
    width: 100%;
  }
}
.workforce-section__line {
  margin-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.workforce-section__text {
  color: var(--cl-charoal);
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  line-height: 1.25;
  max-width: 500px;
}

.talent-supply-section {
  padding-top: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  padding-bottom: clamp(46px, 46px + 94 * (100vw - 768px) / 672, 140px);
  background-color: #FFF;
  z-index: 11;
}

.talent-supply-section__wrap {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .talent-supply-section__wrap {
    flex-direction: column;
  }
}
.talent-supply-section__title {
  margin-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.talent-supply-section__title span:not(.word) {
  color: var(--cl-navy);
}

.talent-supply-section__products {
  padding: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  border-radius: 20px;
  border: 1px solid rgba(53, 53, 63, 0.3);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  -moz-column-gap: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  column-gap: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  row-gap: 16px;
  min-width: clamp(560px, 560px + 142 * (100vw - 768px) / 672, 702px);
}

@media (max-width: 992px) {
  .talent-supply-section__products {
    margin-top: clamp(24px, 24px + 10 * (100vw - 768px) / 672, 34px);
    max-width: 680px;
  }
}
@media (max-width: 680px) {
  .talent-supply-section__products {
    grid-template-columns: repeat(1, 1fr);
    min-width: auto;
  }
}
.talent-supply-section__product {
  max-width: 265px;
  width: 100%;
  padding-left: 40px;
  position: relative;
  color: var(--cl-charcoal);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.3;
  font-weight: 700;
}

@media (max-width: 680px) {
  .talent-supply-section__product {
    max-width: 575px;
  }
}
.talent-supply-section__product:nth-child(odd) {
  grid-column: 1;
}

.talent-supply-section__product:nth-child(even) {
  grid-column: 2;
}

@media (max-width: 680px) {
  .talent-supply-section__product:nth-child(even) {
    grid-column: auto;
  }
}
.talent-supply-section__product::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #0035EC;
}

@media (max-width: 992px) {
  .talent-potential-section {
    overflow: hidden;
  }
}
.talent-potential-section__image {
  z-index: 1;
  position: absolute;
  inset: 0;
}

/* @media (max-width: 551px) {
      bottom: 0;
      height: auto;
    } */
.talent-potential-section__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.talent-potential-section__circle {
  position: absolute;
  z-index: 3;
  width: 100%;
  width: 1470px;
  top: -500px;
  right: -700px;
}

@media (max-width: 1260px) {
  .talent-potential-section__circle {
    right: -50%;
    top: -430px;
    width: 1240px;
  }
}
@media (max-width: 992px) {
  .talent-potential-section__circle {
    top: -60px;
    left: -50%;
    right: auto;
    width: 1200px;
  }
}
.talent-potential-section__wrap {
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
  padding-top: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(72px, 72px + 94 * (100vw - 768px) / 672, 166px);
}

@media (max-width: 992px) {
  .talent-potential-section__wrap {
    flex-direction: column;
  }
}
.talent-potential-section__card {
  background: #68E6FC;
  padding-top: clamp(32px, 32px + 18 * (100vw - 768px) / 672, 50px);
  padding-bottom: clamp(32px, 32px + 22 * (100vw - 768px) / 672, 54px);
  padding-left: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  padding-right: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  border-radius: 24px;
  width: 100%;
  max-width: 640px;
}

.talent-potential-section__card-title {
  color: var(--cl-navy);
  margin-bottom: 16px;
  max-width: clamp(348px, 348px + 162 * (100vw - 992px) / 448, 510px);
}

@media (max-width: 992px) {
  .talent-potential-section__card-title {
    max-width: none;
  }
}
.talent-potential-section__card-descr {
  font-weight: 500;
  font-size: clamp(20px, 20px + 10 * (100vw - 768px) / 672, 30px);
  margin-bottom: clamp(28px, 28px + 12 * (100vw - 768px) / 672, 40px);
  min-height: 76px;
  line-height: 1.28;
}

.talent-potential-section__card-btn {
  width: -moz-fit-content;
  width: fit-content;
}

.talent-potential-section__card-socials {
  display: flex;
  align-items: center;
}

.talent-potential-section__card-social {
  margin-right: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.talent-potential-section__card-social i {
  font-size: clamp(38px, 38px + 18 * (100vw - 768px) / 432, 56px);
  color: var(--cl-navy);
  transition: 0.4s all;
}

.talent-potential-section__card-social:last-child {
  margin-right: 0;
}

.talent-potential-section__card-social:hover i {
  color: var(--cl-navy-80);
}

.talent-potential-section__card:first-child {
  background: #FFFFFF;
  border: 2px solid #68E6FC;
}

.talent-potential-section__card:first-child .talent-potential-section__card-btn {
  border: 2px solid var(--cl-navy);
  color: var(--cl-navy);
}

.talent-potential-section__card:last-child {
  transform: translateY(48px);
  margin-left: clamp(-48px, -34px + -14 * (100vw - 768px) / 672, -34px);
  z-index: 3;
}

@media (max-width: 992px) {
  .talent-potential-section__card:last-child {
    margin-left: 0;
    transform: translateY(0);
    margin-top: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  }
}
.talent-potential-section__card:last-child .talent-potential-section__card-btn {
  color: #FFF;
}

.talent-potential-section__card:last-child .talent-potential-section__card-btn:hover {
  color: var(--cl-navy);
}

.talent-potential-about .talent-potential-section__wrap {
  padding-bottom: clamp(64px, 64px + 131 * (100vw - 768px) / 672, 195px);
}

.talent-potential-about .talent-potential-section__overlay, .talent-potential-about .talent-potential-section__image, .talent-potential-about .talent-potential-section__bg {
  display: none;
}

.talent-potential-about .talent-potential-section__card-btn:hover .btn__icon {
  transform: rotate(0);
}

.case-studies-section {
  background: #F5F5F5;
  padding-top: clamp(62px, 62px + 58 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(72px, 72px + 48 * (100vw - 768px) / 672, 120px);
}

.case-studies-section__wrap {
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  background-color: #FFF;
}

@media (max-width: 935px) {
  .case-studies-section__wrap {
    flex-direction: column;
  }
}
.case-studies-section__title {
  color: var(--cl-navy);
  margin-bottom: clamp(24px, 24px + 20 * (100vw - 768px) / 672, 44px);
}

/* &__image {
    min-width: ac(400px, 390px);
    @media (max-width: 935px) {
      display: none;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  } */
.case-studies-section__slider {
  /* padding-right: ac(41px, 18px);
  padding-left: ac(56px, 18px);
  padding-bottom: ac(41px, 18px); */
  /* width: 70%; */
  /* max-width: 740px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  /* @media (max-width: 1260px) {
    width: 50%;
  }
  @media (max-width: 935px) {
    width: 100%;
    max-width: none;
  } */
}

.case-studies-section__slider .swiper {
  max-width: clamp(777px, 777px + 503 * (100vw - 820px) / 620, 1280px);
}

@media (max-width: 880px) {
  .case-studies-section__slider .swiper {
    max-width: 100%;
  }
}
.case-studies-section__card {
  background: #FFFFFF;
  max-width: clamp(777px, 777px + 503 * (100vw - 820px) / 620, 1280px);
  min-height: clamp(573px, 573px + 50 * (100vw - 992px) / 448, 623px);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 880px) {
  .case-studies-section__card {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    min-height: auto;
  }
}
.case-studies-section__card-image {
  max-width: clamp(340px, 340px + 158 * (100vw - 820px) / 620, 498px);
}

@media (max-width: 880px) {
  .case-studies-section__card-image {
    display: none;
  }
}
.case-studies-section__card-image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.case-studies-section__card-content {
  padding: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px) clamp(18px, 18px + 38 * (100vw - 640px) / 800, 56px) clamp(22px, 22px + 18 * (100vw - 768px) / 672, 40px);
  width: 61%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 880px) {
  .case-studies-section__card-content {
    width: 100%;
  }
}
.case-studies-section__card-time {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 16px;
}

.case-studies-section__card-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
}

@media (max-width: 576px) {
  .case-studies-section__card-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 576px) {
  .case-studies-section__card-text {
    margin-bottom: 14px;
  }
}
.case-studies-section__card-title {
  margin-bottom: 8px;
  color: var(--cl-charcoal);
  font-weight: 700;
}

.case-studies-section__card-subtitle {
  color: var(--cl-navy);
}

.case-studies-section__card-logo {
  border: 1px solid rgba(53, 53, 63, 0.3019607843);
  border-radius: 12px;
  padding: 19px 55px;
}

@media (max-width: 576px) {
  .case-studies-section__card-logo {
    padding: 12px 45px;
  }
}
.case-studies-section__card-body__title {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  color: var(--cl-charcoal);
}

.case-studies-section__card-descr {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  color: rgba(53, 53, 63, 0.8);
  line-height: 1.4em;
  letter-spacing: -0.1px;
  max-height: 9.8em;
  overflow: auto;
  scrollbar-width: thin;
  padding-right: 2px;
}

.case-studies-section__card-descr::-webkit-scrollbar {
  width: 3px;
}

@media (max-width: 880px) {
  .case-studies-section__card-descr {
    margin-bottom: 20px;
  }
}
.case-studies-section__card-btn {
  border: 1px solid #010F93;
  width: -moz-fit-content;
  width: fit-content;
}

.case-studies-section__card-btn .btn__text, .case-studies-section__card-btn .btn__icon {
  color: var(--cl-navy);
}

.case-studies-section__card-btn:hover {
  background-color: transparent;
}

.case-studies-section__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
}

.case-studies-section__arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: clamp(8px, 8px + 8 * (100vw - 768px) / 672, 16px);
  position: absolute;
  right: clamp(12px, 12px + 44 * (100vw - 768px) / 672, 56px);
  bottom: clamp(22px, 22px + 18 * (100vw - 768px) / 672, 40px);
}

.case-studies-section__arrows .swiper-button {
  margin-top: 0;
}

.case-studies-section__pagination {
  position: relative;
  inset: auto;
  width: auto;
}

@media (max-width: 576px) {
  .case-studies-section__pagination {
    display: none;
  }
}
.our-values-section {
  padding-top: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
  padding-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
}

.our-values-section__title {
  margin-bottom: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
}

.our-values-section__title span:not(.word) {
  color: var(--cl-navy);
}

.our-values-section__slider {
  overflow: visible;
}

.our-values-section__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  padding-left: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  padding-top: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  padding-bottom: clamp(20px, 20px + 10 * (100vw - 768px) / 672, 30px);
  background-color: #F2F1F1;
  min-height: 276px;
  max-width: 305px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  border-bottom: 6px solid var(--cl-aqua);
  margin-right: clamp(10px, 10px + 10 * (100vw - 768px) / 672, 20px);
}

@media (min-width: 992px) {
  .our-values-section__card {
    height: auto;
    flex-shrink: initial;
  }
}
@media (max-width: 992px) {
  .our-values-section__card {
    margin-right: 0;
    padding-bottom: 0;
    max-width: none;
  }
}
@media (max-width: 576px) {
  .our-values-section__card {
    min-height: 296px;
  }
}
.our-values-section__card:last-child {
  margin-right: 0;
}

.our-values-section__card:nth-child(2n) {
  border-bottom: none;
  border-top: 6px solid var(--cl-aqua);
}

.our-values-section__card:nth-child(2n) .our-values-section__card-icon {
  margin-top: -6px;
}

.our-values-section__card-icon {
  background-color: var(--cl-aqua);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.our-values-section__card-icon img {
  max-width: 48px;
  height: auto;
}

.our-values-section__card-title {
  margin-bottom: 8px;
}

.our-values-section__card-descr {
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  max-height: 180px;
  overflow: auto;
  scrollbar-width: thin;
  padding-right: 2px;
}

.our-values-section__card-descr::-webkit-scrollbar {
  width: 3px;
}

.our-values-section .swiper {
  overflow: visible;
}

.our-values-section .swiper-buttons {
  display: none;
}

@media (max-width: 992px) {
  .our-values-section .swiper-buttons {
    display: flex;
    margin: auto;
    margin-top: -5px;
  }
}
.our-values-section .swiper-button:hover {
  border-color: 1px solid var(--cl-aqua) !important;
  background-color: var(--cl-aqua) !important;
}

.our-values-section .swiper-button:hover:before {
  color: var(--cl-charcoal);
}

.our-values-section-about {
  background-color: #F2F1F1;
  padding-top: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
}

.our-values-section-about .our-values-section__title {
  text-align: center;
}

.our-values-section-about .our-values-section__card {
  background-color: #FFF;
}

.process-section {
  padding-top: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
}

.process-section__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 992px) {
  .process-section__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
/* left block */
.process-section__text {
  margin-right: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

@media (max-width: 992px) {
  .process-section__text {
    margin-right: 0;
    margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  }
}
.process-section__title {
  max-width: clamp(400px, 400px + 200 * (100vw - 992px) / 448, 600px);
}

@media (max-width: 992px) {
  .process-section__title {
    max-width: 600px;
  }
}
.process-section__title span:not(.word) {
  color: var(--cl-navy);
}

.process-section__line {
  margin-top: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  margin-bottom: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
}

.process-section__image {
  max-width: 630px;
}

@media (max-width: 992px) {
  .process-section__image {
    max-width: 650px;
  }
}
.process-section__image img {
  border-radius: 24px;
  width: 100%;
}

/* right block */
.process-section__card {
  max-width: 595px;
  width: 100%;
  background-color: var(--cl-aqua);
  border: 1px solid rgba(53, 53, 63, 0.2);
  border-radius: 12px;
  padding-top: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  padding-right: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
  padding-bottom: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  padding-left: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
  margin-bottom: 12px;
}

@media (max-width: 992px) {
  .process-section__card {
    max-width: 650px;
  }
}
.process-section__card:last-child {
  margin-bottom: 0px;
}

.process-section__card-nav {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .process-section__card-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
.process-section__card-icon {
  min-width: clamp(54px, 54px + 10 * (100vw - 768px) / 672, 64px);
  height: clamp(54px, 54px + 10 * (100vw - 768px) / 672, 64px);
  border-radius: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: clamp(8px, 8px + 8 * (100vw - 768px) / 672, 16px);
}

@media (max-width: 576px) {
  .process-section__card-icon {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
.process-section__card-icon img {
  max-width: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
  height: auto;
}

.process-section__card-title {
  color: var(--cl-navy);
}

.process-section__card-body {
  color: #363638;
  max-height: 440px;
  overflow: auto;
  scrollbar-width: thin;
}

.process-section__card-body::-webkit-scrollbar {
  width: 3px;
}

.team-section {
  padding-top: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
  background-color: #F2F1F1;
}

.team-section__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(38px, 38px + 34 * (100vw - 768px) / 672, 72px);
}

@media (max-width: 576px) {
  .team-section__nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 576px) {
  .team-section__title {
    margin-bottom: clamp(18px, 18px + 4 * (100vw - 768px) / 672, 22px);
  }
}
.team-section__title span:not(.word) {
  color: var(--cl-navy);
}

.team-section__btn {
  border: 1px solid var(--cl-navy);
}

.team-section__btn .btn__text {
  color: var(--cl-navy) !important;
}

.team-section__btn i {
  color: var(--cl-navy);
}

.team-section__card {
  max-width: 305px;
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  overflow: hidden;
  border: 1px solid var(--cl-aqua);
  margin-right: clamp(8px, 8px + 12 * (100vw - 768px) / 672, 20px);
}

@media (min-width: 992px) {
  .team-section__card {
    height: auto;
    flex-shrink: initial;
  }
}
@media (max-width: 992px) {
  .team-section__card {
    margin-right: 0;
    max-width: none;
  }
}
.team-section__card:last-child {
  margin-right: 0;
}

.team-section__card-text {
  padding-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  padding-right: clamp(10px, 10px + 4 * (100vw - 768px) / 672, 14px);
  padding-left: clamp(10px, 10px + 4 * (100vw - 768px) / 672, 14px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-section__card-image {
  margin-bottom: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  min-height: clamp(160px, 160px + 145 * (100vw - 768px) / 672, 305px);
  max-height: clamp(305px, 405px + -100 * (100vw - 768px) / 672, 405px);
}

.team-section__card-image img {
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
}

.team-section__card-name {
  margin-bottom: 8px;
  color: #3C3C3B;
}

.team-section__card-position {
  margin-bottom: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.2;
  color: var(--cl-navy);
  text-align: center;
}

@media (max-width: 1300px) {
  .team-section__card-position {
    min-height: 43px;
  }
}
@media (max-width: 576px) {
  .team-section__card-position {
    min-height: auto;
  }
}
.team-section__card-line {
  width: 142px;
  height: 2px;
  background-color: var(--cl-aqua);
  margin-bottom: 20px;
}

.team-section__card-socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-section__card-social {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  min-width: 36px;
}

.team-section__card-social:last-child {
  margin-right: 0;
}

.team-section__card-social i {
  color: #0035EC;
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
}

.team-section__card-social:hover i {
  color: var(--cl-navy-80);
}

.team-section .swiper {
  overflow: visible;
}

.team-section .swiper-buttons {
  display: none;
}

@media (max-width: 992px) {
  .team-section .swiper-buttons {
    display: flex;
    margin: auto;
    margin-top: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  }
}
.our-sectors-section {
  padding-top: clamp(64px, 64px + 72 * (100vw - 768px) / 672, 136px);
  padding-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
}

.our-sectors-section__title {
  margin-bottom: clamp(30px, 30px + 42 * (100vw - 768px) / 672, 72px);
  letter-spacing: normal;
}

.our-sectors-section__title span:not(.word) {
  color: var(--cl-navy);
}

.our-sectors-section__wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

/* display: flex;
    flex-wrap: wrap;
    gap: ac(20px, 14px); */
@media (max-width: 980px) {
  .our-sectors-section__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 640px) {
  .our-sectors-section__wrap {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}
.our-sectors-section__item {
  /* width: 32.1%; */
  width: 100%;
  min-height: clamp(200px, 200px + 110 * (100vw - 992px) / 448, 310px);
  border: 1px solid var(--cl-aqua);
  justify-content: flex-start;
  padding-left: clamp(18px, 18px + 12 * (100vw - 768px) / 672, 30px);
}

.our-sectors-section__item-line {
  width: 62px;
  height: 4px;
  margin-bottom: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.our-sectors-section__item-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.our-sectors-section__item .trusted-section__item {
  justify-content: flex-start;
}

.our-sectors-section__item .trusted-section__item-title {
  color: var(--cl-charcoal);
  margin-bottom: clamp(8px, 8px + 4 * (100vw - 768px) / 672, 12px);
  min-height: clamp(67px, 92px + -25 * (100vw - 992px) / 448, 92px);
}

@media (max-width: 992px) {
  .our-sectors-section__item .trusted-section__item-title {
    min-height: clamp(64px, 64px + 28 * (100vw - 715px) / 277, 92px);
  }
}
@media (max-width: 715px) {
  .our-sectors-section__item .trusted-section__item-title {
    min-height: auto;
  }
}
.our-sectors-section__item .trusted-section__item-text {
  color: var(--cl-charcoal);
  --max-line-num: 3;
}

.our-sectors-section__item .trusted-section__item-link {
  background-color: var(--cl-aqua);
  border: none;
  width: clamp(42px, 42px + 14 * (100vw - 768px) / 672, 56px);
  height: clamp(42px, 42px + 14 * (100vw - 768px) / 672, 56px);
}

/* bg */
.our-sectors-section__item::before {
  background-color: var(--cl-white);
}

/* bottom line */
.our-sectors-section__item:hover .trusted-section__item-title {
  color: var(--cl-white);
}

.our-sectors-section__item:hover .trusted-section__item-text {
  color: var(--cl-white);
}

.our-sectors-section__item:hover .trusted-section__item-link i {
  transform: rotate(-45deg);
}

.our-sectors-section__item:hover::before {
  background-color: var(--cl-charcoal);
}

.process-partner-section {
  padding-top: clamp(42px, 42px + 78 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(42px, 42px + 78 * (100vw - 768px) / 672, 120px);
  background-color: var(--cl-charcoal);
}

.process-partner-section__wrap {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .process-partner-section__wrap {
    flex-direction: column;
  }
}
.process-partner-section__image {
  min-width: clamp(465px, 465px + 195 * (100vw - 992px) / 448, 660px);
  margin-right: clamp(18px, 18px + 38 * (100vw - 992px) / 448, 56px);
}

.process-partner-section__image img {
  border-radius: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

@media (max-width: 992px) {
  .process-partner-section__image {
    margin-right: 0;
    margin-bottom: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
    min-width: auto;
    max-width: 545px;
  }
}
.process-partner-section__title {
  margin-bottom: clamp(32px, 32px + 18 * (100vw - 768px) / 672, 50px);
  color: #FFF;
}

.process-partner-section__title span:not(.word) {
  color: var(--cl-aqua);
}

.process-partner-section__line {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  height: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
}

.process-partner-section__line:before {
  background-color: var(--cl-aqua);
}

.process-partner-section__descr {
  color: #FFF;
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.process-partner-section__descr p:not(:last-child) {
  margin-bottom: 10px;
}

.process-partner-section__btn {
  width: -moz-fit-content;
  width: fit-content;
}

.process-partner-section__btn.btn {
  border: 1px solid #fff;
}

.process-partner-section__btn.btn .btn__text, .process-partner-section__btn.btn span i {
  color: #FFF;
}

.process-partner-section.process-partner-about {
  background-color: transparent;
}

.process-partner-section.process-partner-about .process-partner-section__wrap {
  align-items: center;
}

.process-partner-section.process-partner-sectors .process-partner-section__descr {
  font-size: 16px;
  line-height: 1.4;
}

.about-second-section {
  padding-top: clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
}

.about-second-section__wrap {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .about-second-section__wrap {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .about-second-section__left {
    margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  }
}
.about-second-section__title {
  margin-bottom: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
}

.about-second-section__title span:not(.word) {
  color: var(--cl-navy);
}

.about-second-section__text {
  max-width: clamp(430px, 430px + 165 * (100vw - 768px) / 672, 595px);
}

@media (max-width: 768px) {
  .about-second-section__text {
    max-width: none;
  }
}
.about-second-section__text p:not(:last-child) {
  margin-bottom: 10px;
}

.gallery-section {
  padding-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.gallery-section__slider {
  z-index: 7;
  position: relative;
}

.gallery-section__slider:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 10 * (100vw - 768px) / 672, 30px);
}

.gallery-section .swiper {
  overflow: visible;
}

.gallery-section .swiper .swiper-slide {
  width: -moz-fit-content;
  width: fit-content;
}

.gallery-section__item {
  overflow: hidden;
  width: -moz-fit-content;
  width: fit-content;
}

.gallery-section__item img {
  border: 1px solid var(--cl-aqua);
  border-radius: 18px;
  width: auto;
  height: clamp(210px, 210px + 184 * (100vw - 768px) / 672, 394px);
  -o-object-fit: cover;
  object-fit: cover;
}

.logos-section {
  padding-top: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
}

.logos-section__slider {
  display: flex;
}

.logos-section .swiper {
  overflow: visible;
}

.logos-section .swiper .swiper-slide {
  width: -moz-fit-content;
  width: fit-content;
}

.logos-section__item:not(:last-child) {
  margin-right: 20px;
}

.logos-section__item img {
  width: auto;
  max-height: clamp(120px, 120px + 52 * (100vw - 768px) / 672, 172px);
}

.search-form-section {
  position: relative;
  overflow: visible;
  z-index: 40;
  margin-top: clamp(-86px, -64px + -22 * (100vw - 768px) / 672, -64px);
}

.search-form-section__wrap {
  padding-top: clamp(30px, 30px + 21 * (100vw - 768px) / 672, 51px);
  padding-bottom: clamp(30px, 30px + 29 * (100vw - 768px) / 672, 59px);
  padding-left: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  padding-right: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  border-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  border: 1px solid var(--cl-aqua);
  background: var(--cl-white);
}

.search-form-section__form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  grid-row-gap: 14px;
}

@media (min-width: 1400px) {
  .search-form-section__form {
    align-items: flex-start;
  }
}
.search-form-section__form .btn {
  margin-left: clamp(0px, 0px + 8 * (100vw - 768px) / 672, 8px);
}

.search-form-section__form .meta-input, .search-form-section__form .meta-select {
  flex: 1;
}

@media (min-width: 1279px) {
  .search-form-section__form .meta-input:first-child {
    min-width: clamp(320px, 320px + 168 * (100vw - 768px) / 672, 488px);
  }
}
@media (max-width: 1024px) {
  .search-form-section__form .meta-input:first-child {
    min-width: 100%;
  }
}
@media (max-width: 1024px) {
  .search-form-section__form {
    flex-wrap: wrap;
  }
}
@media (max-width: 640px) {
  .search-form-section__form {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .search-form-section__form .meta-input, .search-form-section__form .meta-select {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .search-form-section__form .btn {
    width: 100%;
  }
}
.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: var(--cl-navy);
}

.filters-btn__icon {
  width: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  height: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.filters-btn__icon i {
  font-size: 16px;
  transition: all 0.3s ease;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.filters-btn__icon i.icon-close {
  opacity: 0;
  color: var(--cl-navy);
}

.filters-btn.active .filters-btn__icon i.icon-close {
  opacity: 1;
}

.filters-btn.active .filters-btn__icon i.icon-filters {
  opacity: 0;
}

.filters-btn:not(.active):hover .filters-btn__icon i {
  color: var(--white);
}

.filters-btn:hover .filters-btn__icon {
  transform: none;
}

.job-search-section {
  padding-top: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
  overflow: visible;
  position: relative;
  z-index: 20;
}

.job-search-section__subtitle {
  width: 100%;
  text-align: center;
}

.job-search-section__subtitle:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

@media (max-width: 640px) {
  .job-search-section__subtitle br {
    display: none;
  }
}
.job-search-section__wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.job-search-section__container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  flex-direction: row;
}

.job-search-section__sidebar {
  flex: 1;
  max-width: 23.83%;
}

@media (max-width: 1300px) {
  .job-search-section__sidebar {
    width: 26%;
  }
}
.job-search-section__sidebar-inner {
  padding-top: clamp(40px, 40px + 115 * (100vw - 768px) / 672, 155px);
}

.job-search-section__sidebar-inner .btn-close__icon {
  transition: 0.3s all;
}

.job-search-section__sidebar-inner .btn-close__icon:hover {
  opacity: 0.85;
}

.job-search-section__sidebar-filters-title {
  width: 100%;
  margin-bottom: clamp(24px, 24px + 18 * (100vw - 768px) / 672, 42px);
  position: relative;
}

.job-search-section__sidebar-filters-title .btn-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 5;
}

@media (min-width: 641px) {
  .job-search-section__sidebar-filters-title .btn-close {
    display: none !important;
  }
}
.job-search-section__sidebar-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.job-search-section__sidebar-item .btn-show-hide {
  margin-top: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.job-search-section__sidebar-item-content {
  /*padding-top: 6px;*/
  transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /*grid-column-gap: 10px;
  grid-row-gap: 9px;*/
  overflow: hidden;
}

.job-search-section__sidebar-item-content.with-inputs {
  grid-row-gap: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  overflow: visible;
}

.job-search-section__sidebar-item-content.with-inputs .meta-select, .job-search-section__sidebar-item-content.with-inputs .meta-input {
  width: 100%;
}

.job-search-section__sidebar-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  grid-row-gap: 8px;
  transition: min-height 0.4s ease;
}

.job-search-section__sidebar-accordion-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition: all 0.4s ease;
}

.job-search-section__sidebar-accordion-item.active .job-search-section__sidebar-accordion-item-top {
  margin-bottom: 9px;
}

.job-search-section__sidebar-accordion-item.active .job-search-section__sidebar-accordion-item-btn {
  transform: scaleY(-1);
}

.job-search-section__sidebar-accordion-item.active .job-search-section__sidebar-accordion-item-content {
  opacity: 1;
}

.job-search-section__sidebar-accordion-item:not(.active) {
  cursor: pointer;
}

.job-search-section__sidebar-accordion-item:not(.active) .job-search-section__sidebar-accordion-item-content {
  max-height: 0 !important;
}

.job-search-section__sidebar-accordion-item:not(.active) .job-search-section__sidebar-accordion-item-btn {
  transform: scaleY(1);
}

.job-search-section__sidebar-accordion-item:not(:last-child) {
  margin-bottom: clamp(18px, 18px + 5 * (100vw - 768px) / 672, 23px);
}

.job-search-section__sidebar-accordion-item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-bottom 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--cl-aqua);
  cursor: pointer;
}

.job-search-section__sidebar-accordion-item-top:hover {
  opacity: 0.71;
}

@media (min-width: 768px) {
  .job-search-section__sidebar-accordion-item-top:hover .job-search-section__sidebar-accordion-item-title {
    transform: translateX(4px);
  }
}
.job-search-section__sidebar-accordion-item-title {
  max-width: calc(100% - 40px);
  color: var(--cl-navy);
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  line-height: 132%;
  font-weight: 500;
  transition: 0.27s all ease-in-out;
}

.job-search-section__sidebar-accordion-item-btn {
  --size: clamp(34px, calc(34px + 2.00 * ((100vw - 768px) / 672)), 36px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
}

.job-search-section__sidebar-accordion-item-btn i {
  font-size: 8px;
  transition: all 0.4s ease;
  color: var(--cl-charcoal);
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.job-search-section__sidebar-accordion-item-btn:hover i {
  color: var(--cl-navy);
  transform: translateX(-50%) translateY(-50%);
}

.job-search-section__sidebar-accordion-item-content {
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*max-height: 0;*/
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.4s ease;
  width: 100%;
}

.job-search-section__sidebar-accordion-item-content .meta-checkbox {
  min-height: clamp(24px, 24px + 12 * (100vw - 768px) / 672, 36px);
}

.job-search-section__sidebar-accordion-item-content .meta-checkbox:hover .meta-checkbox__box {
  border-color: var(--cl-navy);
}

.job-search-section__sidebar-accordion-item-content .meta-checkbox:hover .meta-checkbox__text {
  color: var(--cl-navy);
}

.job-search-section__sidebar-accordion-item-content .meta-checkbox:not(:last-child) {
  margin-bottom: 4px;
}

.job-search-section__sidebar-accordion-item-content .meta-checkbox.is-hidden {
  display: none;
}

.job-search-section__sidebar-accordion-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: clamp(1px, 3px + -2 * (100vw - 768px) / 672, 3px);
  width: 100%;
}

.job-search-section__sidebar-accordion-item-list .meta-checkbox__text {
  transform: translateY(2px);
  color: var(--cl-charcoal);
}

.job-search-section__sidebar-accordion-item-list .meta-checkbox__box i.icon-check {
  color: var(--cl-charcoal) !important;
}

.job-search-section__sidebar-accordion-item-list .meta-select {
  width: 100%;
}

.job-search-section__sidebar-accordion-item-list .meta-select .choices__inner {
  background: var(--cl-white);
  border-color: rgba(53, 53, 63, 0.3);
}

.job-search-section__sidebar-accordion-item-list .meta-select .choices__inner .choices__placeholder {
  color: var(--cl-charcoal) !important;
}

.job-search-section__sidebar-accordion-item-list .meta-select .choices__list {
  background-color: var(--cl-white);
}

.job-search-section__sidebar-accordion-item-list .meta-select .choices:after {
  border-color: var(--cl-charcoal);
}

.job-search-section__sidebar-accordion-item-list .meta-select:not(:first-child) {
  margin-top: clamp(9px, 11px + -2 * (100vw - 768px) / 672, 11px);
}

.job-search-section__sidebar-buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.job-search-section__sidebar-buttons .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-search-section__sidebar-buttons .btn:not(.btn-navy) {
  border-color: var(--cl-navy);
}

.job-search-section__sidebar-buttons .btn:not(.btn-navy) .btn__text {
  color: var(--cl-navy);
}

.job-search-section__sidebar-buttons .btn:not(.btn-navy):hover {
  border-color: var(--cl-navy);
}

.job-search-section__result {
  width: 71.8%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

@media (max-width: 1300px) {
  .job-search-section__result {
    width: 67%;
  }
}
@media (max-width: 640px) {
  .job-search-section__result {
    position: relative;
  }
}
.job-search-section__title:not(:last-child) {
  margin-bottom: 12px;
}

.job-search-section__subtitle {
  margin-top: clamp(-4px, 0px + -4 * (100vw - 768px) / 672, 0px);
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: var(--cl-navy);
  line-height: 1.32;
  text-align: left;
}

.job-search-section__subtitle:not(:last-child) {
  margin-bottom: 12px;
}

.job-search-section__result-bg {
  display: none;
}

@media (max-width: 640px) {
  .job-search-section__result-bg {
    display: block;
    position: absolute;
    inset: 0;
    z-index: 10;
    pointer-events: none;
  }
}
.job-search-section__result-grid {
  padding-top: clamp(18px, 18px + 10 * (100vw - 768px) / 672, 28px);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  grid-row-gap: 20px;
}

@media (max-width: 901px) {
  .job-search-section__result-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 641px) {
  .job-search-section__filters-btn {
    display: none !important;
  }
}
@media not all and (min-width: 1280px) {
  .job-search-section__sidebar {
    max-width: 30%;
  }
}
@media not all and (min-width: 768px) {
  .job-search-section__sidebar {
    max-width: 40%;
  }
  .job-search-section__result {
    width: 55%;
  }
}
@media not all and (min-width: 640px) {
  .job-search-section {
    overflow: hidden;
  }
  .job-search-section__container {
    flex-direction: row;
  }
  .job-search-section__sidebar {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    opacity: 0;
    max-width: 280px;
    z-index: 2;
    transition: all 0.4s ease;
  }
  @media not all and (min-width: 374px) {
    .job-search-section__sidebar {
      max-width: 100%;
    }
  }
  .job-search-section__result {
    width: 100%;
    transition: all 0.3s ease;
    transform: translateX(0);
    opacity: 1;
    position: relative;
  }
  .job-search-section__filters-btn {
    display: flex;
    margin-right: auto;
    margin-bottom: 24px;
  }
  .job-search-section__container.active .job-search-section__sidebar {
    position: relative;
    opacity: 1;
    transform: translateX(0);
  }
  .job-search-section__container.active .job-search-section__result {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(305px);
    opacity: 0.4;
  }
  .job-search-section__container.active .job-search-section__result > * {
    pointer-events: none;
  }
  @media not all and (min-width: 374px) {
    .job-search-section__container.active .job-search-section__result {
      transform: translateX(110%);
    }
  }
  .job-search-section__container.active .job-search-section__result-bg {
    pointer-events: auto;
  }
}
.contact-form-section {
  margin-top: clamp(92px, 92px + 44 * (100vw - 768px) / 672, 136px);
  padding-bottom: clamp(64px, 64px + 64 * (100vw - 768px) / 672, 128px);
  padding-top: clamp(64px, 64px + 16 * (100vw - 768px) / 672, 80px);
  border-top: 1px solid var(--cl-aqua);
}

.contact-form-section__wrap {
  display: flex;
  justify-content: center;
}

@media (max-width: 992px) {
  .contact-form-section__wrap {
    flex-direction: column;
  }
}
.contact-form-section__text {
  background-color: var(--cl-navy);
  border-radius: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  padding-left: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
  padding-right: clamp(24px, 24px + 56 * (100vw - 768px) / 672, 80px);
  min-height: 660px;
  max-width: 653px;
  margin-left: clamp(20px, 20px + 43 * (100vw - 992px) / 448, 63px);
  min-width: clamp(410px, 410px + 240 * (100vw - 992px) / 448, 650px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 992px) {
  .contact-form-section__text {
    margin-left: 0;
    max-width: 800px;
    min-height: 410px;
    min-width: auto;
    margin-bottom: clamp(24px, 24px + 18 * (100vw - 768px) / 672, 42px);
  }
}
.contact-form-section__title {
  color: #F2F1F1;
  margin-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.contact-form-section__line {
  margin-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.contact-form-section__descr {
  color: #F2F1F1;
  margin-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.contact-form-section__toggle {
  border-radius: 12px;
  padding: 4px;
  border: 1px solid var(--cl-aqua);
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.contact-form-section__toggle-bg {
  content: "";
  position: absolute;
  width: calc(50% - 8px);
  height: calc(100% - 8px);
  top: 4px;
  left: 4px;
  background-color: var(--cl-aqua);
  border-radius: 8px;
  z-index: 0;
  transition: 0.3s all;
}

.contact-form-section__toggle-bg-2 {
  left: 51%;
}

.contact-form-section__toggle-btn {
  font-size: 18px;
  cursor: pointer;
  height: 48px;
  color: #F2F1F1;
  display: flex;
  align-items: center;
  padding: 0 clamp(8px, 8px + 16 * (100vw - 768px) / 672, 24px);
  z-index: 1;
  transition: 0.3s all;
}

.contact-form-section__toggle-btn:not(.contact-form-section__toggle-btn--active):hover {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 576px) {
  .contact-form-section__toggle-btn {
    font-size: 16px;
  }
}
.contact-form-section__toggle-btn--active {
  color: var(--cl-charcoal);
}

.contact-form-section__form {
  background-color: var(--cl-charcoal);
  border-radius: 24px;
  border: 1px solid var(--cl-aqua);
  padding: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px) clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  padding-top: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  max-width: 675px;
  width: 100%;
  min-width: clamp(500px, 500px + 175 * (100vw - 992px) / 448, 675px);
  transform: translate(clamp(-48px, -16px + -32 * (100vw - 992px) / 448, -16px), clamp(24px, 24px + 24 * (100vw - 992px) / 448, 48px));
  display: none;
}

@media (max-width: 992px) {
  .contact-form-section__form {
    transform: translate(0);
    max-width: 800px;
    min-width: auto;
  }
}
.contact-form-section__form--active {
  display: block;
}

.contact-form-section__form-title {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: #FFF;
}

.contact-form-section__form-title .heading-5 {
  font-weight: 700;
}

.contact-form-section__form-row {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .contact-form-section__form-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
.contact-form-section__form-row .meta-input {
  max-width: 600px;
  width: 100%;
}

.contact-form-section__form-row .meta-input:not(:last-child) {
  margin-right: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
}

@media (max-width: 576px) {
  .contact-form-section__form-row .meta-input {
    margin-right: 0 !important;
  }
}
.contact-form-section__form-row .meta-select {
  margin-right: 16px;
  max-width: 650px;
  width: 100%;
}

@media (max-width: 576px) {
  .contact-form-section__form-row .meta-select {
    margin-right: 0 !important;
  }
}
.contact-form-section__form-cv {
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 576px) {
  .contact-form-section__form-cv {
    margin-bottom: 16px;
    max-width: none;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.contact-form-section__form-cv__text {
  font-size: 12px;
  color: #D0D1D3;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 576px) {
  .contact-form-section__form-cv__text {
    text-align: left;
  }
}
.contact-form-section__form-cv input {
  display: none;
}

.contact-form-section__form-cv__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  transition: 0.3s all;
}

@media (max-width: 576px) {
  .contact-form-section__form-cv__btn {
    justify-content: flex-start;
  }
}
.contact-form-section__form-cv__btn i {
  margin-left: 8px;
  font-size: 24px;
  transition: 0.3s all;
}

.contact-form-section__form-cv__btn:hover {
  color: rgba(255, 255, 255, 0.8);
}

.contact-form-section__form-cv__btn:hover i {
  color: rgba(255, 255, 255, 0.8);
}

.contact-form-section__form .meta-input {
  margin-bottom: 16px;
}

.contact-form-section__form .meta-input input, .contact-form-section__form .meta-input textarea {
  background-color: transparent;
  color: #FFF;
  border: 1px solid var(--cl-aqua);
  border-radius: 8px;
  clip-path: none;
}

.contact-form-section__form .meta-input input::-moz-placeholder, .contact-form-section__form .meta-input textarea::-moz-placeholder {
  color: #FFF;
}

.contact-form-section__form .meta-input input::placeholder, .contact-form-section__form .meta-input textarea::placeholder {
  color: #FFF;
}

.contact-form-section__form .meta-input input:focus, .contact-form-section__form .meta-input textarea:focus {
  caret-color: auto;
}

.contact-form-section__form .meta-select {
  margin-bottom: 16px;
}

.contact-form-section__form .meta-select .choices__inner {
  background-color: transparent;
}

.contact-form-section__form .meta-select .choices__list--single {
  background-color: transparent;
}

.contact-form-section__form .meta-select .choices__placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.contact-form-section__form .meta-select .choices:after {
  border-color: #FFF;
}

.contact-form-section__form .meta-select .choices__list {
  background-color: var(--cl-charcoal);
}

.contact-form-section__form .meta-select .choices__list .choices__item {
  color: rgba(255, 255, 255, 0.8);
}

.contact-form-section__form .meta-select .choices__list .choices__item:hover {
  color: rgba(255, 255, 255, 0.8);
}

.contact-form-section__form .meta-select .choices__list--dropdown .choices__item {
  color: #FFF;
}

.contact-form-section__form .meta-select .choices__list .is-highlighted {
  color: rgba(255, 255, 255, 0.8) !important;
}

.contact-form-section__form label.contact-form-section__form-name {
  padding-bottom: clamp(5px, 5px + 3 * (100vw - 768px) / 672, 8px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: white;
}

.contact-form-section__form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

@media (max-width: 576px) {
  .contact-form-section__form-bottom {
    flex-wrap: wrap;
    row-gap: 12px;
  }
}
.contact-form-section__form .meta-checkbox__box {
  border-color: #D0D1D3;
}

.contact-form-section__form .meta-checkbox__box .check-clip {
  width: 65%;
  height: 65%;
}

.contact-form-section__form .meta-checkbox input:checked ~ .meta-checkbox__box {
  background-color: var(--cl-aqua);
  border-color: #D0D1D3;
}

.contact-form-section__form .meta-checkbox__text {
  color: #D0D1D3;
  font-size: 14px;
  line-height: 1.4;
}

.contact-form-section__form .meta-checkbox__text a {
  color: #FFF;
  display: inline;
  text-decoration: none;
  position: relative;
}

.contact-form-section__form .meta-checkbox__text a:hover:before {
  background-color: var(--cl-navy);
  height: 50%;
}

.contact-form-section__form .meta-checkbox__text a:before {
  background: var(--cl-white);
  content: "";
  z-index: -1;
  height: 1px;
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.our-location-section {
  background-color: #F5F5F5;
  padding: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px) 0;
}

.our-location-section__nav {
  margin-bottom: clamp(34px, 34px + 22 * (100vw - 768px) / 672, 56px);
}

.our-location-section__title {
  margin-bottom: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
  color: var(--cl-charocal);
}

.our-location-section__title span:not(.word) {
  color: var(--cl-navy);
}

.our-location-section__tabs {
  max-width: 650px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.our-location-section__tab {
  border: 2px solid var(--cl-aqua);
  border-radius: 12px;
  padding: 13px 16px;
  color: var(--cl-navy);
  font-weight: 700;
  background-color: #FFF;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.35s all ease;
}

.our-location-section__tab i {
  color: var(--cl-aqua);
  margin-right: 6px;
  font-size: 24px;
  transition: 0.2s all;
}

.our-location-section__tab--active {
  color: var(--cl-charocal);
  background-color: var(--cl-aqua);
}

.our-location-section__tab--active i {
  color: var(--cl-charocal);
}

.our-location-section__tab:hover {
  color: var(--cl-charocal);
  background-color: var(--cl-aqua);
}

.our-location-section__tab:hover i {
  color: var(--cl-charocal);
}

.our-location-section__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .our-location-section__wrap {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.our-location-section__content {
  display: none;
}

.our-location-section__content--active {
  display: flex;
}

.our-location-section__map {
  margin-right: clamp(14px, 14px + 18 * (100vw - 768px) / 672, 32px);
  border-radius: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  overflow: hidden;
  border: 1px solid rgba(53, 53, 63, 0.3);
  min-width: clamp(500px, 500px + 210 * (100vw - 992px) / 448, 710px);
}

.our-location-section__map .gm-style iframe + div {
  border-color: transparent !important;
}

.our-location-section__map .gm-style div {
  border: none !important;
}

@media (max-width: 992px) {
  .our-location-section__map {
    margin-top: clamp(24px, 24px + 18 * (100vw - 768px) / 672, 42px);
    width: 100%;
    max-width: 710px;
    min-width: auto;
  }
}
.our-location-section__map iframe {
  width: clamp(499px, 499px + 211 * (100vw - 992px) / 448, 710px);
  height: clamp(380px, 380px + 93 * (100vw - 992px) / 448, 473px);
  border-radius: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.our-location-section__map iframe div {
  border: none;
}

@media (max-width: 992px) {
  .our-location-section__map iframe {
    width: 100%;
    height: clamp(320px, 320px + 60 * (100vw - 576px) / 864, 380px);
  }
}
.our-location-section__text {
  max-width: clamp(360px, 360px + 165 * (100vw - 768px) / 672, 525px);
}

.our-location-section__text-title {
  margin-bottom: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 700;
  color: var(--cl-navy);
}

.our-location-section__contacts {
  margin-top: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.our-location-section__contact {
  display: flex;
  align-items: center;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: var(--cl-navy);
  transition: 0.3s all;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
}

.our-location-section__contact span {
  text-decoration: underline;
  text-decoration-color: var(--cl-navy);
}

.our-location-section__contact:not(:last-child) {
  margin-bottom: 8px;
}

.our-location-section__contact i {
  margin-right: clamp(8px, 8px + 4 * (100vw - 768px) / 672, 12px);
  color: var(--cl-navy);
  font-size: 32px;
  transition: 0.2s all;
}

.our-location-section__contact:hover {
  color: var(--cl-navy-80);
}

.our-location-section__contact:hover i {
  color: var(--cl-navy-80);
}

.profile {
  margin-top: clamp(92px, 92px + 44 * (100vw - 768px) / 672, 136px);
  border-top: 1px solid var(--cl-aqua);
}

.profile__wrap {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  /* min-height: 100vh; */
}

.profile__aside {
  width: 28.91%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border-right: 1px solid var(--cl-aqua);
  padding-right: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.profile__aside-content {
  position: sticky;
  top: 32px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: clamp(60px, 60px + 20 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(40px, 40px + 24 * (100vw - 768px) / 672, 64px);
}

.profile__client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  --size-client-image: clamp(72px, calc(72px + 16.00 * ((100vw - 768px) / 672)), 88px);
  grid-column-gap: 16px;
  width: 100%;
}

.profile__client:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.profile__client-image {
  width: var(--size-client-image);
  height: var(--size-client-image);
  border: 1px solid var(--cl-aqua);
  border-radius: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  overflow: hidden;
  isolation: isolate;
}

.profile__client-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile__client-info {
  width: calc(100% - var(--size-client-image) - 16px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-row-gap: 12px;
}

.profile__client-title {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 700;
  color: var(--cl-primary);
  line-height: 1.32;
}

.profile__client-name {
  font-size: clamp(22px, 22px + 8 * (100vw - 768px) / 672, 30px);
  line-height: 1.28;
  font-weight: 500;
}

.profile__aside-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}

.profile__aside-item {
  border: 1px solid transparent;
  border-radius: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  padding: clamp(14px, 14px + 1 * (100vw - 768px) / 672, 15px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  grid-column-gap: 8px;
}

.profile__aside-item.active {
  border-color: var(--cl-primary);
}

.profile__aside-item.active .profile__aside-item-text {
  color: var(--cl-primary);
}

.profile__aside-item.active .profile__aside-item-icon i {
  color: var(--cl-primary);
}

.profile__aside-item:hover {
  border-color: var(--cl-aqua);
}

.profile__aside-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(22px, 22px + 2 * (100vw - 768px) / 672, 24px);
}

.profile__aside-item-text {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.2;
  transition: 0.3s;
}

.profile__main {
  width: 66.09%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: clamp(40px, 40px + 24 * (100vw - 768px) / 672, 64px);
  padding-bottom: clamp(40px, 40px + 24 * (100vw - 768px) / 672, 64px);
}

.profile__main--cv {
  padding-bottom: clamp(46px, 46px + 336 * (100vw - 768px) / 672, 382px);
}

.profile__title {
  font-size: clamp(26px, 26px + 26 * (100vw - 768px) / 672, 52px);
  font-weight: 700;
  line-height: 1.2;
  color: var(--cl-primary);
}

@media (min-width: 1024px) {
  .profile__title {
    max-width: calc(100% - clamp(280px, 280px + 20 * (100vw - 768px) / 672, 300px));
  }
}
.profile__title:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
}

.profile__subtitle {
  font-size: clamp(22px, 22px + 8 * (100vw - 768px) / 672, 30px);
  font-weight: 700;
  line-height: 1.28;
}

.profile__subtitle:not(:last-child) {
  margin-bottom: 12px;
}

.profile__text {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.2;
}

.profile__text:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.profile__sort {
  position: relative;
  z-index: 10;
}

@media (min-width: 1024px) {
  .profile__sort {
    width: clamp(260px, 260px + 20 * (100vw - 768px) / 672, 280px);
    position: absolute;
    right: 0;
    top: clamp(-56px, -30px + -26 * (100vw - 768px) / 672, -30px);
    transform: translateY(-100%);
  }
}
@media (max-width: 1023px) {
  .profile__sort {
    margin-bottom: 24px;
  }
}
.profile__main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.profile__main-content:not(:last-child) {
  margin-bottom: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
}

.profile__main-buttons {
  width: 100%;
}

@media (min-width: 641px) {
  .profile__main-buttons {
    justify-content: flex-end;
  }
}
.profile__main-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile__main-item:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.profile__main-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.profile__main-result .meta-select .choices__list div {
  color: var(--cl-charcoal) !important;
}

.profile__main-result .meta-select .choices__list div:hover {
  opacity: 0.8;
}

.profile__main-result .meta-select .choices__list--dropdown {
  border-color: rgba(53, 53, 63, 0.3019607843) !important;
}

@media (max-width: 576px) {
  .profile__main-result .meta-select .choices__inner {
    min-width: 200px;
  }
}
.profile__main-result-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  grid-row-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  width: 100%;
}

.profile__main-result-grid:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

@media (max-width: 551px) {
  .profile__main-result-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
/* border: 1px solid var(--cl-aqua); */
.profile__main-result-grid .job-card:hover:after {
  height: 0px;
}

.profile__more-btn {
  margin-left: auto;
  margin-right: auto;
}

.profile__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile__form .meta-input label, .profile__form .meta-select label {
  font-weight: 400;
}

.profile__form .meta-input span, .profile__form .meta-select span {
  transform: translateY(-4px);
}

.profile__form .meta-input #repeat-password, .profile__form .meta-input #new-password, .profile__form .meta-select #repeat-password, .profile__form .meta-select #new-password {
  padding-right: 45px;
}

.profile__checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.profile__checkboxes li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.profile__checkboxes li .meta-checkbox i.icon-check {
  margin-top: 1px;
  color: var(--cl-charcoal);
}

.profile__checkboxes li:hover .meta-checkbox__text {
  color: var(--cl-navy);
}

.profile__checkboxes li:hover .meta-checkbox__box {
  border-color: var(--cl-navy);
}

.profile__checkboxes:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.profile__form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 425px) {
  .profile__form-buttons {
    width: 100%;
  }
}
.profile__form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.profile__form-item .profile__subtitle {
  color: var(--cl-primary);
  overflow: hidden;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
}

.profile__form-item .profile__subtitle span {
  position: relative;
}

.profile__form-item .profile__subtitle span:before {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%) translateX(100%);
  height: 1px;
  background: #e6e6e6;
  content: "";
  width: 100vw;
}

.profile__form-item .profile__subtitle:not(:last-child) {
  margin-bottom: clamp(22px, 22px + 10 * (100vw - 768px) / 672, 32px);
}

.profile__form-item:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

.profile__form-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  width: 100%;
}

@media (min-width: 952px) {
  .profile__form-grid.with-date-type {
    grid-template-columns: repeat(8, 1fr);
    /* .div1 {
      grid-area: 1 / 1 / 2 / 4;
    }
    .div2 {
      grid-area: 1 / 4 / 2 / 7;
    }
    .div3 {
      grid-area: 1 / 7 / 2 / 9;
    }*/
    /*.div4 {
      grid-area: 2 / 1 / 3 / 5;
    }
    .div5 {
      grid-area: 2 / 5 / 3 / 9;
    }*/
  }
  .profile__form-grid.with-date-type > * {
    /*&:nth-child(2n - 1) {
      grid-area: 1 / 4 / 2 / 7;
    }

    &:nth-child(2n) {
      grid-area: 1 / 7 / 2 / 9;
    }*/
  }
  .profile__form-grid.with-date-type > *:nth-child(1) {
    grid-area: 1/1/2/4;
  }
  .profile__form-grid.with-date-type > *:nth-child(2) {
    grid-area: 1/4/2/7;
  }
  .profile__form-grid.with-date-type > *:nth-child(3) {
    grid-area: 1/7/2/9;
  }
  .profile__form-grid.with-date-type > *:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    grid-column: span 4/span 4;
  }
}
@media (max-width: 951px) {
  .profile__form-grid.with-date-type > *:nth-child(2n-1):last-child {
    grid-column: 1/-1;
  }
}
@media (max-width: 551px) {
  .profile__form-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.profile__upload-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (max-width: 576px) {
  .profile__upload-form {
    flex-wrap: wrap;
  }
}
.profile__upload-form .text-btn {
  margin-left: clamp(0px, 0px + 24 * (100vw - 768px) / 672, 24px);
}

.profile__upload-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  --size-client-image: clamp(88px, calc(88px + 24.00 * ((100vw - 768px) / 672)), 112px);
}

.profile__upload-image .meta-upload-file label {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.profile__upload-image .profile__client-image {
  cursor: pointer;
  overflow: hidden;
  background: var(--cl-grey);
}

.profile__upload-image .profile__client-image img {
  transition: 0.3s;
}

.profile__upload-image .profile__client-image:hover img {
  transform: scale(1.1);
}

@media (max-width: 1100px) {
  .profile .profile__aside {
    width: 32%;
  }
  .profile .profile__main {
    width: 64%;
  }
}
@media (max-width: 960px) {
  .profile .profile__aside {
    width: 35%;
  }
  .profile .profile__main {
    width: 62%;
  }
}
@media (max-width: 851px) {
  .profile .profile__aside {
    width: 38%;
  }
  .profile .profile__main {
    width: 60%;
  }
}
@media (min-width: 769px) {
  .profile .profile__filters-btn {
    display: none;
  }
}
@media (max-width: 768px) {
  .profile .profile {
    overflow: hidden;
  }
  .profile .profile__filters-btn {
    margin-top: 30px;
    margin-bottom: 24px;
  }
  .profile .profile__wrap {
    position: relative;
    min-height: auto;
  }
  .profile .profile__wrap:after {
    top: -1px;
    height: 1px;
    background: var(--cl-aqua);
    content: "";
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
  .profile .profile__wrap.active .profile__aside {
    transform: translateX(0);
    position: relative;
    pointer-events: auto;
    opacity: 1;
  }
  .profile .profile__wrap.active .profile__main {
    transform: translateX(calc(100% - 320px));
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.3;
    pointer-events: none;
  }
}
@media (max-width: 768px) and (max-width: 425px) {
  .profile .profile__wrap.active .profile__main {
    transform: translateX(100%);
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .profile .profile__aside {
    width: 100%;
    max-width: 340px;
    position: absolute;
    left: 0;
    z-index: 10;
    top: 0;
    background: var(--cl-white);
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
}
@media (max-width: 768px) and (max-width: 425px) {
  .profile .profile__aside {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .profile .profile__main {
    width: 100%;
    padding-top: 24px;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
  .profile .profile__aside-content {
    padding-top: 24px;
  }
}
.job-info-section {
  margin-top: clamp(136px, 140px + -4 * (100vw - 768px) / 672, 140px);
  overflow: hidden;
}

.job-info-section__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.job-info-section__content {
  margin-bottom: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--cl-navy);
  border-radius: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
  padding-left: clamp(20px, 20px + 44 * (100vw - 768px) / 672, 64px);
  padding-top: clamp(40px, 40px + 70 * (100vw - 768px) / 672, 110px);
  padding-bottom: clamp(40px, 40px + 70 * (100vw - 768px) / 672, 110px);
  padding-right: clamp(64px, 64px + 16 * (100vw - 768px) / 672, 80px);
  color: var(--cl-white);
  width: 55.47%;
}

.job-info-section__subtitle {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  color: var(--cl-aqua);
  font-weight: 700;
  line-height: 1.32;
}

.job-info-section__subtitle:not(:last-child) {
  margin-bottom: 16px;
}

.job-info-section__title {
  color: var(--cl-light-bg);
}

.job-info-section__title:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 12 * (100vw - 768px) / 672, 42px);
}

.job-info-section__list {
  margin-top: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--cl-charcoal);
  border-radius: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
  padding-left: clamp(20px, 20px + 60 * (100vw - 768px) / 672, 80px);
  padding-top: clamp(40px, 40px + 52 * (100vw - 768px) / 672, 92px);
  padding-bottom: clamp(40px, 40px + 52 * (100vw - 768px) / 672, 92px);
  padding-right: clamp(20px, 20px + 60 * (100vw - 768px) / 672, 80px);
  color: var(--cl-white);
  width: calc(44.53% + clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px));
  margin-left: clamp(-56px, -20px + -36 * (100vw - 768px) / 672, -20px);
  position: relative;
  z-index: 10;
}

.job-info-section__list-item {
  padding: clamp(10px, 10px + 4 * (100vw - 768px) / 672, 14px) 0;
  width: 100%;
  font-weight: 500;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  line-height: 1.32;
}

.job-info-section__list-item:not(:last-child) {
  border-bottom: 1px solid var(--cl-aqua);
}

.job-info-section__list-item:first-child {
  padding-top: 0;
}

.job-info-section__list-item:last-child {
  padding-bottom: 0;
}

@media (max-width: 640px) {
  .job-info-section .job-info-section__wrap {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .job-info-section .job-info-section__content {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 50px;
    padding-right: 20px;
  }
  .job-info-section .job-info-section__list {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin-top: -18px;
    margin-left: 0;
    margin-bottom: 0;
  }
}
.job-details-section {
  padding-top: clamp(40px, 40px + 54 * (100vw - 768px) / 672, 94px);
  padding-bottom: clamp(40px, 40px + 68 * (100vw - 768px) / 672, 108px);
}

.job-details-section__wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .job-details-section__wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.job-details-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 69.92%;
}

@media (max-width: 1100px) {
  .job-details-section__content {
    width: 62%;
  }
}
@media (max-width: 640px) {
  .job-details-section__content {
    width: 100%;
  }
  .job-details-section__content:not(:last-child) {
    margin-bottom: 30px;
  }
}
.job-details-section__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.job-details-section__text:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 34 * (100vw - 768px) / 672, 64px);
}

.job-details-section__text ul li::before {
  /* background-color: #0035EC; */
}

.job-details-section__text p:last-child {
  margin-top: clamp(15px, 15px + 15 * (100vw - 768px) / 672, 30px);
}

.job-details-section__buttons .text-btn {
  margin-right: clamp(10px, 10px + 14 * (100vw - 768px) / 672, 24px);
}

.job-details-section__buttons:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 30 * (100vw - 768px) / 672, 60px);
}

.job-details-section__share {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.job-details-section__share-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}

.job-details-section__share-list {
  padding-left: clamp(0px, 0px + 8 * (100vw - 768px) / 672, 8px);
  grid-column-gap: clamp(16px, 16px + 3 * (100vw - 768px) / 672, 19px);
}

.job-details-section__share-list .social-link {
  font-size: 26px;
}

.job-details-section__info {
  position: sticky;
  top: clamp(86px, 86px + 24 * (100vw - 768px) / 672, 110px);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 23.83%;
}

@media (max-width: 1100px) {
  .job-details-section__info {
    width: 30%;
  }
}
@media (max-width: 851px) {
  .job-details-section__info {
    width: 34%;
  }
}
@media (max-width: 640px) {
  .job-details-section__info {
    width: 100%;
    inset: auto;
    position: relative;
  }
}
.job-details-section__info-title {
  font-size: clamp(28px, 28px + 8 * (100vw - 768px) / 672, 36px);
  line-height: 133.33%;
  color: var(--cl-navy);
  font-weight: 700;
}

.job-details-section__info-title:not(:last-child) {
  margin-bottom: clamp(22px, 22px + 2 * (100vw - 768px) / 672, 24px);
}

.job-details-section__info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid var(--cl-aqua);
  border-radius: 12px;
  padding: 24px 20px clamp(24px, 24px + 9 * (100vw - 768px) / 672, 33px);
}

.job-details-section__info-block:not(:last-child) {
  margin-bottom: 24px;
}

.job-details-section__info-name {
  font-size: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  line-height: 128.33%;
  font-weight: 500;
  text-align: center;
}

.job-details-section__info-name:not(:last-child) {
  margin-bottom: 8px;
}

.job-details-section__info-position {
  font-size: 18px;
  line-height: 1.2;
  color: var(--cl-navy);
  text-align: center;
}

.job-details-section__info-position:not(:last-child) {
  margin-bottom: 16px;
}

.job-details-section__info-socials {
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  position: relative;
  grid-column-gap: clamp(16px, 16px + 3 * (100vw - 768px) / 672, 19px);
}

.job-details-section__info-socials .social-link {
  font-size: 26px;
}

.job-details-section__info-socials .social-link i.icon-mail {
  font-size: 28px;
}

.job-details-section__info-socials:after {
  top: 0;
  left: 50%;
  height: 1px;
  background: var(--cl-aqua);
  width: 142px;
  content: "";
  position: absolute;
  transform: translateX(-50%);
}

.resources-info-section {
  margin-top: clamp(136px, 140px + -4 * (100vw - 768px) / 672, 140px);
  overflow: hidden;
}

.resources-info-section__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.resources-info-section__content {
  margin-bottom: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid var(--cl-aqua);
  background: var(--cl-white);
  border-radius: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
  padding-left: clamp(20px, 20px + 43 * (100vw - 768px) / 672, 63px);
  padding-top: clamp(40px, 40px + 101 * (100vw - 768px) / 672, 141px);
  padding-bottom: clamp(40px, 40px + 101 * (100vw - 768px) / 672, 141px);
  padding-right: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
  width: 55.47%;
}

.resources-info-section__subtitle {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  color: var(--cl-charcoal);
  font-weight: 700;
  line-height: 1.32;
}

.resources-info-section__subtitle:not(:last-child) {
  margin-bottom: 16px;
}

.resources-info-section__title {
  color: var(--cl-navy);
}

.resources-info-section__title.heading-5 {
  line-height: 1.2;
  letter-spacing: 0;
  font-weight: 700;
}

.resources-info-section__title:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 17 * (100vw - 768px) / 672, 47px);
}

.resources-info-section__buttons .text-btn {
  margin-right: clamp(6px, 6px + 10 * (100vw - 768px) / 672, 16px);
}

.resources-info-section__buttons .text-btn .btn__text {
  text-wrap: nowrap;
}

.resources-info-section__buttons .text-btn .text-btn__icon i {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.resources-info-section__image {
  margin-top: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  width: calc(44.53% + clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px));
  margin-left: clamp(-56px, -20px + -36 * (100vw - 768px) / 672, -20px);
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
}

@media (max-width: 640px) {
  .resources-info-section .resources-info-section__wrap {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .resources-info-section .resources-info-section__content {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 50px;
    padding-right: 20px;
  }
  .resources-info-section .resources-info-section__image {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin-top: -18px;
    margin-left: 0;
    margin-bottom: 0;
  }
}
.resources-details-section {
  padding-top: clamp(40px, 40px + 54 * (100vw - 768px) / 672, 94px);
  padding-bottom: clamp(40px, 40px + 68 * (100vw - 768px) / 672, 108px);
}

.resources-details-section__wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .resources-details-section__wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.resources-details-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 69.92%;
}

@media (max-width: 1100px) {
  .resources-details-section__content {
    width: 62%;
  }
}
@media (max-width: 640px) {
  .resources-details-section__content {
    width: 100%;
  }
  .resources-details-section__content:not(:last-child) {
    margin-bottom: 30px;
  }
}
.resources-details-section__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.resources-details-section__text:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 34 * (100vw - 768px) / 672, 64px);
}

.resources-details-section__buttons .text-btn {
  margin-right: clamp(10px, 10px + 14 * (100vw - 768px) / 672, 24px);
}

.resources-details-section__buttons .text-btn .text-btn__icon i {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.resources-details-section__buttons:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 30 * (100vw - 768px) / 672, 60px);
}

.resources-details-section__share {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.resources-details-section__share-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}

.resources-details-section__share-list {
  padding-left: clamp(0px, 0px + 8 * (100vw - 768px) / 672, 8px);
  grid-column-gap: clamp(16px, 16px + 3 * (100vw - 768px) / 672, 19px);
}

.resources-details-section__share-list .social-link {
  font-size: 26px;
}

.resources-details-section__info {
  position: sticky;
  top: clamp(86px, 86px + 24 * (100vw - 768px) / 672, 110px);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 23.83%;
}

@media (max-width: 1100px) {
  .resources-details-section__info {
    width: 30%;
  }
}
@media (max-width: 851px) {
  .resources-details-section__info {
    width: 34%;
  }
}
@media (max-width: 640px) {
  .resources-details-section__info {
    width: 100%;
    inset: auto;
    position: relative;
  }
}
.resources-details-section__info-title {
  font-size: clamp(28px, 28px + 8 * (100vw - 768px) / 672, 36px);
  line-height: 133.33%;
  color: var(--cl-navy);
  font-weight: 700;
}

.resources-details-section__info-title:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 11 * (100vw - 768px) / 672, 35px);
}

.resources-details-section__info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid var(--cl-aqua);
  border-radius: 12px;
  padding: 0 0 clamp(24px, 24px + 9 * (100vw - 768px) / 672, 33px);
  overflow: hidden;
}

.resources-details-section__info-block:not(:last-child) {
  margin-bottom: 24px;
}

.resources-details-section__info-image {
  width: 100%;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

.resources-details-section__info-image:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.resources-details-section__info-name {
  font-size: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
  line-height: 128.33%;
  font-weight: 500;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.resources-details-section__info-name:not(:last-child) {
  margin-bottom: 8px;
}

.resources-details-section__info-position {
  font-size: 18px;
  line-height: 1.2;
  color: var(--cl-navy);
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.resources-details-section__info-position:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 5 * (100vw - 768px) / 672, 21px);
}

.resources-details-section__info-socials {
  padding-top: clamp(20px, 20px + 11 * (100vw - 768px) / 672, 31px);
  position: relative;
  grid-column-gap: clamp(16px, 16px + 3 * (100vw - 768px) / 672, 19px);
  margin-left: 20px;
  margin-right: 20px;
}

.resources-details-section__info-socials .social-link {
  font-size: 26px;
}

.resources-details-section__info-socials:after {
  top: 0;
  left: 50%;
  height: 1px;
  background: var(--cl-aqua);
  width: 142px;
  content: "";
  position: absolute;
  transform: translateX(-50%);
}

.login-section {
  margin-top: clamp(92px, 92px + 44 * (100vw - 768px) / 672, 136px);
  padding-top: clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  position: static;
}

.login-section__circle {
  position: absolute;
  bottom: clamp(-1122px, -542px + -580 * (100vw - 768px) / 672, -542px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 520px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 680px) {
  .login-section__circle {
    bottom: clamp(-340px, -220px + -120 * (100vw - 320px) / 360, -220px);
  }
}
.login-section__wrap {
  display: flex;
  justify-content: center;
}

.login-section__title {
  color: var(--cl-navy);
  display: flex;
  justify-content: center;
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

.login-section__form {
  border: 1px solid var(--cl-aqua);
  padding: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  border-radius: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  background-color: #FFF;
  position: relative;
  z-index: 6;
}

.login-section__form .meta-input {
  margin-bottom: 16px;
}

.login-section__form .meta-input input {
  color: var(--cl-charcoal);
}

.login-section__form .meta-input input::-moz-placeholder {
  color: rgba(53, 53, 63, 0.7);
}

.login-section__form .meta-input input::placeholder {
  color: rgba(53, 53, 63, 0.7);
}

.login-section__form .meta-input label {
  font-weight: 400;
}

.login-section__form-btn {
  margin-bottom: 16px;
  width: 100%;
}

.login-section__form-switch {
  font-size: 14px;
  line-height: 1.4;
  color: rgba(53, 53, 63, 0.8);
  display: flex;
  justify-content: center;
}

.login-section__form-switch a {
  color: var(--cl-navy);
  text-decoration: underline;
  display: inline;
  margin-left: 2px;
}

.login-section__form-switch a:hover {
  text-decoration: none;
}

.login-section__input-password input {
  padding-right: 50px !important;
}

.login-section__input-wrap {
  position: relative;
  width: 100%;
}

.login-section__input-icon {
  position: absolute;
  right: 17px;
  top: 52%;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-section__input-icon i {
  font-size: 20px;
  color: var(--cl-charcoal);
}

.sign-up-section__form {
  max-width: 414px;
  width: 100%;
}

.sign-up-section__form-forgot {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  font-size: 14px;
  color: var(--cl-navy);
  text-decoration: underline;
  line-height: 1.4;
  width: -moz-fit-content;
  width: fit-content;
}

.sign-in-section__form {
  max-width: 630px;
  width: 100%;
}

.sign-in-section__form-row {
  display: flex;
}

@media (max-width: 660px) {
  .sign-in-section__form-row {
    flex-direction: column;
  }
}
.sign-in-section__form-row .meta-input {
  max-width: 600px;
  width: 100%;
}

.sign-in-section__form-row .meta-input:not(:last-child) {
  margin-right: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
}

@media (max-width: 660px) {
  .sign-in-section__form-row .meta-input {
    margin-right: 0 !important;
  }
}
.sign-in-section__form-btn {
  margin-top: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.bulettins-slider {
  background: #F5F5F5;
  padding-top: clamp(62px, 62px + 58 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(72px, 72px + 48 * (100vw - 768px) / 672, 120px);
}

.bulettins-slider__wrap {
  margin-top: clamp(-160px, -100px + -60 * (100vw - 768px) / 672, -100px);
  z-index: 18;
  position: relative;
}

@media (max-width: 935px) {
  .bulettins-slider__wrap {
    flex-direction: column;
    border-left: none;
  }
}
.bulettins-slider__slider {
  position: relative;
}

.bulettins-slider__card {
  background: #FFFFFF;
  display: flex;
  align-items: stretch;
  border-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  border: 1px solid var(--cl-aqua);
  overflow: hidden;
}

@media (max-width: 764px) {
  .bulettins-slider__card {
    flex-direction: column;
  }
}
.bulettins-slider__card-text {
  padding-top: clamp(18px, 18px + 38 * (100vw - 768px) / 672, 56px);
  padding-right: clamp(18px, 18px + 38 * (100vw - 768px) / 672, 56px);
  padding-left: clamp(18px, 18px + 24 * (100vw - 768px) / 672, 42px);
  padding-bottom: clamp(18px, 18px + 30 * (100vw - 768px) / 672, 48px);
  border-left: clamp(10px, 10px + 4 * (100vw - 764px) / 676, 14px) solid var(--cl-aqua);
  border-top-left-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  border-bottom-left-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  max-width: clamp(700px, 700px + 66 * (100vw - 900px) / 540, 766px);
  margin-left: clamp(-13px, -10px + -3 * (100vw - 764px) / 676, -10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 764px) {
  .bulettins-slider__card-text {
    border-left: none;
    margin-left: 0;
    border-radius: 0;
  }
}
.bulettins-slider__card-image {
  min-width: clamp(300px, 300px + 150 * (100vw - 764px) / 536, 450px);
}

.bulettins-slider__card-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.bulettins-slider__card-title {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
  color: var(--cl-navy);
  font-weight: 700;
  --max-line-num: 4;
}

.bulettins-slider__card-descr {
  color: var(--cl-charocal);
  --max-line-num: 5;
  margin-bottom: clamp(18px, 18px + 18 * (100vw - 768px) / 672, 36px);
}

.bulettins-slider__btn {
  border: 1px solid #010F93;
  width: -moz-fit-content;
  width: fit-content;
}

.bulettins-slider__btn .btn__text, .bulettins-slider__btn .btn__icon {
  color: var(--cl-navy);
}

.bulettins-slider__btn:hover {
  background-color: transparent;
}

.bulettins-slider__arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: absolute;
  right: clamp(12px, 12px + 44 * (100vw - 768px) / 672, 56px);
  bottom: clamp(18px, 18px + 30 * (100vw - 768px) / 672, 48px);
  gap: clamp(6px, 6px + 10 * (100vw - 768px) / 672, 16px);
}

.bulettins-slider__arrows .swiper-button {
  margin-top: 0;
}

.bulettins-slider__arrows .swiper-button::before {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.bulettins-slider__pagination {
  position: relative;
  inset: auto;
  width: auto;
}

@media (max-width: 576px) {
  .bulettins-slider__pagination {
    display: none;
  }
}
.search-bulletins-section {
  padding-top: clamp(64px, 64px + 46 * (100vw - 768px) / 672, 110px);
  padding-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
}

.search-bulletins-section__title {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

.search-bulletins-section__title span:not(.word) {
  color: var(--cl-navy);
}

.search-bulletins-section__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
}

@media (max-width: 992px) {
  .search-bulletins-section__nav {
    flex-wrap: wrap;
    row-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  }
}
.search-bulletins-section__search {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: clamp(8px, 8px + 4 * (100vw - 768px) / 672, 12px);
}

.search-bulletins-section__search .meta-input {
  max-width: clamp(352px, 352px + 210 * (100vw - 992px) / 448, 562px);
  width: 100%;
}

@media (max-width: 992px) {
  .search-bulletins-section__search .meta-input {
    max-width: 768px;
  }
}
.search-bulletins-section__search .meta-input input {
  border-radius: 100px;
}

.search-bulletins-section__search .meta-input input::-webkit-search-decoration, .search-bulletins-section__search .meta-input input::-webkit-search-cancel-button, .search-bulletins-section__search .meta-input input::-webkit-search-results-button, .search-bulletins-section__search .meta-input input::-webkit-search-results-decoration, .search-bulletins-section__search .meta-input input::-ms-clear {
  cursor: pointer;
}

.search-bulletins-section__submit {
  background-color: var(--cl-aqua);
  margin-left: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  transition: 0.3s all;
  min-width: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
}

.search-bulletins-section__submit:hover {
  background-color: var(--cl-navy);
}

.search-bulletins-section__submit:hover .btn__icon i {
  transform: rotate(0);
  color: var(--cl-white);
}

.search-bulletins-section__sort:not(:last-child) {
  margin-right: clamp(10px, 10px + 10 * (100vw - 768px) / 672, 20px);
}

.search-bulletins-section__sort .choices__inner {
  min-width: 200px;
  min-width: clamp(180px, 180px + 20 * (100vw - 992px) / 448, 200px);
}

@media (max-width: 500px) {
  .search-bulletins-section__sort .choices__inner {
    min-width: clamp(140px, 140px + 20 * (100vw - 320px) / 180, 160px);
  }
}
.search-bulletins-section__sort .choices__list .choices__item {
  color: var(--cl-charcoal) !important;
}

.search-bulletins-section__sort .choices__list .choices__item--choice {
  color: var(--cl-charcoal) !important;
}

.search-bulletins-section__sort .choices__list .choices__item--choice:hover {
  color: var(--cl-navy) !important;
}

.search-bulletins-section__sort .choices__item--selectable::before {
  content: none !important;
}

.search-bulletins-section__sort-wrap {
  display: flex;
  align-items: center;
}

.search-bulletins-section__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(12px, 12px + 8 * (100vw - 992px) / 448, 20px);
  margin-bottom: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
}

.search-bulletins-section__card {
  border: 1px solid var(--cl-aqua);
  border-radius: 16px;
  overflow: hidden;
  max-width: 420px;
  min-height: clamp(390px, 390px + 123 * (100vw - 992px) / 448, 513px);
  width: 32.1%;
}

@media (max-width: 992px) {
  .search-bulletins-section__card {
    width: 48.9%;
    max-width: none;
    min-height: clamp(365px, 365px + 25 * (100vw - 768px) / 672, 390px);
  }
}
@media (max-width: 715px) {
  .search-bulletins-section__card {
    width: 100%;
  }
}
.search-bulletins-section__card:hover .search-bulletins-section__card-image img {
  transform: scale(1.03);
}

.search-bulletins-section__card:hover .search-bulletins-section__card-btn .btn__icon {
  transform: rotate(-45deg);
}

.search-bulletins-section__card-image {
  overflow: hidden;
}

.search-bulletins-section__card-image img {
  width: 100%;
  height: auto;
  transition: 0.3s all;
}

.search-bulletins-section__card-body {
  border-top: 6px solid var(--cl-aqua);
  border-radius: 16px 16px 0 0;
  margin-top: -10px;
  z-index: 6;
  position: relative;
  background: white;
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  padding-right: clamp(22px, 22px + 10 * (100vw - 768px) / 672, 32px);
  padding-bottom: clamp(20px, 20px + 6 * (100vw - 768px) / 672, 26px);
  padding-left: clamp(22px, 22px + 10 * (100vw - 768px) / 672, 32px);
}

.search-bulletins-section__card-text {
  min-height: clamp(80px, 80px + 74 * (100vw - 768px) / 672, 154px);
  margin-bottom: 10px;
}

.search-bulletins-section__card-date {
  margin-bottom: 8px;
}

.search-bulletins-section__card-title {
  color: var(--cl-navy);
  --max-line-num: 3;
}

.search-bulletins-section__card-btn {
  display: flex;
  align-items: center;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.2;
  width: -moz-fit-content;
  width: fit-content;
}

.search-bulletins-section__card-btn .btn__icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid var(--cl-charcoal);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: clamp(6px, 6px + 4 * (100vw - 768px) / 672, 10px);
}

.search-bulletins-section__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bulletins-section__pagination-arrow {
  cursor: pointer;
}

.search-bulletins-section__pagination-arrow i {
  color: var(--cl-navy);
  font-size: 10px;
}

.search-bulletins-section__pagination-arrow--left {
  transform: rotate(90deg);
}

.search-bulletins-section__pagination-arrow--right {
  transform: rotate(-90deg);
}

.search-bulletins-section__pagination-list {
  margin: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 8px;
  column-gap: 8px;
}

.search-bulletins-section__pagination-item {
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cl-charcoal);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.4;
}

@media (max-width: 355px) {
  .search-bulletins-section__pagination-item {
    display: none;
  }
}
.search-bulletins-section__pagination-item--active {
  color: var(--cl-navy);
  border-color: #0035EC;
}

@media (max-width: 355px) {
  .search-bulletins-section__pagination-item--active {
    display: flex;
    border-color: transparent;
  }
}
.search-bulletins-section__pagination-more {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 4px;
}

/* Common effects */
[data-view] {
  transition-duration: var(--view-speed);
  transition-timing-function: var(--view-easing);
}

[data-view].animated {
  opacity: 1;
  transform: translateZ(0);
}

[data-view=fade], [data-view^=fade], [data-view=zoom], [data-view^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

/* Slide effect */
[data-view=slide], [data-view^=slide] {
  transition-property: transform;
}

[data-view=slide].animated, [data-view^=slide].animated {
  transform: translateZ(0);
}

/* Fade and slide effects */
[data-view=slide-tl], [data-view=fade-tl] {
  transform: translateY(100px) translateX(100px);
}

[data-view=slide-tr], [data-view=fade-tr] {
  transform: translateY(100px) translateX(-100px);
}

[data-view=slide-bl], [data-view=fade-bl] {
  transform: translateY(-100px) translateX(100px);
}

[data-view=slide-br], [data-view=fade-br] {
  transform: translateY(-100px) translateX(-100px);
}

[data-view=fade-l], [data-view=slide-l] {
  transform: translateX(100px);
}

[data-view=fade-r], [data-view=slide-r] {
  transform: translateX(-100px);
}

[data-view=fade-t], [data-view=slide-t] {
  transform: translateY(100px);
}

[data-view=fade-b], [data-view=slide-b] {
  transform: translateY(-100px);
}

/* Zoom effect */
[data-view^=zoom].animated, [data-view^=zoom].animated {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-view=zoom] {
  transform: scale(0.1);
}

[data-view=zoom-l] {
  transform: translateX(100px) scale(0.1);
}

[data-view=zoom-tl] {
  transform: translateY(100px) translateX(100px) scale(0.1);
}

[data-view=zoom-tr] {
  transform: translateY(100px) translateX(-100px) scale(0.1);
}

[data-view=zoom-br] {
  transform: translateY(-100px) translateX(-100px) scale(0.1);
}

[data-view=zoom-bl] {
  transform: translateY(-100px) translateX(100px) scale(0.1);
}

[data-view=zoom-r] {
  transform: translateX(-100px) scale(0.1);
}

[data-view=zoom-t] {
  transform: translateY(100px) scale(0.1);
}

[data-view=zoom-b] {
  transform: translateY(-100px) scale(0.1);
}

/* Rotate effect */
[data-view^=rotate], [data-view^=rotate] {
  opacity: 0;
  transform: rotate(180deg);
}

[data-view=rotate-l] {
  transform: rotate(180deg) translateX(-100px);
}

[data-view=rotate-r] {
  transform: rotate(180deg) translateX(100px);
}

[data-view=rotate-t] {
  transform: rotate(180deg) translateY(-100px);
}

[data-view=rotate-b] {
  transform: rotate(180deg) translateY(100px);
}
/*# sourceMappingURL=styles.f3e602a7.css.map */
